import { Badge } from './Badge';
import { Image } from './Image';

import {
  upvoteIcon,
  downvoteIcon,
  bookmarkFillIcon,
  bookmarkIcon,
  shareIcon,
  CustomEvent,
  upvoteFillIcon,
  downvoteFillIcon,
} from '@roc-digital/ui-lib';
import { Pressable } from './Pressable';
import { Icon } from './Icon';
import { Heading1 } from './Typefaces';

export enum CardSizes {
  large = 'lg',
  medium = 'md',
  small = 'sm',
}

export type ArticleProps = {
  id?: string;
  size?: 'lg' | 'md' | 'sm';
  image?: string;
  logo?: string;
  timeLapse?: string;
  tag?: string;
  headline?: string;
  upvoted?: boolean;
  downvoted?: boolean;
  upvotes?: number;
  downvotes?: number;
  saved?: boolean;
  upVoteEvent?: CustomEvent;
  downVoteEvent?: CustomEvent;
  saveEvent?: CustomEvent;
  shareEvent?: CustomEvent;
  shareOptions?: { name: string; icon: string; action: string }[];
  className?: string;
};

const viewContainerClasses = 'bg-white flex-col justify-center items-center';

const articleContainerClasses = 'w-full relative';

const imageContainerClasses = {};

const imageClasses = {
  lg: 'w-24',
  md: 'w-24',
  sm: 'w-24',
};
const articleGrayFontClasses = 'text-gray-400 text-xs';

const sourceLogoClasses = 'w-[28px] h-[28px] mr-2';

export const HorizontalArticleView = ({
  id,
  size = 'lg',
  image,
  logo,
  timeLapse,
  tag,
  upvoted,
  downvoted,
  upvotes,
  downvotes,
  saved,
  upVoteEvent,
  downVoteEvent,
  saveEvent,
  shareEvent,
  className,
}: ArticleProps): JSX.Element => {
  return (
    <div id={id} className={`${viewContainerClasses} shadow shadow-gray-300 p-3 ${className}`}>
      <div className={`${articleContainerClasses} flex flex-col`}>
        <div className="flex-row w-full items-center">
          <div className={`flex flex-row justify-between items-center`}>
            <div className={'flex justify-start items-center'}>
              {logo && <Image className={sourceLogoClasses} src={logo} />}
              <span className={articleGrayFontClasses}>{timeLapse}</span>
            </div>
            <div className={'flex justify-end'}>
              {tag && (
                <Badge type={'web'} customClassName={'bg-neutral-200'}>
                  {tag.toString().length > 15 ? tag.toString().slice(0, 10) + '...' : tag.toString() || ''}
                </Badge>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between py-5">
          <Heading1 className="text-black">U.S. prepping major military</Heading1>
          <div className={`${imageContainerClasses[size]}`}>
            <Image className={`${imageClasses[size]}`} src={image as string} />
          </div>
        </div>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center rounded-2xl border border-gray-100 px-2">
            <Pressable id={id} clickEvent={upVoteEvent} className="px-2 border-gray-100">
              <Icon size="small" src={upvoted ? upvoteFillIcon : upvoteIcon} />
            </Pressable>
            <span className={'text-gray-400 text-xs'}>{upvotes}</span>

            <div className="h-full bg-gray-100 mx-1" />

            <Pressable id={id} clickEvent={downVoteEvent} className={'px-2'}>
              <Icon size="small" src={downvoted ? downvoteFillIcon : downvoteIcon} />
            </Pressable>
            <span className={'text-gray-400 text-xs border-gray-100'}>{downvotes}</span>
          </div>

          <div className="flex flex-row items-center">
            <Pressable
              className={'items-center rounded-full border border-gray-100 px-2'}
              id={id}
              clickEvent={saveEvent}
            >
              <Icon size="small" src={saved ? bookmarkFillIcon : bookmarkIcon} />
            </Pressable>
            <div className="h-full bg-gray-100 mx-1" />
            <Pressable className={'rounded-full border border-gray-100 px-2'} id={id} clickEvent={shareEvent}>
              <Icon size="small" src={shareIcon} />
            </Pressable>
          </div>
        </div>
      </div>
    </div>
  );
};

HorizontalArticleView.displayName = 'HorizontalArticleView';
