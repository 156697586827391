import { useState } from 'react';
import { useMethod } from '@roc-digital/ui-lib';
import { Heading1, Input, Button, Modal } from '@roc-digital/ui-web';
import { toast } from 'react-toastify';
import { subscribeToNewsLetter } from '@roc-digital/mxm-base/logic';


const validateEmail = (email: string) => {
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailRegex.test(email);
};

const NewsletterModal = () => {
  const [email, setEmail] = useState('');
  const signupQuery = useMethod(subscribeToNewsLetter, {
    onBefore: async (args) => {
      const email = args[0];
      if (!email || email == '' || !validateEmail(email)) { 
        toast.error('You must enter a valid email address'); 
        return undefined; 
      }

      return args;
    },
    onSuccess: () => {
      toast.success('Thank you for signing up to your newsletter.');
      Modal.close('mxm.modal');
    },
    onError: () => {
      toast.error('Failed to subscribe.');
    }
  });

  return (
    <div className="p-12 dark:bg-surface-dark">
      <Heading1 className="font-[Chivo] !font-bold text-3xl dark:text-white mb-8">Sign up for our daily digest</Heading1>
      <div className="my-4">Keep in the know with the latest MxM Stories!</div>
      <div className="mt-6">
        <Input
          type="email"
          value={email}
          changeEvent={(setEmail)}
          placeholder="Email Address"
          className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-worksans !text-base sm:pr-12"
        />
      </div>
      <div className="w-1/2 mx-auto mt-6">
        <Button clickEvent={() => signupQuery.run(email)}>{signupQuery.loading ? 'Saving...' : 'Sign Up'}</Button>
      </div>
    </div>
  );
};

export default NewsletterModal;
