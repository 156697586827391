import React from 'react';
import { createRoot } from 'react-dom/client';
import * as ReactDOM from "react-dom";

import { BrowserRouter } from 'react-router-dom';
import { loadJsonConfig } from '@roc-digital/mxm-base/config';
import { initHttp, initI18n, reportMxMAnalytics, setEventSource } from '@roc-digital/mxm-base/logic';
import { CacheState, SessionState, MocksState } from '@roc-digital/mxm-base/state';
import { publish } from '@roc-digital/mxm-base/events';
import { App } from '@/components/App';
import reportWebVitals from './reportWebVitals';
import { loadProfile } from '@roc-digital/ui-lib';
import { getEnvKey } from './logic/env';
import { initNotifications } from './logic/notifications';

import '@roc-digital/ui-web/index.css';
import './index.css';
import { appLaunch } from './logic/interest-tagging';
import { logUtmParameter } from './logic/analytics';
import { checkUrlForArticleUnlockToken } from './logic/article-unlock-token';

/**
 * Report Analytics
 * Check for valid session
 *  if none, store srcInterest in localStorage
 * 
 * When a valid session is found
 */
reportMxMAnalytics({platform: 'web'});
logUtmParameter();

appLaunch();
checkUrlForArticleUnlockToken();

const loadUserLanguage = async () => {
  const sessionStateLanguage = await SessionState.getLanguage();

  const systemLanguage = navigator.language.split('-')[0];

  if (sessionStateLanguage) {
    return sessionStateLanguage === 'system' ? systemLanguage : sessionStateLanguage;
  }

  // const user = await SessionState.getUser();
  const currentLanguage = 'en';

  await SessionState.setLanguage(currentLanguage);

  return currentLanguage;
};

declare const ssr: boolean;

loadJsonConfig({
  env: '',
  logging: false,
  mocking: false,
  ssoUrl: '',
  ssoClientId: '',
  ssoRealm: '',
  cdnHost: 'https://api.qa-mxmnews.com',
  mxmApiWebUrl: getEnvKey('webApi', 'Web api url is missing from config'),
  mxmAuxApiUrl: getEnvKey('auxApi', 'Aux api url is missing from config'),
  mxmApiDataUrl: getEnvKey('webApi', 'Web api url is missing from config'),
  mxmApiAdminUrl: getEnvKey('adminApi', 'Admin api is missing from config'),
  appVersion: '',
  appDevice: '',
  app: undefined,
  mockingUser: '',
}).then(async () => {
  setEventSource(EventSource);

  CacheState.init(localStorage);
  SessionState.init(sessionStorage);
  initNotifications();

  const userLanguage = await loadUserLanguage();

  initI18n(userLanguage, () => {
    publish('mxm.i18n', 'initialize.success');
  });

  initHttp(CacheState, SessionState, MocksState);

  const container = document.getElementById('root');

  if (!container) {
    throw new Error('No root tag to mount React.');
  }

  loadProfile();

  if(typeof ssr !== 'undefined' && ssr) {
    ReactDOM.hydrate(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      container
    );
  } else {
    const root = createRoot(container);
  

    root.render(
      // <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      // </React.StrictMode>
    );
  }


});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
