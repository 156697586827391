import React from 'react';
import { publish } from '@roc-digital/mxm-base/events';

import { ClickEvent, CustomEvent, PublisherData } from '@roc-digital/ui-lib';

export type PressableProps = {
  id?: string;
  className?: string;
  name?: string;
  disabled?: boolean;
  clickEvent?: ClickEvent | CustomEvent;
  children?: React.ReactNode;
};

export const Pressable = ({ children, id, className, disabled, clickEvent }: PressableProps) => {
  const publishClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    if (!clickEvent || disabled) {
      return;
    }

    if (clickEvent.allowDefault === false) {
      evt.preventDefault();
    }

    if (clickEvent.propagate === false) {
      evt.stopPropagation();
    }

    publish<PublisherData>(clickEvent.namespace, 'click', {
      id,
      eventData: clickEvent.data || {},
      htmlEvent: evt,
    });
  };

  return (
    <div id={id} className={`cursor-pointer ${className}`} onClick={publishClick}>
      {children}
    </div>
  );
};
