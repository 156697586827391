import React from "react"

const getSize = () => {
  const size = window.innerWidth;
  if(size < 720) {
    return 'sm'
  } else if(size < 800) {
    return 'md';
  } else {
    return 'lg';
  }
};

export const useScreenSize = () => {
  const [size, changeSize] = React.useState<'lg' | 'md' | 'sm'>(getSize);
  const sizeRef = React.useRef(size);
  sizeRef.current = size;
  React.useEffect(() => {
    const fn = () => {
      const next = getSize();
      if(next !== sizeRef.current) {
        changeSize(next);
      }
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    }
  }, []);

  return size;
}