import { LoginOptions, resetPasswordWithFirebase } from '@/logic';
import { useEffect, useState } from 'react';
import { Button, Heading1, Icon, Input } from '@roc-digital/ui-web';
import {
  InputData,
  mxmIcon,
  useChangeEvent,
  useClickEvent,
  eyeOpenIcon,
  eyeClosedIcon,
} from '@roc-digital/ui-lib';
import { Event } from '@roc-digital/mxm-base/dist/types';
import SocialSignin from '@/components/views/Login/SocialSignin';
import { Loading } from '@/components/elements/Loading';
import { useLocation, useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

export interface ForgotPasswordFormProps {
  onChangePassword: (value: LoginOptions) => void;
  loading?: boolean;
}

export function ForgotPasswordForm(props: ForgotPasswordFormProps) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const handleEmailChange = useChangeEvent(
    ({ data }: Event<InputData>) => {
      setEmail(data.value as string);   
    },
    {},
    [],
    [email]
  );

  const handleResetClick = () => {
    if (!email) {
      toast.error('Please enter a valid email.');
      return;
    } 
      try {
        resetPasswordWithFirebase(email).then(() => {
          toast.success(`If ${email} exists in MXM, you will recieve a reset link within 1-2 minutes. Please check your email.`);
          navigate('/login');
        });
      } catch (error) {
        console.error('Failed to reset password:', error);
        toast.error('Could not reset password.');
      }
  }

  return <>
    <div className="flex items-center">
      <div className="mx-auto flex items-center">
        <Icon src={mxmIcon} size="x-large" />
        <Heading1 className="text-3xl text-admin-dark font-chivo font-bold dark:text-white pl-4">MxM News</Heading1>
      </div>
    </div>

    <div className="w-full mt-4">
      <Input
        type="email"
        value={email}
        changeEvent={handleEmailChange}
        placeholder="Email Address"
        className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-worksans !text-base sm:pr-12"
      />
    </div>
    <div className="w-full">
      <Button clickEvent={() => handleResetClick()}>
        {props.loading ? <Loading color={'white'} customClassName={'ml-auto mr-auto'} /> : null}
        Request Reset Link
      </Button>
    </div>
    
    <span className="h-10 flex items-center justify-center w-full font-worksans dark:text-white cursor-pointer mt-10" onClick={() => navigate('/signup')}>
      Need an account?
      <span className="font-medium ml-2">Sign Up</span>
      <span className="text-font-info-high ml-2 dark:text-white">{'>'}</span>
    </span>
    <span className="h-10 flex items-center justify-center w-full font-worksans dark:text-white cursor-pointer" onClick={() => navigate('/login')}>
      Know your password?
      <span className="font-medium ml-2">Login</span>
      <span className="text-font-info-high ml-2">{'>'}</span>
    </span>
    <span onClick={() => navigate('/trending')} className="h-14 flex items-center justify-center w-full font-medium font-worksans dark:text-white cursor-pointer">
      Continue as Guest
      <span className="text-font-info-high ml-2 dark:text-white">{'>'}</span>
    </span>
  </>;
}