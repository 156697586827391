"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteBookmark = exports.listBookmarksV2 = exports.listBookmarks = void 0;
/* eslint-disable import/no-cycle */
// @copyright 2022 Navigo, Inc
var data_1 = require("../data");
var http_1 = require("./http");
var config_1 = require("../config");
var json_fetch_1 = require("./json-fetch");
var BOOKMARKS_CACHE_TTL = 60 * 60 * 1000; // 1 hour
var SERVICE_NAME = 'bookmarks';
var JSON_PATH = '_';
function url() {
    return "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/bookmarks");
}
function listBookmarks(page, count, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'listBookmarks', (0, http_1.createHTTPRequest)('GET', "".concat(url(), "?page=").concat(page, "&count=").concat(count), params), (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Articles, data_1.Article), true, BOOKMARKS_CACHE_TTL);
}
exports.listBookmarks = listBookmarks;
function listBookmarksV2(page, count) {
    return __awaiter(this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                        method: 'GET',
                        url: "".concat(url(), "?page=").concat(page, "&count=").concat(count)
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, (result === null || result === void 0 ? void 0 : result.data) || []];
            }
        });
    });
}
exports.listBookmarksV2 = listBookmarksV2;
function deleteBookmark(cacheState, id, params) {
    return (0, http_1.resolveTextHTTPResponse)(SERVICE_NAME, 'deleteBookmark', (0, http_1.createHTTPRequest)('DELETE', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/articles/").concat(id, "/bookmark"), params), function () { return ({ id: id }); }).then(function (data) {
        cacheState.remove("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/bookmarks?"));
        return data;
    });
}
exports.deleteBookmark = deleteBookmark;
