import { Badge } from './Badge';
import { Image } from './Image';

import {
  bookmarkFillIcon,
  bookmarkIcon,
  CustomEvent,
  downvoteFillIcon,
  downvoteIcon,
  shareIcon,
  upvoteFillIcon,
  upvoteIcon,
} from '@roc-digital/ui-lib';
import { Pressable } from './Pressable';
import { Icon } from './Icon';
import { Heading1 } from './Typefaces';
import { Button, ButtonSizes, ButtonVariants } from './Button';

export enum CardSizes {
  large = 'lg',
  medium = 'md',
  small = 'sm',
}

export type ArticleProps = {
  id?: string;
  size?: 'lg' | 'md' | 'sm';
  image?: string;
  logo?: string;
  timeLapse?: string;
  tag?: string;
  headline?: string;
  upvoted?: boolean;
  downvoted?: boolean;
  upvotes?: number;
  downvotes?: number;
  saved?: boolean;
  upVoteEvent?: CustomEvent;
  downVoteEvent?: CustomEvent;
  saveEvent?: CustomEvent;
  shareEvent?: CustomEvent;
  shareOptions?: { name: string; icon: string; action: string }[];
  className?: string;
};

const viewContainerClasses = 'bg-white w-full h-full items-stretch';

const articleGrayFontClasses = 'text-gray-400 text-xs';

const sourceLogoClasses = 'w-[28px] h-[28px] mr-3';

export const BigArticleView = ({
  id,
  image,
  logo,
  timeLapse,
  tag,
  upvoted,
  downvoted,
  upvotes,
  downvotes,
  saved,
  upVoteEvent,
  downVoteEvent,
  saveEvent,
  shareEvent,
  className,
}: ArticleProps): JSX.Element => {
  return (
    <div id={id} className={`${viewContainerClasses} flex shadow shadow-gray-300 flex-row ${className}`}>
      <div className={'flex flex-col w-6/12 justify-between items-stretch py-3 px-5'}>
        <div>
          <div className={'flex justify-end'}>
            {tag && (
              <Badge type={'web'} customClassName={'bg-neutral-200'}>
                {tag.toString().length > 15 ? tag.toString().slice(0, 10) + '...' : tag.toString() || ''}
              </Badge>
            )}
          </div>
          <div className={'mb-5'}>
            <Button variant={ButtonVariants.web} size={ButtonSizes.medium}>
              {'<Back'}
            </Button>
          </div>
          <div className="flex-row w-full items-center">
            <div className={`flex flex-row items-center`}>
              <div className={'flex justify-start items-center'}>
                {logo && <Image className={sourceLogoClasses} src={logo} />}
                <span className={articleGrayFontClasses}>{timeLapse}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-start mb-20">
          <div className={'my-10'}>
            <Heading1 className="text-black mb-10 w-full">U.S. prepping major military</Heading1>
          </div>
          <div className="justify-start">
            <Button size={ButtonSizes.medium} variant={ButtonVariants.web}>
              Read full article
            </Button>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center rounded-2xl border border-gray-100 px-2">
            <Pressable id={id} clickEvent={upVoteEvent} className="px-2 border-gray-100">
              <Icon size="small" src={upvoted ? upvoteFillIcon : upvoteIcon} />
            </Pressable>
            <span className={'text-gray-400 text-xs'}>{upvotes}</span>

            <div className="bg-gray-100 mx-1" />

            <Pressable id={id} clickEvent={downVoteEvent} className={'px-2'}>
              <Icon size="small" src={downvoted ? downvoteFillIcon : downvoteIcon} />
            </Pressable>
            <span className={'text-gray-400 text-xs border-gray-100'}>{downvotes}</span>
          </div>

          <div className="flex flex-row items-center">
            <Pressable
              className={'items-center rounded-full border border-gray-100 px-2'}
              id={id}
              clickEvent={saveEvent}
            >
              <Icon size="small" src={saved ? bookmarkFillIcon : bookmarkIcon} />
            </Pressable>
            <div className="bg-gray-100 mx-1" />
            <Pressable className={'rounded-full border border-gray-100 px-2'} id={id} clickEvent={shareEvent}>
              <Icon size="small" src={shareIcon} />
            </Pressable>
          </div>
        </div>
      </div>
      <div className={'flex w-6/12'}>
        <Image className={'w-full'} src={image as string} />
      </div>
    </div>
  );
};

BigArticleView.displayName = 'BigArticleView';
