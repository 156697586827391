import UserInterests from '@/components/elements/Interests';

const Interests = () => {
  return (
    <div className="flex flex-col max-w-2xl w-full text-sm dark:text-white">
      <div className="text-2xl font-bold mb-6">Update Interests</div>
      <div className="max-w-md text-font-low mb-3">Add sections to your news feed.</div>
      <UserInterests />
    </div>
  );
};

Interests.displayName = 'Interests';

export default Interests;
