import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ContentLayout } from './ContentLayout';
import { Button, ButtonSizes } from '@roc-digital/ui-web';

export function Complete() {
  const [search] = useSearchParams();
  const location = useLocation();
  const nav = useNavigate();
  const newUser = search.get('new') === 'yes';

  if(location.pathname.includes('welcome-back')) {
    return <ContentLayout
      title='You are already a subscriber!'
      description='Congratulations, you are already an active subscriber. Click on the link below to start enjoying +MXM today!'
    >
      <Button size={ButtonSizes.large} clickEvent={() => nav('/trending')}>Start Reading!</Button>
    </ContentLayout>;
  }

  return <ContentLayout
    title='Congratulations! Welcome to MXM News+'
    description='Welcome to MXM News+! You now have access to unlimited  local content for over 200 American Communities, and access to our EXCLUSIVE Paid content!'
    >

     {newUser ? <>
      <Button size={ButtonSizes.large} clickEvent={() => nav('/login')}>Next, Customize Your Experience</Button>
     </> : <>
     <Button size={ButtonSizes.large} clickEvent={() => nav('/trending')}>Start Reading!</Button>
     </>} 
  </ContentLayout>;
}