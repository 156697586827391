import { followIcon, checkIcon, CustomEvent, PublisherData } from '@roc-digital/ui-lib';
import { publish } from '@roc-digital/mxm-base/events';
import { BodyDark } from './Typefaces';
import { Image } from './Image';
import { Icon } from './Icon';
import { Loading } from './Loading';

export const FollowItem = ({
  id,
  avatar = undefined,
  name = '',
  followed = false,
  followEvent = undefined,
  loading = false,
  className = '',
}: {
  id: string;
  avatar?: string;
  name?: string;
  followed?: boolean;
  followEvent?: CustomEvent;
  loading?: boolean;
  className?: string;
}) => {
  const publishClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    if (!followEvent) {
      return;
    }

    if (followEvent.allowDefault === false) {
      evt.preventDefault();
    }

    if (followEvent.propagate === false) {
      evt.stopPropagation();
    }

    publish<PublisherData>(followEvent.namespace, 'click', {
      id,
      eventData: followEvent.data || {},
    });
  };

  return (
    <div
      className={`flex flex-row bg-surface-high dark:bg-transparent w-full py-1 items-center justify-between ${className}`}
    >
      <div className="flex flex-row items-center gap-4 basis-full">
        {avatar && (
          <Image
            className={`rounded-full h-10 w-10 drop-shadow-sm border-2 border-icon-primary basis-10`}
            src={avatar}
          />
        )}

        <div className="break-all basis-4/5" onClick={publishClick}>
          <BodyDark className="text-base line-clamp-2 leading-tight dark:text-white">{name}</BodyDark>
        </div>
      </div>

      <div className="rounded-full gap-2 ml-2" onClick={publishClick}>
        <div
          className={`flex w-10 h-10 items-center justify-center p-0 rounded-full ${
            followed ? 'bg-neutral-200' : 'bg-surface-primary'
          }`}
        >
          {loading ? (
            <Loading size="x-small" />
          ) : followed ? (
            <Icon src={checkIcon.toString()} size="small" />
          ) : (
            <Icon src={followIcon.toString()} size="small" />
          )}
        </div>
      </div>
    </div>
  );
};
