import React from 'react';
import { Badge, BadgeSizes } from '@roc-digital/ui-web';
import {
  followingIcon,
  politicsIcon,
  financeMoneyIcon,
  policyIcon,
  internationalIcon,
  americaFirstIcon,
  lifeCultureIcon,
  addFillIcon,
} from '@roc-digital/ui-lib';
import { TagSchema } from '@roc-digital/mxm-base/src/data';

export interface TagSelectorHeaderProps {
  allScope: string;
  tags: TagSchema[];
  activeFilters: string[];
  setActiveFilters: (next: string[] | ((current: string[]) => string[])) => void;
  onManageTags?: () => void;
}



export function TagSelectorHeader(props: TagSelectorHeaderProps) {

  const activeFilters = props.activeFilters;
  const setActiveFilters = props.setActiveFilters;

  
  const managePreferences = React.useCallback(() => {
    if (props.onManageTags) {
      props.onManageTags();
    }
  }, []);

  const toggleFilter = React.useCallback((id: string) => {
    setActiveFilters(activeFilters => {

      if (id === props.allScope) {
        return [props.allScope];
      }

      let next: string[] = [];
      if (activeFilters.includes(id)) {
        next = activeFilters.filter((item) => item !== id);
      } else {
        next = [...activeFilters, id];
      }

      next = next.filter((a, i, l) => l.indexOf(a) == i)
      if (next.length > 1) {
        next = next.filter(a => a !== props.allScope)
      }

      return next;
    });
  }, []);

  return (
    <>
      <div className={'flex flex-wrap w-full justify-center gap-3 items-start max-w-2xl mx-auto mb-4'}>
        <Badge
          title={'All'}
          size={BadgeSizes.small}
          selected={activeFilters.includes(props.allScope)}
          customClassName={'cursor-pointer'}
          customTextClassName='cursor-pointer !font-[500]'
          clickEvent={() => toggleFilter(props.allScope)}
        />
        {props.tags.map(tag => {

          return <Badge
            key={tag.scope}
            icon={tagIconMap[tag.tag || '']}
            title={tag.name}
            image={tag.image_url}
            size={BadgeSizes.small}
            clickEvent={() => toggleFilter(tag.scope || '')}
            customClassName={'cursor-pointer'}
            customTextClassName='cursor-pointer !font-[500]'
            selected={tag.scope ? activeFilters.includes(tag.scope) : false}
          />;

        })}
        {props.onManageTags ? <>
          <Badge
            icon={addFillIcon}
            title={'Manage Preferences'}
            image={'/icons/settings.svg'}
            size={BadgeSizes.small}
            clickEvent={managePreferences}
            customClassName={'cursor-pointer'}
            customTextClassName='cursor-pointer !font-[500]'
          />
        </> : <></>}
      </div>
    </>
  );
}

const tagIconMap: any = {
  following: followingIcon,
  politics: politicsIcon,
  finance: financeMoneyIcon,
  policy: policyIcon,
  international: internationalIcon,
  'america-first': americaFirstIcon,
  'life-and-culture': lifeCultureIcon,
}