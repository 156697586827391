"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleMocking = exports.getConfig = exports.updateConfig = exports.loadJsonConfig = exports.loadConfig = void 0;
var CONFIG_LOADED = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var CONFIG;
function loadConfig(appConfig) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            CONFIG = {
                env: process.env.REACT_APP_ENV || 'qa',
                logging: process.env.REACT_APP_LOGGING !== 'false',
                mocking: process.env.REACT_APP_API_MOCKING === 'true',
                mockingUser: process.env.REACT_APP_MXM_TEST || '',
                ssoUrl: process.env.REACT_APP_SSO_URL || '',
                ssoClientId: process.env.REACT_APP_SSO_CLIENT_ID || '',
                ssoRealm: process.env.REACT_APP_SSO_REALM || '',
                cdnHost: process.env.REACT_APP_CDN_HOST || 'https://mxm-asset-qa.s3-eu-west-1.amazonaws.com',
                mxmApiWebUrl: process.env.REACT_APP_MXM_API_WEB_URL || '',
                mxmAuxApiUrl: process.env.REACT_APP_AUX_API_URL || '',
                mxmApiDataUrl: process.env.REACT_APP_MXM_API_DATA_URL || '',
                mxmApiAdminUrl: process.env.REACT_APP_MXM_API_ADMIN_URL || '',
                appVersion: process.env.REACT_APP_VERSION || '1.0.0',
                appDevice: process.env.REACT_APP_DEVICE || 'web',
                app: appConfig,
            };
            CONFIG_LOADED = true;
            return [2 /*return*/, CONFIG];
        });
    });
}
exports.loadConfig = loadConfig;
function loadJsonConfig(jsonConfig) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            CONFIG = jsonConfig;
            CONFIG_LOADED = true;
            return [2 /*return*/, CONFIG];
        });
    });
}
exports.loadJsonConfig = loadJsonConfig;
function updateConfig(changes) {
    Object.assign(CONFIG, changes);
}
exports.updateConfig = updateConfig;
function getConfig() {
    if (!CONFIG_LOADED)
        throw new Error('Configuration has not been loaded');
    return CONFIG;
}
exports.getConfig = getConfig;
function toggleMocking() { }
exports.toggleMocking = toggleMocking;
