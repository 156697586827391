import { useNavigate } from 'react-router-dom';

export function useNav() {
  const nav = useNavigate();

  return (to: string, search?: {[key: string]: string}) => {
    if(search) {
      const u = new URL(window.location.toString());
      Object.keys(search).forEach(key => u.searchParams.set(key, search[key]));
      nav(to + '?' + u.searchParams.toString());
    } else {
      nav(to + window.location.search.toString())
    }
  }
}