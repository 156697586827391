import styles from './TableHeader.module.css';

type Props = {
  type?: 'head' | 'foot' | 'body';
  className?: string;
  children: React.ReactNode;
};

const tsClasses = `${styles.ts} text-xm`;
export const TableSection = ({ children, className, type }: Props) => {
  className = className ? `${tsClasses} ${className}` : tsClasses;
  switch (type) {
    case 'head':
      return <thead className={className}>{children}</thead>;
    case 'foot':
      return <tfoot className={className}>{children}</tfoot>;
    case 'body':
      return <tbody className={className}>{children}</tbody>;
  }

  throw new Error(`Invalid TableSection type: ${type}`);
};

TableSection.defaultProps = {
  type: 'body',
};
