import React from 'react';
import { Authentication } from '..';

// AuthContext stores the authentication data globally.
export const AuthContext = React.createContext<Authentication>({
  isAuthenticated: false,
  isDisabled: false,

  authenticate: () => {},
  logout: () => {},
});

AuthContext.displayName = 'AuthContext';
