import { Link } from 'react-router-dom';
import appStoreButtonImage from '../../assets/app_store.svg';

const AppStoreButton = () => {
  return (
    <Link to="https://apps.apple.com/us/app/mxm-news/id1576576073" target="_blank">
      <img src={appStoreButtonImage} />
    </Link>
  );
};

AppStoreButton.displayName = 'AppStoreButton';

export default AppStoreButton;
