import { forwardRef, ComponentPropsWithoutRef } from 'react';

export interface IconBaseProps extends ComponentPropsWithoutRef<'svg'> {
  alt?: string;
  sizeClassName?: string;
  colorClassName?: string;
}

export const IconBase = forwardRef<SVGSVGElement, IconBaseProps>((props, ref) => {
  const { alt, children, className, sizeClassName = 'h-5 w-5', colorClassName = 'fill-icon', ...restProps } = props;

  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={`${sizeClassName} ${colorClassName} ${className ? className : ''}`}
      {...restProps}
    >
      {!!alt && <title>{alt}</title>}
      <rect width="24" height="24" fill="none" />
      {children}
    </svg>
  );
});

IconBase.displayName = 'IconBase';
