import { Button, ButtonSizes, Heading1, Modal } from '@roc-digital/ui-web';
import { useNavigate } from 'react-router';

const LoginSignUpPrompt = () => {
  const navigate = useNavigate();

  return (
    <div className="p-8 dark:bg-surface-dark">
      <Heading1 className="font-[Chivo] !font-bold text-3xl dark:text-white mb-8">Get an MxM Account</Heading1>
      <div>Sign up for a free account today!</div>
      <div className="mt-4">
        <Button size={ButtonSizes.medium} clickEvent={() => {navigate('/signup'); Modal.close('mxm.modal');}}>Sign Up</Button>
      </div>
    </div>
  );
};

export default LoginSignUpPrompt;
