import { Link } from 'react-router-dom';

interface FooterProps {
  dark?: boolean;
}

const Footer = ({ dark }: FooterProps) => {
  let footerClassName = `flex justify-between py-10 max-w-5xl w-full border-t border-font-muted font-semibold `;

  footerClassName = footerClassName + (dark ? 'text-white' : 'text-font');

  return (
    <>
      <div className="flex justify-center w-full bg-transparent font-sans hidden-md ">
        <div className={`${footerClassName} sm:px-2 md:flex-wrap md:px-2`}>
          <div className="flex items-center gap-6">
            <Link to="/trending" className="text-sm dark:text-white">
              Trending
            </Link>
            <Link to="/foryou" className="text-sm dark:text-white">
              For You
            </Link>
            <Link to="/about" className="text-sm dark:text-white">
              About
            </Link>
            <Link to="/help" className="text-sm dark:text-white">
              Support
            </Link>
            <Link to="/careers" className="text-sm dark:text-white">
              Careers
            </Link>
          </div>
          <div className="flex items-center gap-6">
            <Link to="/privacy-policy" className="text-sm dark:text-white">
              Privacy<span className="hidden-sm"> Policy</span>
            </Link>
            <Link to="/terms" className="text-sm dark:text-white">
              Terms<span className="hidden-sm"> & Conditions</span>
            </Link>
            <span className="text-sm dark:text-white">© 2024 MxM News, All Rights Reserved</span>
          </div>
        </div>
      </div>
      <hr className="visible-xs"/>
      <div className="visible-md px-2 pt-4 text-black sm:text-white dark:text-white w-full bg-transparent font-san">
        <div className="text-center w-full mx-auto mb-4">
          <ul>
            <li>
              <Link to="/trending" className="text-sm">
                Trending
              </Link>
            </li>
            <li>
              <Link to="/foryou" className="text-sm">
                For You
              </Link>
            </li>
            <li>
              <Link to="/about" className="text-sm">
                About
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" className="text-sm">
                Privacy
              </Link>
            </li>
            <li>
              <Link to="/terms" className="text-sm">
                Terms
              </Link>
            </li>
            <li>
              <Link to="/help" className="text-sm">
                Support
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="text-center sm:text-white dark:text-white mb-2 visible-md">© 2023 MxM News, All Rights Reserved</div>
    </>
  );
};

Footer.displayName = 'Footer';

export default Footer;
