import { useNavigate } from 'react-router';
import { Button, ButtonSizes, ButtonVariants, Heading1, Heading2, Modal } from '@roc-digital/ui-web';
interface Props {
  size?: string;
}

const LoginSignup = ({ size = 'small' }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      {size === 'small' && 
        <div className={'flex w-full py-10 mb-6 bg-[#24AAE1] items-center justify-between'}>
          <span className={'flex items-center w-full justify-between max-w-5xl mx-auto px-2'}>
            <span className={'flex items-center w-1/2'}>
              <Heading1 className={'text-white !font-chivo !font-extrabold !text-[48px] !leading-10 sm:!text-[26px] md:!text-[36px] mr-2'}>
                Log In or Sign Up to get news that’s the most relevant to you.
              </Heading1>
            </span>
            <span className={'flex items-center w-1/2 justify-end'}>
              <Button
                title={'Login'}
                id={'login'}
                size={ButtonSizes.medium}
                customClassName={'bg-[#24AAE1] border-2 border-white mr-4'}
                customTextClassName={'text-white !text-sm'}
                clickEvent={() => navigate('/login')}
              />
              <Button
                title={'Sign Up'}
                id={'signup'}
                size={ButtonSizes.medium}
                variant={ButtonVariants.secondaryPlain}
                customTextClassName={'text-[#1E222A] !text-sm'}
                clickEvent={() => navigate('/signup')}
              />
            </span>
          </span>
        </div>
      }
      {size === 'large' && 
        <div className={'flex w-full py-6 px-1 mb-6 bg-[#24AAE1] items-center justify-between'}>
          <span className={'flex items-center flex-col w-full text-center max-w-2xl mx-auto mt-6'}>
            <Heading1 className={'text-white !font-chivo !font-extrabold text-[64px] leading-[64px] sm:text-[48px]'}>
              Mainstream News without the Mainstream Bias
            </Heading1>
            <Heading2 className={'text-white !font-chivo !font-bold text-md'}>
              Cut through the censorship, mainstream bias, and institutional dominance that has left
              society divided.
            </Heading2>
            <span className={'flex items-center w-full justify-center my-12'}>
              <Button
                title={'Login'}
                id={'login'}
                size={ButtonSizes.medium}
                customClassName={'bg-[#24AAE1] border-2 border-white mr-4'}
                customTextClassName={'text-white !text-sm'}
                clickEvent={() => navigate('/login')}
              />
              <Button
                title={'Sign Up'}
                id={'signup'}
                size={ButtonSizes.medium}
                variant={ButtonVariants.secondaryPlain}
                customTextClassName={'text-[#1E222A]  !text-sm'}
                clickEvent={() => navigate('/signup')}
              />
            </span>
          </span>
        </div>
      }
    </>
  );
};

LoginSignup.displayName = 'LoginSignup';

export default LoginSignup;
