import { closeIcon } from "@roc-digital/ui-lib";
import { Icon } from "@roc-digital/ui-web";

export interface ChipProps {
  children: any;
  onRemove: () => void;
}

export function Chip(props: ChipProps) {
  return <span
    className='rounded bg-[#E7E7E7] flex-wrap border border-[#D5D5D5] text-[14px] font-bold px-[8px] py-[4px] relative'
  >
    {props.children}
    <span onClick={props.onRemove}>
      
      <Icon src={closeIcon} size='small' className='pl-[4px] cursor-pointer'/>
    </span>
  </span>
}
