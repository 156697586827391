import { Outlet, Route } from 'react-router';
import { SelectPlan } from './SelectPlan';
import { Login } from './Login';
import { Payment } from './Payment';
import { Complete } from './Complete';
import './styles.css';
import AppFooter from '@/components/elements/AppFooter';
import YouTubePlayer from './YouTubePlayer';

export function SubscriberLanding() {
  return <>
    <Route path='subscribe' element={<Container />} >
      {/* <Route path='' element={<Navigate to={'select-plan?'+queryString}/>}/> */}
      <Route path='' Component={SelectPlan} />
      <Route path='sign-up' Component={Login} />
      <Route path='create-account' Component={Login} />
      <Route path='login' Component={Login} />
      <Route path='payment' Component={Payment}/>
      <Route path='thank-you' Component={Complete}/>
      <Route path='welcome-back' Component={Complete}/>
    </Route>
  </>;
}

function Container() {
  return <>
  <div className='sl flex-grow flex flex-col' style={{backgroundColor: '#F7F7F7'}}>
    <div className='flex flex-row justify-center sm:mb-[24px]'>
      <div className='max-w-[350px] flex flex-grow flex-row h-20 items-center gap-[24px]'>
        <img
          alt={'logo'}
          src={'/icons/logo.svg'}
          className={'cursor-pointer h-12 w-12'}
        />
        <h1 className="font-bold text-[48px]" style={{fontWeight: 'bolder', fontFamily: 'Chivo,sans-serif', color: '#1E222A'}}>
          MxM News
        </h1>
      </div>
    </div>
    <center>
      <div style={{maxWidth: '800px'}} className='sm:p-4'>
        <YouTubePlayer videoId='2jPHWsSPMkw'/>
      </div>
    </center>
    <div className='mt-8'>
      <Outlet/>
    </div>
    
  </div>
  <AppFooter/>
</>
}

/**
c=?
c=?
c=?&plan=?
c=?&plan=?
c=?&plan=?&new=yes|no 
c=?&plan=?&new=yes|no
c=?
*/