"use strict";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collection = void 0;
var Pagination_1 = require("./Pagination");
var Collection = /** @class */ (function () {
    function Collection(items, Type, pagination) {
        if (pagination === void 0) { pagination = new Pagination_1.Pagination(); }
        this.Type = Type;
        this.items = [];
        this.items = items.map(function (item) { return new Type(item); });
        this.pagination = pagination;
    }
    Collection.prototype.toArray = function () {
        return this.items;
    };
    Collection.prototype.toJson = function () {
        return JSON.stringify(this.items.map(function (entity) { return entity.toObject(); }));
    };
    Collection.prototype.count = function () {
        return this.items.length;
    };
    Collection.prototype.first = function () {
        return this.items.length ? this.items[0] : undefined;
    };
    Collection.prototype.last = function () {
        return this.items.length ? this.items[this.items.length - 1] : null;
    };
    Collection.prototype.get = function (position) {
        return this.items.length && typeof this.items[position] !== 'undefined' ? this.items[position] : undefined;
    };
    Collection.prototype.push = function (item) {
        return this.items.push(item);
    };
    Collection.prototype.reverse = function () {
        return new this.constructor(this.items.reverse(), this.Type);
    };
    Collection.prototype.sort = function (callback) {
        return new this.constructor(this.items.sort(callback), this.Type);
    };
    Collection.prototype.splice = function (start, deleteCount) {
        var _a;
        var items = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            items[_i - 2] = arguments[_i];
        }
        return new this.constructor((_a = this.items).splice.apply(_a, __spreadArray([start, deleteCount || 0], __read(items), false)), this.Type);
    };
    Collection.prototype.forEach = function (callbackfn, thisArg) {
        this.items.forEach(callbackfn, thisArg);
        return new this.constructor(this.items, this.Type);
    };
    Collection.prototype.find = function (predicate, thisArg) {
        return this.items.find(predicate, thisArg || this);
    };
    Collection.prototype.filter = function (predicate, thisArg) {
        return new this.constructor(this.items.filter(predicate, thisArg || this), this.Type);
    };
    Collection.prototype.map = function (callbackfn, thisArg) {
        return this.items.map(callbackfn, thisArg || this);
    };
    Collection.prototype.some = function (predicate, thisArg) {
        return this.items.some(predicate, thisArg || this);
    };
    Collection.prototype.reduce = function (callbackfn, initialValue) {
        return this.items.reduce(callbackfn, initialValue || null);
    };
    return Collection;
}());
exports.Collection = Collection;
