export interface CardProps {
  title: string;
  children: any;
}

export function Card(props: CardProps) {
  return <div className="card">
    <h1 className="card-title">
      {props.title}
    </h1>
    {props.children}
  </div>
}