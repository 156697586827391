"use strict";
/* eslint-disable import/no-cycle */
// @copyright 2023 MXM, Inc
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionState = void 0;
var config_1 = require("../config");
var data_1 = require("../data");
var logging_1 = require("../logic/logging");
var STORAGE = null;
function setItem(key, value) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, STORAGE === null || STORAGE === void 0 ? void 0 : STORAGE.setItem(key, value)];
        });
    });
}
function getItem(key) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, STORAGE === null || STORAGE === void 0 ? void 0 : STORAGE.getItem(key)];
        });
    });
}
function removeItem(key) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, STORAGE === null || STORAGE === void 0 ? void 0 : STORAGE.removeItem(key)];
        });
    });
}
function clear() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, STORAGE === null || STORAGE === void 0 ? void 0 : STORAGE.clear()];
        });
    });
}
var SessionState = /** @class */ (function () {
    function SessionState() {
    }
    SessionState.init = function (newStorage) {
        if (STORAGE) {
            return;
        }
        STORAGE = newStorage;
    };
    SessionState.has = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            var value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.has]', [key]);
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][").concat(key, "]"))];
                    case 1:
                        value = _a.sent();
                        if (value) {
                            (0, logging_1.log)('[Session.has] Key exists', [value]);
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/, false];
                }
            });
        });
    };
    SessionState.get = function (key, silent) {
        if (silent === void 0) { silent = false; }
        return __awaiter(this, void 0, void 0, function () {
            var value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!silent) {
                            (0, logging_1.log)('[Session.get]', [key]);
                        }
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][").concat(key, "]"))];
                    case 1:
                        value = _a.sent();
                        if (value) {
                            (0, logging_1.log)('[Session.get] Found key', [value]);
                            return [2 /*return*/, JSON.parse(value || '')];
                        }
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    SessionState.set = function (key, value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.set]', [key, value]);
                        return [4 /*yield*/, setItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][").concat(key, "]"), JSON.stringify(value))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SessionState.add = function (key, value) {
        return __awaiter(this, void 0, void 0, function () {
            var values, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        (0, logging_1.log)('[Session.add]', [key, value]);
                        _b = (_a = JSON).parse;
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][").concat(key, "]"))];
                    case 1:
                        values = _b.apply(_a, [(_c.sent()) || '[]']);
                        (0, logging_1.log)('[Session.add] Adding value', [value, values]);
                        values.push(value);
                        return [4 /*yield*/, setItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][").concat(key, "]"), JSON.stringify(values))];
                    case 2:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SessionState.remove = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.remove]', [key]);
                        return [4 /*yield*/, removeItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][").concat(key, "]"))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SessionState.clear = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.clear]');
                        return [4 /*yield*/, clear()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SessionState.isLoggedIn = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.isLoggedIn]', []);
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%LOGGED_IN%]"))];
                    case 1: return [2 /*return*/, !!(_a.sent())];
                }
            });
        });
    };
    SessionState.getLoggedIn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.getLoggedIn]', []);
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%LOGGED_IN%]"))];
                    case 1:
                        value = _a.sent();
                        if (value) {
                            return [2 /*return*/, new Date(JSON.parse(value || ''))];
                        }
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    SessionState.setLoggedIn = function (timestamp) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.setLoggedIn]', [timestamp]);
                        return [4 /*yield*/, setItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%LOGGED_IN%]"), JSON.stringify(timestamp.getTime()))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SessionState.hasToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.hasToken]', []);
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%TOKEN%]"))];
                    case 1: return [2 /*return*/, !!(_a.sent())];
                }
            });
        });
    };
    SessionState.getToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.getToken]', []);
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%TOKEN%]"))];
                    case 1:
                        value = _a.sent();
                        if (value) {
                            return [2 /*return*/, JSON.parse(value || '')];
                        }
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    SessionState.setToken = function (token) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.setToken]', [token]);
                        return [4 /*yield*/, setItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%TOKEN%]"), JSON.stringify(token))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SessionState.setTokenExpireTime = function (time) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.setTokenExpireTime]', [time]);
                        return [4 /*yield*/, setItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%TOKEN_EXPIRE_TIME%]"), JSON.stringify(time))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SessionState.getTokenExpireTime = function () {
        return __awaiter(this, void 0, void 0, function () {
            var value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.getTokenExpireTime]', []);
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%TOKEN_EXPIRE_TIME%]"))];
                    case 1:
                        value = _a.sent();
                        if (value) {
                            return [2 /*return*/, JSON.parse(value || '')];
                        }
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    SessionState.getRefreshToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.getRefreshToken]', []);
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%REFRESH_TOKEN%]"))];
                    case 1:
                        value = _a.sent();
                        if (value) {
                            return [2 /*return*/, JSON.parse(value || '')];
                        }
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    SessionState.setRefreshToken = function (refreshToken) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.setRefreshToken]', [refreshToken]);
                        return [4 /*yield*/, setItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%REFRESH_TOKEN%]"), JSON.stringify(refreshToken))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SessionState.setRefreshTokenExpireTime = function (time) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.setRefreshTokenExpireTime]', [time]);
                        return [4 /*yield*/, setItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%REFRESH_TOKEN_EXPIRE_TIME%]"), JSON.stringify(time))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SessionState.getRefreshTokenExpireTime = function () {
        return __awaiter(this, void 0, void 0, function () {
            var value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.getRefreshTokenExpireTime]', []);
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%REFRESH_TOKEN_EXPIRE_TIME%]"))];
                    case 1:
                        value = _a.sent();
                        if (value) {
                            return [2 /*return*/, JSON.parse(value || '')];
                        }
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    SessionState.getUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            var value, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.getUser]', []);
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%USER%]"))];
                    case 1:
                        value = _a.sent();
                        if (value) {
                            user = JSON.parse(value || '');
                            return [2 /*return*/, new data_1.User(user)];
                        }
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    SessionState.setUser = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.setUser]', [user]);
                        return [4 /*yield*/, setItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%USER%]"), JSON.stringify(user.toObject()))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SessionState.getLanguage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.getLanguage]', []);
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%USER_LANGUAGE%]"))];
                    case 1:
                        value = _a.sent();
                        if (value) {
                            return [2 /*return*/, value || ''];
                        }
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    SessionState.setLanguage = function (language) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Session.setLanguage]', [language]);
                        return [4 /*yield*/, setItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%USER_LANGUAGE%]"), language)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return SessionState;
}());
exports.SessionState = SessionState;
