import { Button, ButtonSizes } from "@roc-digital/ui-web";
import { useEffect, useRef, useState } from "react";
import subscribe1 from '../../../assets/subscribe1.png';
import { useLocation } from "react-router";

export interface ContentLayoutProps {
  title: string;
  description?: string;
  subtext?: string;
  children?: any;
  version?: number;
}

export function ContentLayout(props: ContentLayoutProps) {
  const subscribeRef = useRef<HTMLDivElement>(null); // Specify the type of the ref
  const [version, setVersion] = useState<string | null>(null);
  const location = useLocation(); // React Router hook to get the current URL location

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const versionParam = params.get('version');
    if (versionParam) {
      setVersion(versionParam);
    }
  }, [location.search]);

  const handleClickEvent = () => {
    if (subscribeRef.current) {
      subscribeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }
  
  const version0 = <>
      <div className='flex flex-col align-center gap-[32px]  max-w-[1024px]' style={{textAlign: 'center', alignItems: 'center'}}>
      <h1 className="font-bold text-[38px] sm:text-[32px]" style={{fontWeight: 'bolder', fontFamily: 'Chivo,sans-serif'}}>
        {props.title}
      </h1>
      <span className="text-[24px] sm:text-[16px]">
        {props.description}
      </span>
      <span className="text-[16px] sm:text-[12px]" style={{fontWeight: '600', color: '#545454'}}>
        {props.subtext}
      </span>
    </div>
  </>;

  const version1 = <>
      <div className="text-center">
        <div className="font-2xl font-[800] text-[42px]">GET UNLIMITED ACCESS TO NEWS<br/>WITHOUT MAINSTREAM BIAS</div>
      </div>
      <div className="mx-auto w-[200px] my-8">
        <Button clickEvent={() => handleClickEvent()} size={ButtonSizes.large}>SUBSCRIBE NOW</Button>
      </div>
      <div className="mb-16 max-w-[900px] mx-auto"><img src={subscribe1} className="w-full h-auto rounded-xl"/></div>
  </>;

  const version2 = <>
    <div className="mx-auto max-w-[800px] text-center bg-[#24aae1] rounded-xl py-4 px-8" onClick={() => handleClickEvent()}>
      <div className="font-[800] text-[38px] text-white">FULL ACCESS MEMBERSHIP</div>
      <div className="text-left text-white">Cancel or pause at any time</div>
      <div className="flex items-center pt-2 cursor-pointer">
        <div className="h-6 w-6 rounded-full bg-gray-300"></div>
        <div className="uppercase text-white ml-2 hover:underline">$2.49 Per Week, Billed Every 4 weeks</div>
      </div>
      <div className="flex items-center py-2 cursor-pointer">
        <div className="h-6 w-6 rounded-full bg-gray-300"></div>
        <div className="uppercase text-white ml-2 hover:underline">$1.86 Per Week, Billed Once A Year</div>
      </div>
    </div>
    <div className="mx-auto w-[200px] my-8">
      <Button clickEvent={() => handleClickEvent()} size={ButtonSizes.large}>SUBSCRIBE NOW</Button>
    </div>
    <div className="mb-4 mx-auto max-w-[900px]"><img src={subscribe1} className="w-full h-auto rounded-xl"/></div>
    <div className="text-center font-[800] text-[34px] uppercase">Here's what you get...</div>
    <div className="uppercase text-center">
      <div>Ad-free mainstream news without mainstream bias</div>
      <div>Local news from your community</div>
      <div>top podcasts, interviews, and viral must-see content</div>
    </div>
    <div className="mx-auto w-[200px] my-8">
      <Button clickEvent={() => handleClickEvent()} size={ButtonSizes.large}>SUBSCRIBE NOW</Button>
    </div>
  </>;
  
  return <div className='sl-content-layout flex flex-col align-center w-full pb-[200px] ' style={{color: '#1E222A', 'alignItems': 'center'}}>
    {!version || version === '0' ? version0 : null }
    {version === '1' ? version1 : null }
    {version === '2' ? version2 : null }
    <div ref={subscribeRef}  className="pt-8 mb-8 mx-auto max-w-[1024px]">
      {props.children}
    </div>
  </div>;

}