import { useEffect, useState } from 'react';
import { subscribe, unsubscribe } from '@roc-digital/mxm-base/events';
import { Event } from '@roc-digital/mxm-base/types';

import { StateEventCallback } from '../types';

// The useStateEvent hook allows listening for component events.
//
// A state value is returned by the hook. This value is updated with the event data
// every time the event is published unless the callback function returns a value, in
// which case it would be used to update the state instead of the payload.
export function useStateEvent<T>(
  namespace: string,
  eventName: string,
  initialState?: T | null,
  fn?: StateEventCallback<T>
): [T | null | undefined, React.Dispatch<React.SetStateAction<T | null | undefined>>] {
  const [state, setState] = useState<T | null | undefined>(initialState);

  useEffect(() => {
    const key = subscribe<T>(namespace, eventName, (evt: Event<T>) => {
      // When a callback is defined call it and if available
      // use the return value to update the state.
      if (fn) {
        const value = fn(evt);

        if (value !== undefined) {
          setState(value);

          return;
        }
      }

      // By default use the payload as value
      setState(evt.data);
    });

    return () => {
      unsubscribe(namespace, eventName, key);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn]);

  return [state, setState];
}
