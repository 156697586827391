import {  useMethod } from '@roc-digital/ui-lib';
import { useMemo } from 'react';

export interface IconV2Props {
  src: string;
  size: number | [number, number];
  stroke?: string;
  fill?: string;
  onClick?: () => void;
}

export function IconV2(props: IconV2Props) {
  const query = useMethod(fetchSvg, {autoRun: [props.src]});
  const svg = useMemo(() => {
    return alterSVG(query.result, props.fill, props.stroke);
  }, [query.result, props.stroke, props.fill]);

  let width = 16;
  let height = 16;

  if(props.size instanceof Array) {
    width  = props.size[0];
    height = props.size[1];
  } else {
    width = props.size;
    height = props.size;
  }

  if(!svg) {
    return <></>;
  }

  return <img
    src={svg}
    style={{width: width + 'px', height: height + 'px'}}
    onClick={props.onClick}
  />;

}

async function fetchSvg(src: string) {
  if(!src) return '';

  if(src.startsWith('<svg')) {
    return src;
  }

  if(src.startsWith('data:image/svg+xml')) {
    return decodeURIComponent(src.split(',')[1]);
  }

  if(src.endsWith('.svg') !== true) {
    return src;
  }

  const result = await fetch(src, {cache: 'force-cache'});

  const text = await result.text();

  if(!text || !text.startsWith('<svg')) {
    return '';
  } else {
    return text;
  }
}

function alterSVG(svg = '', fill = '', stroke = ''): string {

  if(!svg) return '';

  if(svg.startsWith('<svg') == false) {
    return svg;
  }

  if (fill) {
    svg = svg.replace(/fill="#?[^"]*"/ig, `fill="${fill}"`);
  }
  if (stroke) {
    svg = svg.replace(/stroke="#?[^"]*"/ig, `stroke="${stroke}"`);
  }

  return 'data:image/svg+xml,' + encodeURIComponent(svg);
}