import { Button, ButtonSizes, ButtonVariants, Heading1, Input } from '@roc-digital/ui-web';
import React, { useLayoutEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { ChipList, ChipItem } from './ChipList';
import { useMethod, useProfile } from '@roc-digital/ui-lib';
import { createArticleUnlockToken } from '@/logic/article-unlock-token';
import { emailArticle } from '@/logic/emailArticle';
import { ArticleSchema } from '@roc-digital/mxm-base/src/data';
import { toast } from 'react-toastify';

type Props = {
  description?: string;
  article: ArticleSchema;
};

const ShareThis = ({ description = 'MXM Subscription', ...props }: Props) => {
  const profile = useProfile(true);
  const isPremiumUser = profile && profile?.subscribed;
  const isPremiumArticle = props.article?.paywall === 'paid';

  const url = window.location.origin + '/article/' + props.article.id;
  const title = props.article.title.length > 140 ? props.article.title.substring(0, 140) + '...' : props.article.title;

  const showEmailShare = isPremiumUser && profile?.email && isPremiumArticle;

  const loadShareButtons = () => {
    let shareUrl = url;
    /* @ts-ignore */
    window.__sharethis__.load('inline-share-buttons', {
      alignment: 'left',
      id: 'my-inline-buttons',
      enabled: true,
      font_size: 11,
      // padding: 8,
      radius: 8,
      networks: ['messenger', 'email', 'twitter', 'facebook', 'pinterest', 'sms', 'wechat', 'sharethis'],
      // size: 32,
      show_mobile_buttons: isMobile,
      spacing: 4,
      url: shareUrl,
      title: title ? title + ' ~ MxM News' : 'MxM News',
      language: "en",
      image: "https://18955-presscdn-pagely.netdna-ssl.com/wp-content/uploads/2016/12/ShareThisLogo2x.png", // useful for pinterest sharing buttons
      description: description,
      username: "mxmnews"
    });
  }

  useLayoutEffect(() => {
    loadShareButtons();
  }, []);

  return (
    <div className="p-8 dark:bg-surface-dark" onLoad={() => loadShareButtons()}>
      <Heading1 className="font-[Chivo] !font-bold text-3xl dark:text-white mb-8">Share This Content</Heading1>
      <div id="my-inline-buttons" className="sharethis-inline-share-buttons inline-share-buttons"></div>
      {showEmailShare ? <EmailPremiumArticle
        url={url}
        userId={profile?.email}
        article={props.article}
      /> : null}
    </div>
  );
};

export default ShareThis;


interface EmailPremiumArticleProps {
  userId: string;
  url: string;
  article: ArticleSchema;
}

function EmailPremiumArticle(props: EmailPremiumArticleProps) {

  const userId = props.userId || '';
  const url = props.url || '';

  const [emails, setEmails] = React.useState<ChipItem[]>([]);

  const sendQuery = useMethod(emailArticle, {
    onSuccess: () => {
      toast.success('Thank you for sharing this article!');
      setEmails([]);
    },
    onError: () => {
      toast.error('There was an error sharing this article.');
    }
  });

  const [emailInput, setEmailInput] = React.useState('');
  const inputState = validateEmailString(emailInput);

  const updateEmailItems = () => {
    const { invalidEmails, validEmails} = validateEmailString(emailInput);
    setEmailInput(invalidEmails.join(';'));

    const allEmails = [
      ...emails.map(e => e.label),
      ...validEmails
    ].filter((e, i, l) => l.indexOf(e) === i).slice(0, 5);
    const items = allEmails.map(email => ({ label: email }));
    setEmails(items);

    return {
      items,
      invalidEmails,
    }
  }

  const sharePremiumArticle = () => {
    const state = updateEmailItems();
    const addresses = state.items.map(e => e.label);
    if (!url || !props.article?.id || !userId || !addresses.length) return;

    const pat = 'pat=' + createArticleUnlockToken(props.article?.id, userId)
    const patUrl = url + (url.includes('?') ? '&' : '?') + pat;
    sendQuery.run({
      addresses: addresses,
      article: props.article,
      url: patUrl,
    });
  }

  const canSendEmail = inputState.validEmails.length || emails.length;

  return <div className='flex flex-col gap-[8px] mt-[16px]'>
    <div
      className='font-[Chivo] !font-bold text-2xl dark:text-white mb-8"'
    >
      Share premium content with friends ({emails.length} out of 5 used)
    </div>
    <div>
      <ChipList
        value={emails}
        onChange={setEmails}
      />
    </div>
    <div>
      <Input
        className='w-full'
        value={emailInput}
        placeholder='Enter a friend’s email'
        changeEvent={setEmailInput}
        disabled={emails.length == 5 || sendQuery.loading}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            updateEmailItems();
          }
        }}
      />
    </div>
    <div>
      <Button
        variant={ButtonVariants.primary}
        size={ButtonSizes.smallFull}
        customClassName='w-full'
        disabled={!canSendEmail || sendQuery.loading}
        loading={sendQuery.loading}
        clickEvent={sharePremiumArticle}
      >
        Share Article
      </Button>
    </div>
  </div>
}

function validateEmailString(emailInput: string) {
  const validEmails: string[] = [];
  const invalidEmails: string[] = [];
  emailInput.split(/;|,|\s/g)
    .map(text => text.trim())
    .forEach(email => {
      try {
        const [handle, domain] = email.split('@');
        if (!handle || (domain || '').split('.').length < 2) {
          invalidEmails.push(email);
          return;
        }
      } catch (err) {
        invalidEmails.push(email);
        return;
      }
      validEmails.push(email);
    });

  return {
    validEmails,
    invalidEmails,
  }
}