import { Blurhash } from '../Blurhash';

export interface ArticleImageProps {
  src: string;
  height?: number;
  blurhash?: string;
  style?: any;
  className?: string;
  onClick?: () => void;
  mini?: boolean;
  href?: string;
}

export function ArticleImage(props: ArticleImageProps) {

  const image = <img src={props.src} className={`${props.mini ? 'min-w[230px]' : ''}`} style={{ opacity: 0, position: 'relative', transition: 'opacity 2s', boxShadow: '0px 0px 12px 17px #0000004d', objectFit: 'contain', height: '100%'}} onLoad={(e) => e.target['style'].opacity = 1}></img>;

  return <div
    className={`relative flex content-center justify-center ${props.className || ''} cursor-pointer`} style={{overflow: 'hidden', maxHeight: '160px'}}
    onClick={props.onClick}
  >
    <div className='absolute' style={{width: '100%', height: '100%'}}>
      {props.blurhash ? 
        <Blurhash hash={props.blurhash || ''} height={100}/>
        :
        <div className={`opacity-75  absolute h-full w-full bg-cover bg-center blur-lg`} style={{backgroundImage: `url(${props.src})`}}></div>
      }
    </div>
    {props.href ? <a
      href={props.href}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onClick?.();
      }}
    >
      {image}
    </a> : image}
  </div>
}