import AppStoreButton from './AppStoreButton';
import PlayStoreButton from './PlayStoreButton';

interface Props {
  customClassName?: string;
}

const GetAppButtonGroup = ({ customClassName = '' }: Props) => {
  return (
    <div className={`flex items-center gap-4 ${customClassName}`}>
      <AppStoreButton />
      <PlayStoreButton />
    </div>
  );
};

GetAppButtonGroup.displayName = 'GetAppButtonGroup';

export default GetAppButtonGroup;
