import { ArticleViewProps } from '@roc-digital/ui-lib';
import { ActionBar } from './ActionBar';
import { MetaBar } from './MetaBar';
import { Card } from './Card';
import { ArticleHeadline } from './ArticleHeadline';
import './Mini.scss';

export function Mini(props: ArticleViewProps) {

  const showActions = props.actions !== false;

  return <Card
    noFrame={props.noFrame}
    className={`mini-article ${props.className}`}
    orientation={'landscape'}
  >
    {props.hideMeta !== true ? <>
      <MetaBar
        logo={props.article.logo}
        timeLapse={props.article.timeLapse}
        tag={props.hideTag !== true ? props.article.tag : undefined}
      />
    </> : <></>}
    <ArticleHeadline
      size={'md'}
      headline={props.article.headline}
      blurhash={props.article?.blurhash}
      onClick={() => props.onAction && props.onAction({action: 'open', articleId: props.article.id})}
      children={props.children}
      image={!props.hideImage ? props.article.image : undefined}
      href={props.article.href}
    />
    {showActions ?
      <ActionBar
        bookmarked={props.article.bookmarked}
        voted={props.article.voted}
        upvotes={props.article.upvotes || 0}
        downvotes={props.article.downvotes || 0}
        articleId={props.article.id || ''}
        onAction={props.onAction || (() => {})}/>
    : null}
  </Card>;
}
