import React from 'react';
import SubscribeBanner from './SubscribeBanner';
import { usePayWall } from '@/logic/usePaywall';

export interface PaywallOverlayProps {
  message?: string;

}

export function PaywallOverlay(props: PaywallOverlayProps) {
  const showPaywall = usePayWall();

  if(!showPaywall) return <></>;

  return <div style={{
    position: 'fixed',
    paddingLeft: '7vw',
    paddingRight: '7vw',
    right: '0px',
    left: '0px',
    top: '33vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }}>
    <SubscribeBanner message={props.message}/>
  </div>;
}