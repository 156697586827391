"use strict";
/* eslint-disable import/no-cycle */
// @copyright 2023 MXM, Inc
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreState = void 0;
var logging_1 = require("../logic/logging");
var STORE = {};
/**
 * In-memory store class.
 */
var StoreState = /** @class */ (function () {
    function StoreState() {
    }
    StoreState.has = function (key) {
        (0, logging_1.log)('[Store.has]', [key]);
        return typeof STORE[key] !== 'undefined';
    };
    StoreState.get = function (key, silent) {
        if (silent === void 0) { silent = false; }
        if (!silent) {
            (0, logging_1.log)('[Store.get]', [key]);
        }
        if (typeof STORE[key] !== 'undefined') {
            return STORE[key];
        }
        return undefined;
    };
    StoreState.set = function (key, value) {
        (0, logging_1.log)('[Store.set]', [key, value]);
        STORE[key] = value;
    };
    StoreState.add = function (key, value) {
        (0, logging_1.log)('[Store.add]', [key, value]);
        if (typeof STORE[key] === 'undefined' || !Array.isArray(STORE[key])) {
            return false;
        }
        STORE[key].push(value);
        return true;
    };
    StoreState.remove = function (key) {
        (0, logging_1.log)('[Store.remove]', [key]);
        delete STORE[key];
    };
    StoreState.clear = function () {
        (0, logging_1.log)('[Store.clear]', []);
        STORE = {};
    };
    return StoreState;
}());
exports.StoreState = StoreState;
