import { ContentLayout } from './ContentLayout';
import { Card } from './Card';
import { useNav } from './utils';
import { getProfile } from '@roc-digital/ui-lib';
import Subscription, { SubscriptionProps } from '@/components/elements/Subscription';
import { useSearchParams } from 'react-router-dom';
import { Button, ButtonSizes, ButtonVariants } from '@roc-digital/ui-web';

export function Payment() {
  const [search] = useSearchParams();
  const nav = useNav();

  const defaultPlan: SubscriptionProps['defaultPlan'] = search.get('plan') === 'annual' ? 'annual' : 'monthly';

  return <ContentLayout title='Subscribe NOW for one month FREE'
    description='Woke news organizations have had too much influence for too long. Join MxM and take back control of information in America.'
    subtext='Save 30% with a yearly subscription.'
  >
    <Card title='Purchase Subscription'>
      <Subscription
        defaultPlan={defaultPlan}
        noFrame
        onComplete={() => {
          nav('/subscribe/thank-you')
        }}
        preCheck={() => {
          const profile = getProfile();
          if(profile?.subscribed) {
            nav('/subscribe/thank-you')
            return false;
          } else if(!profile?.id) {
            nav('/subscribe/login')
            return false;
          }

          return true;
        }}
      />
      <Button variant={ButtonVariants.plain} size={ButtonSizes.largeFull} clickEvent={() => nav('/trending', {cancel: 'yes'})}>Cancel</Button>
    </Card>
  </ContentLayout>;
}