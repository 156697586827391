import { publish } from '@roc-digital/mxm-base/events';

import { ChangeEvent, CustomEvent, InputData, getClassNames, checkIcon } from '@roc-digital/ui-lib';
import { Icon } from './Icon';

import styles from './Checkbox.module.css';

type Props = {
  id?: string;
  className?: string;
  label?: React.ReactNode;
  name?: string;
  value?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  changeEvent?: ChangeEvent | CustomEvent;
};

const labelClasses = `${styles.label} text-font flex items-center h-7 leading-5 text-sm font-normal`;
const inputClasses = 'hidden';
const labelTextClasses = 'ml-2';
const checkmarkClasses = {
  base: 'border-edge flex w-5 h-5 rounded-md transition-all duration-200 ease-linear cursor-pointer shadow-sm',
  state: {
    checked: 'bg-action-primary',
    disabled: 'bg-secondary border-edge text-font opacity-50',
  },
};

export const Checkbox = ({ id, className, label, changeEvent, value, disabled }: Props) => {
  const publishChange = (evt: React.MouseEvent<HTMLSpanElement>) => {
    if (!changeEvent) {
      return;
    }

    if (changeEvent.allowDefault === false) {
      evt.preventDefault();
    }

    if (changeEvent.propagate === false) {
      evt.stopPropagation();
    }

    publish<InputData>(changeEvent.namespace, 'change', {
      id,
      eventData: changeEvent.data || {},
      value: !value,
    });
  };

  let state = '';

  if (disabled) {
    state = 'disabled';
  } else if (value) {
    state = 'checked';
  }

  const markClassName = getClassNames(checkmarkClasses, { state });

  className = className ? `${labelClasses} ${className}` : labelClasses;

  return (
    <label className={className} onClick={(evt) => evt.preventDefault()}>
      <input checked={value} value={id} className={inputClasses} type="checkbox" onChange={() => {}} />

      <span
        className={`${markClassName} flex items-center justify-center ${value ? '' : 'border-2 border-slate-400'}`}
        onClick={publishChange}
      >
        {value ? <Icon src={checkIcon} fill={'white'} stroke={'white'} size={'small'} /> : null}
      </span>

      {label && <span className={labelTextClasses}>{label}</span>}
    </label>
  );
};
