export function getEnv() {
  const mxm: {[key: string]: string} = window['mxm_config' as any] as any || {};

  return {
    webApi: mxm['REACT_APP_WEB_API_URL'] || process.env.REACT_APP_WEB_API_URL || undefined,
    auxApi: mxm['REACT_APP_AUX_API_URL'] || process.env.REACT_APP_AUX_API_URL || undefined,
    adminApi: mxm['REACT_APP_ADMIN_API_URL'] || process.env.REACT_APP_ADMIN_API_URL || undefined,
    stripePublishableKey: mxm['REACT_APP_STRIPE_PUBLISHABLE_KEY'] || process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || undefined,
    firebaseConfig: mxm['REACT_APP_FIREBASE_CONFIG'] || process.env.REACT_APP_FIREBASE_CONFIG || undefined,
  };
}

export function getEnvKey(key: keyof ReturnType<typeof getEnv>, error?: string) {
  const value = getEnv()[key];
  if(!value && error) {
    throw new Error(error);
  }

  return value || '';
}

export function getEnvJson<T>(key: keyof ReturnType<typeof getEnv>, error?: string) {
  const value = getEnvKey(key, error).replace(/\\"/g, '"');
  try {
    return JSON.parse(value) as T;
  } catch(err) {
    throw error + ' - Failed to parse json. ' + err + value;
  }
}