import { PromoBoxes } from '@/components/elements/PromoBoxes';
import Subscription from '@/components/elements/Subscription';
import { readProfile } from '@roc-digital/mxm-base/logic';
import { useMethod, useProfile } from '@roc-digital/ui-lib';
import { Button, Modal, ButtonSizes, ButtonVariants } from '@roc-digital/ui-web';
import { useEffect } from 'react';

const Billing = () => {
  const profile = useProfile();
  useMethod(readProfile, {autoRun: []});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openPaymentModal = (plan: 'monthly' | 'annual') => {
    const paymentCardForm = <div className="p-12 dark:bg-black dark:text-white">
      <div className="text-xl w-full font-bold mb-2">Subscribe to MxM News+</div>
      <Subscription defaultPlan={plan} />
    </div>;
    Modal.open('mxm.modal', paymentCardForm, 'small');
  };

  return (
    <div className="flex flex-col max-w-2xl w-full text-sm dark:text-white gap-2">
      <div className="text-2xl font-bold mb-6">Billing and Payments</div>

      {!profile?.subscribed ? <>
        <div className="text-xl w-full font-bold mb-2 bg-surface-primary p-4 rounded-md text-white mr-2">
          You currently have a free account. Try MxM News+ for FREE!
          <ul className='ml-2'>
            <li className='text-md'>• Get access to <b>EXCLUSIVE</b> Paid Content</li>
            <li className='text-md'>• Unlimited local content for over 200 American Communities</li>
            <li className='text-md'>• Up and down voting so you influence what stories are trending</li>
            <li className='text-md'>• Go AD free!</li>
          </ul>
         </div>
        <PromoBoxes
          signup={openPaymentModal}
        />
      </> : <>
        <div className="text-xl w-full font-bold mb-2">Congratulations!</div>
        <div className="text-xl w-full mb-2">You have an active subscription to MXM News+. Use your app to access, search, and browse through thousands of articles each day.</div>
        <hr className='mt-8 mb-8'/>
        <a target='_blank' href='https://billing.stripe.com/p/login/3cseYxenWdztgeY9AA'>
          <Button
            size={ButtonSizes.small}
            variant={ButtonVariants.square}
          >
            Manage Your Subscription
          </Button>
        </a>
      </>}

    </div>
  );
};

Billing.displayName = 'Billing';

export default Billing;
