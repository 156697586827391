import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useToken, brokenImagePlaceholderIcon } from '@roc-digital/ui-lib';

type Props = {
  src: string;
  className?: string;
  version?: 'large' | 'medium' | 'small';
};

export const AuthImage = forwardRef<HTMLImageElement, Props>(({ src, className, version }, ref) => {
  const token = useToken();
  const [error, setError] = useState(false);
  const imgSrc = useMemo(() => {
    if ((src.startsWith('https') && src.includes('mxm.global')) || src.includes('localhost:4200/media')) {
      return `${src}?accessToken=${token}${version ? `&version=${version}` : ''}`;
    }
    return src;
  }, [src, token, version]);

  useEffect(() => {
    setError(!token);
  }, [token]);

  return (
    <>
      {error || !token ? (
        <img ref={ref} src={brokenImagePlaceholderIcon} className={className} />
      ) : (
        <img ref={ref} src={imgSrc} className={className || ''} onError={() => setError(true)} />
      )}
    </>
  );
});

AuthImage.displayName = 'AuthImage';
