import { ReactNode } from 'react';
import { publish } from '@roc-digital/mxm-base/events';
import { ClickEvent, CustomEvent, PublisherData } from '@roc-digital/ui-lib';

export enum ButtonVariants {
  primary = 'primary',
  primaryPlain = 'primaryPlain',
  secondary = 'secondary',
  secondaryPlain = 'secondaryPlain',
  destructive = 'destructive',
  disabled = 'disabled',
  white = 'white',
  plain = 'plain',
  web = 'web',
  square = 'square',
}

export enum ButtonSizes {
  large = 'lg',
  medium = 'md',
  small = 'sm',
  xsmall = 'xs',
  largeFull = 'lgFull',
  mediumFull = 'mdFull',
  smallFull = 'smFull',
  xsmallFull = 'xsFull',
}

export type ButtonProps = {
  id?: string;
  variant?: ButtonVariants;
  size?: ButtonSizes;
  customClassName?: string;
  customTextClassName?: string;
  name?: string;
  disabled?: boolean;
  clickEvent?: ClickEvent | CustomEvent | Function;
  children?: ReactNode;
  title?: JSX.Element | string;
  loading?: boolean;
  isIconLeft?: boolean;
  icon?: JSX.Element | string;
};

const baseClasses = 'rounded-full leading-5 relative items-center justify-center font-bold text-base';

const variantClasses = {
  primary: 'bg-surface-primary',
  primaryPlain: 'bg-surface',
  secondary: 'bg-surface-secondary',
  secondaryPlain: 'bg-neutral-200',
  destructive: 'bg-surface-critical',
  disabled: 'bg-surface-secondary-high',
  white: 'bg-white border border-[#24AAE1]',
  plain: 'bg-transparent',
  web: 'bg-surface-primary rounded-full',
  square: 'bg-white border border-slate-200 rounded-none',
};

const sizeClasses = {
  lg: 'h-14 w-full max-w-80',
  md: 'h-8 min-w-fit px-4',
  sm: 'h-6 min-w-fit px-4',
  xs: 'h-4 w-12',
  lgFull: 'h-14 w-full',
  mdFull: 'h-14 w-full',
  smFull: 'h-12 w-full',
  xsFull: 'h-12 w-full',
};

const textClasses = {
  primary: 'text-font-on-primary font-workSans font-medium',
  primaryPlain: 'text-font-primary font-workSans font-medium',
  secondary: 'text-font-on-secondary font-workSans font-medium',
  secondaryPlain: 'text-font-secondary font-workSans font-medium',
  destructive: 'text-font-on-critical font-workSans font-medium',
  disabled: 'text-font-on-disabled font-workSans font-medium',
  white: 'text-font-on-secondary font-workSans font-medium',
  plain: 'text-font font-workSans font-medium dark:text-white',
  square: 'text-black font-workSans font-medium',
};

const textSizeClasses = {
  lg: 'text-lg',
  md: 'text-base',
  sm: 'text-sm',
  xs: 'text-xs',
  lgFull: 'text-base',
  mdFull: 'text-base',
  smFull: 'text-sm',
  xsFull: 'text-sm',
};

export const Button = ({
  children,
  title,
  isIconLeft = false,
  id,
  customClassName = '',
  customTextClassName = '',
  variant = ButtonVariants.primary,
  size = ButtonSizes.large,
  disabled,
  clickEvent,
  loading = false,
  icon,
}: ButtonProps) => {
  const publishClick = (event: any) => {
    if (!clickEvent || disabled || loading) {
      return;
    }
    if(typeof clickEvent === 'function') {
      clickEvent(event);
    } else {
      publish<PublisherData>(clickEvent.namespace, 'click', {
        id,
        eventData: clickEvent.data || {},
      });
    }
  };

  if (disabled) {
    variant = ButtonVariants.disabled;
  }

  const getContent = (): ReactNode => {
    if (title !== undefined) {
      return (
        <div className="flex flex-row items-center justify-center">
          {isIconLeft ? icon : null}
          <div>{title}</div>
          {!isIconLeft ? icon : null}
        </div>
      );
    }
    return typeof children === 'string' ? <div>{children}</div> : children;
  };

  return (
    <button
      className={`${variantClasses[variant]} ${sizeClasses[size]} ${baseClasses} ${customClassName}`}
      onClick={publishClick}
      disabled={disabled}
    >
      <div className={`${textClasses[variant]} ${textSizeClasses[size]} ${customTextClassName}`}>
        {loading ? '...' : (getContent() as any)}
      </div>
    </button>
  );
};
