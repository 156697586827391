import { ArticleSchema, TagSchema } from '@roc-digital/mxm-base/data';
import { formatDistanceToNow, fromUnixTime } from 'date-fns';
import { ArticleAction, ArticleContent } from '../types/Article';

export function mapToArticleContent(article: ArticleSchema, base: Partial<ArticleContent> = {}, noLink = false) {
  const tag = selectTag(article.tags || []);
  const publication =  article?.tags?.find?.((tag) => tag.type === 'publication') || {tag:''};

  return {
    id: article.id,
    title: article.title,
    image: (article.image_url ? article.image_url : 'https://qa-mxmnews.com/photos/no-image.svg'),
    url: article.url,
    href: noLink !== true ? ('/article/' + article.id) : undefined,
    headline: article.title,
    publication: publication?.tag,
    logo: article?.tags?.[0]?.image_url || extractLogo(article.url, publication, article.content_type),
    tag: tag?.name || '',
    publicationImage: article?.tags?.[0]?.image_url,
    timeLapse: timePassedSince(article.date || 0),
    upvotes: article.upvotes,
    downvotes: article.downvotes,
    saved: article.bookmarked,
    voted: article.voted === 1 ? 'up' : article.voted === -1 ? 'down' : null,
    bookmarked: article.bookmarked ? true : false,
    blurhash: article?.blur_hash,
    isFree: article.content_type !== 'mxm_exclusive' || article.paywall === 'free',
    ...base
  } as ArticleContent
}

function extractLogo(url: string, publication?: any, content_type?: string) {
  const mxm_logo = 'https://storage.googleapis.com/rocdigital-pub-assets/mxm_news.svg';
  if (publication && publication.name && (publication.name.indexOf('mxm') > -1)) {
    return mxm_logo;
  }
  if (content_type && content_type === 'mxm_exclusive') {
    return mxm_logo;
  }
  if (url) {
    const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^\/?#]+)/i;
    const matches = url.match(domainRegex);
    if (matches && matches.length > 1) {
      const favicon = 'https://s2.googleusercontent.com/s2/favicons?domain=' + matches[1];
      return favicon;
    }
  }
  return '';
}

function timePassedSince(timestamp: number): string {
  const dateFromTimestamp = fromUnixTime(timestamp);

  return formatDistanceToNow(dateFromTimestamp) + ' ago';
}

export interface ArticleActionHandlerDeps {
  get: () => ArticleSchema[];
  set: (next: ArticleSchema[]) => void;
  navigate: (article: ArticleSchema) => void;
  vote: (article: ArticleSchema, vote: 'up-vote' | 'down-vote') => Promise<ArticleSchema>;
  bookmark: (article: ArticleSchema) => Promise<ArticleSchema>;
  share?: (article: ArticleSchema) => void;
}

export function getArticleActionHandler(deps: ArticleActionHandlerDeps) {
  return (action: ArticleAction) => {
    
    const articles = deps.get();
    if(!articles?.length){
      return console.warn('No articles for action');
    }

    const article = articles.find((a: ArticleSchema) => a.id === action.articleId);

    if(!article){
      return console.warn('No article in memory for action');
    }

    if(action.action === 'open') {
      deps.navigate(article);
      return;
    }

    const replaceArticle = (article: ArticleSchema) => {
      const all = deps.get();
      const updates = all.map((a) => {
        if(a.id !== article.id) {
          return a;
        }

        return {...a, ...article};
      });

      deps.set(updates);
    }

    if(action.action === 'up-vote' || action.action === 'down-vote') {
      deps.vote(article, action.action).then(replaceArticle);
    } else if(action.action === 'bookmark') {
      deps.bookmark(article).then(replaceArticle);
    } else if(action.action === 'share') {
      if(deps.share) {
        deps.share(article);
      }
    }
  }
}

const _DaysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
function selectTag(tags: TagSchema[]) {
  const catTag = tags.find((tag) => tag.type === 'category' || tag.type === 'topic');

  if(catTag) return catTag;


  return tags.find((tag) => {
    const name = (tag.name?.toLocaleLowerCase() || '').trim();
    if(_DaysOfWeek.includes(name)) return false;

    return true;
  });
}