import { useCallback, useEffect, useState, RefObject } from 'react';

export const useContainerWidth = (containerRef: RefObject<HTMLDivElement | undefined>, defaultWidth = 600) => {
  const [width, setWidth] = useState(defaultWidth);
  const setCurrentWidth = useCallback(() => {
    const currentWidth = containerRef?.current?.offsetWidth;
    if (currentWidth) {
      setWidth(currentWidth);
    } else {
      setWidth(defaultWidth);
    }
  }, [containerRef, defaultWidth]);
  useEffect(() => {
    if (containerRef?.current) {
      new ResizeObserver(setCurrentWidth).observe(containerRef.current);
    }
  }, [containerRef, setCurrentWidth]);
  return width;
};
