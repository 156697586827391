import { Button, ButtonSizes, Heading1, Modal } from '@roc-digital/ui-web';
import NewsletterModal from './NewsletterModal';

const NewsletterBanner = () => {

  const openModal = () => {
    Modal.open('mxm.modal', <NewsletterModal />, 'small');
  };

  return (
    <div className={'flex my-8 w-full py-6 px-1 bg-[#24AAE1] items-center justify-between'}>
      <span className={'flex items-center flex-col w-full text-center max-w-2xl mx-auto mt-6'}>
        <Heading1 className={'text-white !font-chivo !font-extrabold text-[56px] leading-[56px] sm:text-[48px]'}>
          Keep in the know!
        </Heading1>
        <span className={'flex items-center w-full justify-center my-12'}>
          <div className="mx-auto w-80">
            <Button
              title={'Sign up for our Daily Digest'}
              id={'login'}
              size={ButtonSizes.large}
              customClassName={'bg-[#24AAE1] border-2 border-white mr-4'}
              customTextClassName={'text-white !text-md'}
              clickEvent={() => openModal()}
            />
          </div>
        </span>
      </span>
    </div>
  );
};

NewsletterBanner.displayName = 'NewsletterBanner';

export default NewsletterBanner;
