/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { subscribe, unsubscribe } from '@roc-digital/mxm-base/events';

import { unique } from '../utils';
import { EventCallback, CustomEvent, EventOptions } from '../types';
import { Data } from '@roc-digital/mxm-base/types';

export const DEFAULT_EVENT_OPTIONS: CustomEvent = {
  type: '',
  namespace: '',
  propagate: true,
  allowDefault: true,
};

// The useCustomEvent hook allows listening for component events.
export function useCustomEvent<T>(
  eventName: string,
  fn: EventCallback<T>,
  opts?: EventOptions,
  data?: Data,
  deps: any[] = []
): CustomEvent {
  const [event, setEvent] = useState<CustomEvent>({ ...DEFAULT_EVENT_OPTIONS });

  const callback = useCallback(fn, deps);
  const dataMemo = useMemo(() => data, deps);
  const optsMemo = useMemo(() => opts, deps);

  useEffect(() => {
    const namespace = optsMemo?.namespace || unique();

    // Assign the event for the current options and namespace
    setEvent({ ...DEFAULT_EVENT_OPTIONS, ...(optsMemo || {}), type: eventName, namespace, data: dataMemo });

    const key = subscribe<T>(namespace, eventName, callback);

    return () => {
      unsubscribe(namespace, eventName, key);
    };
  }, [eventName, callback, optsMemo, dataMemo]);

  return event;
}
