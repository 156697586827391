"use strict";
/* eslint-disable import/no-cycle */
// @copyright 2022 Navigo, Inc
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchV2 = exports.search = void 0;
var http_1 = require("./http");
var config_1 = require("../config");
var data_1 = require("../data");
var json_fetch_1 = require("./json-fetch");
var SERVICE_NAME = 'search';
function url() {
    return "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/search");
}
function resolveSearchResults() {
    return (0, http_1.createCollection)('_', data_1.Articles, data_1.Article);
}
function search(query, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'search', (0, http_1.createHTTPRequest)('GET', "".concat(url(), "?q=").concat(query), params), resolveSearchResults());
}
exports.search = search;
function searchV2(query) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, articles, tags;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        searchArticles(query),
                        searchTags(query),
                    ])];
                case 1:
                    _a = __read.apply(void 0, [_b.sent(), 2]), articles = _a[0], tags = _a[1];
                    return [2 /*return*/, {
                            articles: articles || [],
                            tags: tags || [],
                        }];
            }
        });
    });
}
exports.searchV2 = searchV2;
function searchArticles(q) {
    return __awaiter(this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                        method: "GET",
                        url: "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/articles?q=").concat(q)
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, (result === null || result === void 0 ? void 0 : result.data) || []];
            }
        });
    });
}
function searchTags(q) {
    return __awaiter(this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                        method: "GET",
                        url: "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/tags?q=").concat(q)
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, (result === null || result === void 0 ? void 0 : result.data) || []];
            }
        });
    });
}
