import { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { SessionState } from '@roc-digital/mxm-base/state';
import {
  notificationIcon,
  chevronRightIcon,
  interestsIcon,
  bookmarkIcon,
  themeIcon,
  followersIcon,
  accountFillBrandIcon,
  billingIcon,
  mapMarker,
  useProfile
} from '@roc-digital/ui-lib';
import { Icon } from '@roc-digital/ui-web';
import { signoutWithFirebase } from '@/logic';

const Settings = () => {
  const profile = useProfile();
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(true);
  const onSignout = () => {
    signoutWithFirebase(SessionState);
    navigate('/');
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const settings = [
    {
      icon: accountFillBrandIcon,
      title: 'Profile',
      description: 'Manage your username, email, password, and more.',
      route: '/account/profile',
    },
    {
      icon: billingIcon,
      title: 'Billing and Payments',
      description: profile?.subscribed ? 'Manage Your payment settings.' : 'Upgrade to MxM News+',
      route: '/account/billing',
    },
    {
      icon: notificationIcon,
      title: 'Notifications',
      description: 'Never miss a big story with updates about the news that matters to you.',
      route: '/account/notifications',
    },
    {
      icon: bookmarkIcon,
      title: 'Bookmarks',
      description: 'View your bookmarked articles.',
      route: '/account/saved',
    },
    {
      icon: interestsIcon,
      title: 'Interests',
      description: 'Add sections to your news feed.',
      route: '/account/interests',
    },
    {
      icon: mapMarker,
      title: 'Locations',
      description: 'Add locations to your news feed.',
      route: '/account/locations',
    },
  /*  {
      icon: followersIcon,
      title: 'Following',
      description: 'Choose public figures to follow.',
      route: '/account/following',
    }, */
    {
      icon: themeIcon,
      title: 'Theme',
      description: 'Automatic (follow system settings).',
      route: '/account/theme',
    },
  ];
  
  const settingsLinks = (
    <>
      {settings.map((setting) => (
        <Link
          key={setting.title}
          className="flex justify-between items-center gap-4 cursor-pointer hover:bg-surface-low rounded-l-xl p-2 pr-4 dark:hover:bg-surface-dark-high"
          to={setting.route}
        >
          <Icon src={setting.icon} />
          <div className="flex-1">
            <div className="font-bold">{setting.title}</div>
            <div className="text-font-low font-workSans hidden-xs">{setting.description}</div>
          </div>
          <Icon src={chevronRightIcon} fill="#919193" />
        </Link>
      ))}
    </>
  );

  const extraLinks = (
    <>
      <div className="flex flex-col border-b border-font-edge py-4 dark:text-white dark:border-font-muted">
        <Link
          className="font-bold flex justify-between items-center py-4 px-2 hover:bg-surface-low cursor-pointer pr-4 dark:hover:bg-surface-dark-high"
          to="/help"
        >
          <div className="font-bold">Help and Support</div>
          <Icon src={chevronRightIcon} fill="#919193" />
        </Link>
        <Link
          className="font-bold flex justify-between items-center py-4 px-2 hover:bg-surface-low cursor-pointer pr-4 dark:hover:bg-surface-dark-high"
          to="/privacy-policy"
        >
          <div className="font-bold">Privacy Policy</div>
          <Icon src={chevronRightIcon} fill="#919193" />
        </Link>
        <Link className="px-2 mt-4" to="/about">
          <div className="font-bold mb-1">About MxM News</div>
          <div className="text-font-low">MxM News 1.1.11</div>
        </Link>
      </div>
      <div
        className="py-6 flex items-center justify-center cursor-pointer font-semibold dark:text-white"
        onClick={onSignout}
      >
        Sign Out
      </div>
    </>
  );

  return (
    <div className="flex justify-center w-full h-full flex-1 border-t border-font-edge dark:border-[#444444]">
      <div className="w-full h-full flex flex-1">
        <div onClick={toggleMenu} className="absolute visible-xs mobile-menu-hamburger flex inline dark:text-white w-[75px] mr-6 ml-2 cursor-pointer">
          <img src={'/icons/back.svg'} className="h-10 w-10" /> Back
        </div>
        {menuVisible && (
          <div className="absolute w-full h-full visible-xs z-1000 bg-white dark:bg-surface-dark dark:text-white shadow-md p-5" onClick={toggleMenu}>
            <div className="font-bold text-xl mb-6 pl-2 dark:text-white">Account Settings</div>
            {settingsLinks}
            {extraLinks}
          </div>
        )}
        <div className="w-96 flex flex-col py-8 border-r border-font-edge hidden-xs pl-8 dark:bg-surface-dark-low dark:border-[#444444]">
          <div className="font-bold text-xl mb-6 pl-2 dark:text-white">Account Settings</div>
          <div className="flex flex-col gap-6 border-b border-font-low pb-4 dark:text-white dark:border-[#444444]">
            {settingsLinks}
          </div>
          {extraLinks}
        </div>
        <div className="visible-xs">
          <div className="flex flex-col mt-8">
            <Outlet />
          </div>
        </div>
        <div className="py-8 flex-1 hidden-xs dark:bg-surface-dark-high">
          <div className="flex flex-col ml-8">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

Settings.displayName = 'Settings';

export default Settings;
