import { ReactNode, forwardRef, useCallback, useEffect, useState } from 'react';
import { publish } from '@roc-digital/mxm-base/events';
import { Event } from '@roc-digital/mxm-base/types';
import {
  ChangeEvent,
  CustomEvent,
  InputData,
  PublisherData,
  useChangeEvent,
  useClickEvent,
  useMountedState,
  closeIcon,
  searchIcon,
} from '@roc-digital/ui-lib';
import { debounce } from '@roc-digital/mxm-base/logic';
import { Input } from './Input';
import { Pressable } from './Pressable';
import { List } from './List';
import { Icon } from './Icon';
import { Loading } from './Loading';
import { BodyDark } from './Typefaces';

export interface SearchResultsContainerProps {
  absolute?: boolean;
  children?: ReactNode;
}

const SearchResultsContainer = ({ absolute, children }: SearchResultsContainerProps) => {
  return (
    <div className={`${absolute ? 'absolute' : ''} top-16 left-0 right-0 z-20`}>
      <div
        style={{ maxHeight: '250px' }}
        className="bg-surface rounded-lg p-1 m-2 mt-0 shadow-black drop-shadow-lg overflow-auto"
      >
        {children}
      </div>
    </div>
  );
};

interface InputSearchProps extends Pick<SearchResultsContainerProps, 'absolute'> {
  id?: string;
  name?: string;
  data?: any[];
  search?: (value: string) => Promise<any[] | undefined>;
  listItem?: ({ item, index }: { item: any; index: number }) => ReactNode;
  separator?: ReactNode;
  placeholder?: string;
  value?: string;
  changeValueEvent?: ChangeEvent | ((value: string) => void);
  searchEvent?: CustomEvent;
  selectedEvent?: CustomEvent;
  enterEvent?: CustomEvent;
  absolute?: boolean;
  disabled?: boolean;
  pressableClasses?: string;
  resultsContainer?: React.FC;
  showLoading?: boolean;
  fullWidth?: boolean;
  hideResults?: boolean;
}

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
  (
    {
      id,
      name,
      data,
      search,
      listItem,
      separator,
      placeholder,
      value: initialValue,
      selectedEvent,
      disabled,
      changeValueEvent,
      searchEvent,
      enterEvent,
      absolute = true,
      pressableClasses = 'px-1 py-1 m-1 rounded-md',
      resultsContainer,
      showLoading = true,
      fullWidth = false,
      hideResults = false,
      ...props
    }: InputSearchProps,
    ref: any
  ) => {
    const [value, setValue] = useState<string>(initialValue || '');
    const [results, setResults] = useState<any[]>();
    const isMounted = useMountedState();
    const [showResults, setShowResults] = useState(false);
    const [selectedSuggestion, setSelectedSuggestion] = useState(-1);
    const [isFocused, setIsFocused] = useState(false);
    const ResultsContainer = resultsContainer || SearchResultsContainer;

    const publishSearchEvent = useCallback(() => {
      if (searchEvent) {

        publish(searchEvent.namespace, 'search', {
          id,
          eventData: searchEvent.data || {},
          value: results,
        });
      }
    }, [id, results, searchEvent]);

    const publishChangeValueEvent = useCallback(() => {
      if (changeValueEvent) {

        if (typeof changeValueEvent === 'function') {
          return changeValueEvent(value);
        }

        publish(changeValueEvent.namespace, 'change', {
          id,
          eventData: changeValueEvent.data || {},
          value,
        });
      }
    }, [changeValueEvent, id, value]);

    const publishSelectedEvent = useCallback(
      (data: PublisherData) => {
        if (selectedEvent) {
          publish(selectedEvent.namespace, 'selected', {
            id,
            selectedEvent,
            selectedItem: (results as any[])[parseInt(data.id || '0')],
            selectedIndex: data.id,
          });
          setShowResults(false);
        }
      },
      [id, results, selectedEvent]
    );

    const publishCleanSearchEvent = useCallback(() => {
      if (changeValueEvent) {

        if (typeof changeValueEvent === 'function') {
          return changeValueEvent(value);
        }

        publish(changeValueEvent.namespace, 'change', {
          id,
          eventData: changeValueEvent.data || {},
          value: '',
        });
      }
    }, [changeValueEvent, id]);

    const reset = () => {
      setValue('');
      setShowResults(false);
      setResults(undefined);
    };

    const resetSelection = () => setSelectedSuggestion(-1);

    useEffect(() => {
      setValue(initialValue || '');
    }, [initialValue]);

    useEffect(() => {
      setShowResults(results !== undefined);
    }, [results]);

    useEffect(() => {
      publishSearchEvent();
    }, [publishSearchEvent, results]);

    useEffect(() => {
      resetSelection();

      if (!value) reset();

      publishChangeValueEvent();
    }, [value, publishChangeValueEvent]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const runExternalSearch = useCallback(
      debounce(async ([search, searchValue, isMounted]) => {
        if (!search) return;

        try {
          const results = await search(searchValue);

          if (isMounted) {
            setResults(results);
            return;
          }
        } catch (err) {
          console.warn(err);
        }

        setResults([]);
      }, 300),
      []
    );

    const changeEvent = useChangeEvent(
      (event: Event<InputData>) => {
        const searchValue = event.data.value as string;

        setValue(searchValue);

        if (searchValue.length > 0) {
          setShowResults(true);
        }

        if (searchValue) {
          if (search) {
            runExternalSearch(search, searchValue, isMounted);
          } else {
            setResults(
              data?.filter((item: any) =>
                typeof item === 'string' ? item.toLowerCase().includes(searchValue.trim().toLowerCase()) : false
              )
            );
          }
        }
      },
      {},
      {},
      [search, isMounted, data, search, value]
    );

    const searchInputFocus = useCallback((evt: React.FocusEvent<HTMLInputElement, Element>) => {
      setIsFocused(true);
      if (evt.target.value.length > 0) {
        setShowResults(true);
      }
    }, []);

    const searchInputBlur = useCallback((evt: React.FocusEvent<HTMLInputElement, Element>) => {
      if (evt.relatedTarget === null) {
        setIsFocused(false);
      }
    }, []);

    const itemClickEvent = useClickEvent(
      (event: Event<PublisherData>) => {
        if (disabled) return;

        setShowResults(false);
        if (results) {
          setValue(results[parseInt(event.data.id || '0')]);
        }

        publishSelectedEvent(event.data);
      },
      { propagate: false, allowDefault: false },
      undefined,
      [disabled, selectedEvent, publishSelectedEvent, value]
    );

    const cleanSearchEvent = useClickEvent(
      () => {
        reset();
        publishCleanSearchEvent();
      },
      {},
      {},
      [publishCleanSearchEvent]
    );

    const handleKeyDown = useCallback(
      (evt: KeyboardEvent) => {
        if (!isFocused) return;

        if (evt.key === 'ArrowDown' && selectedSuggestion < (results?.length || 0) - 1) {
          evt.preventDefault();
          setSelectedSuggestion((prev) => prev + 1);
        }

        if (evt.key === 'ArrowUp' && selectedSuggestion > 0) {
          evt.preventDefault();
          setSelectedSuggestion((prev) => prev - 1);
        }

        if (evt.key === 'Enter' && selectedSuggestion >= 0) {
          evt.preventDefault();
          publishSelectedEvent({
            id: selectedSuggestion.toString(),
            eventData: {},
          });
          setShowResults(false);
        }

        if (evt.key === 'Enter' && (selectedSuggestion === -1 || !showResults)) {
          evt.preventDefault();
          if (enterEvent) {
            publish(enterEvent?.namespace, 'enter', {
              value,
            });
            setShowResults(false);
          }
          setShowResults(false);
        }
      },
      [isFocused, selectedSuggestion, results, showResults, publishSelectedEvent, enterEvent, value]
    );

    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown);

      return () => document.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    const renderItem = useCallback(
      ({ item, index, highlighted }: { item: any; index: number; highlighted?: number }) => {
        return (
          <Pressable
            className={`${pressableClasses} ${highlighted === index ? 'bg-surface-high' : ''}`}
            id={index.toString()}
            clickEvent={itemClickEvent}
          >
            {listItem ? listItem({ item, index }) : <BodyDark className="text-admin-dark text-lg">{item}</BodyDark>}
          </Pressable>
        );
      },
      [itemClickEvent, listItem, pressableClasses]
    );

    return (
      <div className={`flex flex-col relative ${fullWidth ? 'w-full' : ''} rounded-md overflow-hidden`}>
        <div className="flex flex-row items-center bg-background px-6 py-2 rounded-full w-full dark:bg-surface-dark-muted">
          <Icon className="max-w-none" src={searchIcon} />
          <Input
            ref={ref}
            name={name}
            inputContainerClassName={'w-full'}
            className="font-workSans w-full pr-10 pl-2 border-none bg-background font-normal text-sm focus:outline-none dark:bg-surface-dark-muted"
            value={value}
            placeholder={placeholder || 'Search'}
            changeEvent={changeEvent}
            onFocus={searchInputFocus}
            onBlur={searchInputBlur}
            refreshable={true}
            {...props}
          />
          {value && <Icon className="max-w-none right-0 mr-0 -ml-8" src={closeIcon} clickEvent={cleanSearchEvent} />}
        </div>
        {showResults && !hideResults && (
          <ResultsContainer absolute={absolute}>
            {results !== undefined ? (
              <List
                className="w-full"
                data={results}
                renderItem={renderItem}
                keyExtractor={(_item, index) => index.toString()}
                separator={separator || null}
                emptyMessage={'No Results'}
                highlighted={selectedSuggestion}
                listClassName={'items-start'}
              />
            ) : showLoading ? (
              <Loading circular className="p-10" />
            ) : null}
          </ResultsContainer>
        )}
      </div>
    );
  }
);

InputSearch.displayName = 'InputSearch';
