import { Navigate, Route, Routes, useLocation, useNavigate, useMatches, UIMatch, } from 'react-router';
import { Modal, Notifications } from '@roc-digital/ui-web';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContainer from './elements/AppContainer';
import Trending from '@/components/views/Trending';
import ForYou from '@/components/views/ForYou';
import PrivacyPolicy from './views/PrivacyPolicy';
import Terms from './views/Terms';
import Careers from './views/Careers';
import About from './views/About';
// import Subscribe from './views/Subscribe';
import { useAuthState } from '@/logic';
import Home from './views/Home';
import Account from './views/Account';
import Profile from './views/Account/Profile';
import SavedArticles from './views/Account/SavedArticles';
import AccountNotifications from './views/Account/Notifications';
import Interests from './views/Account/Interests';
import Locations from './views/Account/Locations';
import Following from './views/Account/Following';
import Billing from './views/Account/Billing';
import AccountTheme from './views/Account/AccountTheme';
import Article from '@/components/views/Article';
import { ForgotPassword } from './views/Login/ForgotPassword';
import Help from './views/Help';
import Header from './elements/Header';
import { useKeepTop } from '@/logic/useKeepTop';
import { SignIn } from '@/components/views/Login';
import Local from './views/Local';
import { Loading } from './elements/Loading';
import Landing from './views/Landing';
import Thanks from './views/Thanks';
import { SubscriberLanding } from './views/SubscriberLanding';
import { PopupMounter } from './elements/PopupMounter';
import { postAuth } from '@/logic/interest-tagging';
import { usePaywallRootCssClass } from '@/logic/usePaywallRootCssClass';

export const App = () => {
  const auth = useAuthState();
  usePaywallRootCssClass();

  const { pathname } = useLocation();
  const isLoginSignup = (pathname === '/login' || pathname === '/signup' || pathname === '/forgotpassword' ? true : false);
  const isLanding = (pathname === '/landing' || pathname === '/thanks' || pathname.startsWith('/subscribe') ? true : false);

  const loading = auth.authenticating || auth.loading;

  useKeepTop();

  React.useEffect(() => {
    if(auth.authenticated && !auth.isAnonymous) {
      postAuth();
    }
  }, [auth.authenticated])

  useEffect(() => {
    if (localStorage.theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  return (
    <>
      <div className="min-h-screen h-full flex flex-col dark:bg-surface-dark-low">
        {!isLoginSignup && !isLanding ? (
          <div className="relative z-1050 pt-2 dark:bg-surface-dark-high">
          <Header isLoggedOut={auth.authenticated !== true} />
        </div>
        ) : null}
        <AppContainer>
          <Routes>
            <Route path="/" element={<Home isAuthenticated={auth.authenticated} />} />
            <Route path="/home" element={<Home isAuthenticated={auth.authenticated} />} />
            <Route path="/trending" Component={Trending} />
            <Route path="/trending/:tag" Component={Trending} />
            <Route path="/article/:id" element={<Article />} />
            <Route path="/articles/:id" element={<Article />} />
            <Route path="/news/:id" element={<Article/>}/>
            <Route path="/login" element={<SignIn loginMode='login' />} />
            <Route path="/signup" element={<SignIn loginMode='sign-up'/>} />
            <Route path="/forgotpassword" Component={ForgotPassword}/>
            <Route path="/privacy-policy" Component={PrivacyPolicy} />
            <Route path="/privacy" Component={PrivacyPolicy} />
            <Route path="/terms" Component={Terms} />
            <Route path="/careers" Component={Careers} />
            <Route path="/about" Component={About} />
            <Route path="/help" Component={Help} />
            <Route path="/contact-us" Component={Help} />
            <Route path="/landing" Component={Landing} />
            <Route path="/thanks" Component={Thanks} />
            {auth.authenticated ? <>
              <Route path="/foryou" Component={ForYou} />
              <Route path="/local" Component={Local} />
              <Route path="/account" Component={Account}>
                <Route path="profile" Component={Profile} />
                <Route path="notifications" Component={AccountNotifications} />
                <Route path="saved" Component={SavedArticles} />
                <Route path="interests" Component={Interests} />
                <Route path="locations" Component={Locations} />
                <Route path="following" Component={Following} />
                <Route path="theme" Component={AccountTheme} />
                <Route path="billing" Component={Billing} />
              </Route>
            </> : <>
              <Route path={'/foryou'} element={loading ? <Loading/> : <Navigate to="/login?redirect=foryou" replace={true} />} />
              <Route path={'/local'} element={loading ? <Loading/> : <Navigate to="/login?redirect=local" replace={true} />} />
              <Route path="/account/*" element={loading ? <Loading/> : <Navigate to="/login?redirect=account" replace={true} />}/>
            </>}
            {SubscriberLanding()}
          </Routes>
        </AppContainer>
        <Notifications />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
      <Modal id={'mxm.modal'} />
      <PopupMounter/>
    </>
  );
};
