type Props = {
  title?: string;
  image_url?: string;
  description?: string;
};
const UpdateShareMeta = ({ title = 'MXM News', image_url, description = 'News Without Bias' }: Props) => {
  return null;
  const ogTitleTag: any = document.querySelector('meta[property="og:title"]');
  const ogDescriptionTag: any  = document.querySelector('meta[property="og:description"]');
  const ogImageTag: any  = document.querySelector('meta[property="og:image"]');
  const twitterCardTag: any  = document.querySelector('meta[name="twitter:card"]');
  const twitterTitleTag: any  = document.querySelector('meta[name="twitter:title"]');
  const twitterDescriptionTag: any  = document.querySelector('meta[name="twitter:description"]');
  const twitterImageTag: any  = document.querySelector('meta[name="twitter:image"]');

  if (ogTitleTag) {
    ogTitleTag.content = title;
  }
  if (ogDescriptionTag) {
    ogDescriptionTag.content = description;
  }

  if (twitterTitleTag) {
    twitterTitleTag.content = title;
  }
  if (twitterDescriptionTag) {
    twitterDescriptionTag.content = description;
  }
  if (image_url) {
    if (ogImageTag) {
      ogImageTag.content = image_url;
    }
    if (twitterImageTag) {
      twitterImageTag.content = ((image_url)?.includes("pbs.twimg.com") ? image_url + '?' + Math.floor(Math.random() * 10000) : image_url);
    }
  }
  if (twitterCardTag) {
    twitterCardTag.content = 'summary_large_image';
  }

  return(<></>);
};

UpdateShareMeta.displayName = 'UpdateShareMeta';

export default UpdateShareMeta;
