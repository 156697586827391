import { useNavigate } from 'react-router';
import { Button, ButtonSizes, ButtonVariants, Heading1, Heading2, Modal } from '@roc-digital/ui-web';
import GetAppButtonGroup from './GetAppButtonGroup';

interface Props {
  link: string;
  onContinue: () => void;
  onCancel: () => void;
}

const ConfirmNavigateAway = (props: Props) => {
  const url = new URL(props.link);

  return <div className={'flex w-full py-6 px-1 bg-[#24AAE1] items-center justify-between'}>
  <span className={'flex items-center flex-col w-full text-center max-w-2xl mx-auto mt-6 gap-4'}>
    <Heading1 className={'text-white !font-chivo !font-extrabold text-[32px] mb-4 sm:text-[48px]'}>
    You’re about to leave MxM News.
    </Heading1>
    <Heading2 className={'text-white !font-chivo !font-bold text-md'}>
    Download MxM News on your mobile phone and read all your news without leaving the app.
    </Heading2> 

    <span className={'flex flex-col gap-4 items-center w-full justify-center my-4'}>
      <GetAppButtonGroup/>
    </span>

    <hr className='border-white'/>

    <span className={'flex flex-col gap-4 items-center w-full justify-center'}>
      <Button
        title={'Continue to ' + url.host}
        size={ButtonSizes.medium}
        variant={ButtonVariants.secondaryPlain}
        customTextClassName={'text-[#1E222A]  !text-sm'}
        clickEvent={props.onContinue}
      />

      <Button
        title={'Cancel'}
        size={ButtonSizes.medium}
        customClassName={'bg-[#24AAE1] border-2 border-white mr-4'}
        customTextClassName={'text-white !text-sm'}
        clickEvent={props.onCancel}
      />
    </span>
  </span>
</div>
};

ConfirmNavigateAway.displayName = 'ConfirmNavigateAway';

export default ConfirmNavigateAway;
