import { useCallback, useEffect, useState } from 'react';
import { SSOStateSchema, Event } from '@roc-digital/mxm-base/types';

import { EventOptions } from '../types';
import { useCustomEvent } from './useCustomEvent';
import { SessionState } from '@roc-digital/mxm-base/state';

// Hook that allows reading the authentication token string.
export function useToken(): string | undefined {
  const [token, setToken] = useState<string | undefined>();

  const handleRefreshSuccessEvent = (evt: Event<SSOStateSchema>) => {
    setToken(evt.data.token);
  };

  useEffect(() => {
    SessionState.getToken().then(setToken);
  }, []);

  const eventOptions: EventOptions = { namespace: 'mxm.sso' };

  // Update the token when the token is refreshed
  useCustomEvent('refreshSuccess', handleRefreshSuccessEvent, eventOptions);

  // Clear the token on logout
  useCustomEvent(
    'logout',
    useCallback(() => setToken(undefined), []),
    eventOptions
  );

  return token;
}
