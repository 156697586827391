import { publish } from '@roc-digital/mxm-base/events';

import { ClickEvent, CustomEvent, PublisherData, getClassNames } from '@roc-digital/ui-lib';

import styles from './Icon.module.css';

export type IconProps = {
  id?: string;
  title?: string;
  src: string;
  circle?: boolean;
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large' | 'custom';
  invert?: boolean;
  highlight?: boolean;
  className?: string;
  circleColor?: string;
  fill?: string;
  stroke?: string;
  circleSize?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
  clickEvent?: ClickEvent | CustomEvent | (() => void);
};

const styleClasses = {
  base: `inline-block transition-all duration-200 ease-linear`,
  size: {
    ['x-small']: `${styles['x-small']} w-2.5 h-2.5`,
    small: `${styles['small']} w-3.5 h-3.5`,
    medium: `${styles['medium']} w-5 h-5`,
    large: `${styles['large']} w-8 h-8`,
    ['x-large']: `${styles['x-large']} w-12 h-12`,
    custom: ''
  },
  highlight: `brightness-200`,
  invert: `brightness-0`,
};

export const Icon = ({
  className = '',
  id,
  circle,
  circleColor = 'surface-high',
  circleSize = 'medium',
  size,
  invert,
  highlight,
  fill,
  stroke,
  clickEvent,
  src,
}: IconProps) => {
  const publishClick = (evt: React.MouseEvent<HTMLImageElement>) => {
    if (!clickEvent) {
      return;
    }

    if(typeof clickEvent === 'function') {
      return clickEvent();
    }

    if (clickEvent.allowDefault === false) {
      evt.preventDefault();
    }

    if (clickEvent.propagate === false) {
      evt.stopPropagation();
    }

    publish<PublisherData>(clickEvent.namespace, 'click', {
      id,
      eventData: clickEvent.data || {},
    });
  };

  const iconClassName = getClassNames(styleClasses, { size });

  className = `${iconClassName} ${className}`;

  if (invert) {
    className += ' invert';
  }

  if (highlight) {
    className += ' highlight';
  }

  if (clickEvent) {
    className += ' cursor-pointer';
  }

  function alterSVG(svgDataUrl = '', fill = '', stroke = ''): string {
    let svgString = decodeURIComponent(svgDataUrl.split(',')[1]);

    if (fill) {
      svgString = svgString.replace(/<svg[^>]*>/, (match) => match.replace(/fill='[^']*'/, `fill='${fill}'`));
    }
    if (stroke) {
      svgString = svgString.replace(/<svg[^>]*>/, (match) => match.replace(/stroke='[^']*'/, `stroke='${stroke}'`));
    }

    return 'data:image/svg+xml,' + encodeURIComponent(svgString);
  }

  if (circle) {
    return (
      <div className={`flex-shrink-0 ${circle ? `rounded-full bg-${circleColor}` : ''}`}>
        <img
          style={{ fill: 'red' }}
          alt={id}
          id={id}
          className={`${circleSize === 'small' ? 'm-3' : circleSize === 'x-small' ? 'm-2' : 'm-4'} ${className}`}
          onClick={publishClick}
          src={alterSVG(src, fill, stroke)}
        />
      </div>
    );
  }
  return <img alt={id} id={id} className={className} onClick={publishClick} src={alterSVG(src, fill, stroke)} />;
};

Icon.defaultProps = {
  size: 'medium',
};
