"use strict";
/* eslint-disable @typescript-eslint/no-explicit-any */
// @copyright 2023 MXM, Inc
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePassword = exports.validateEmail = exports.snakeToCamel = exports.debounce = exports.sortByKeys = exports.generateUuid = exports.generateHash = exports.hasOwnProperty = void 0;
var uuid_1 = require("uuid");
function hasOwnProperty(object, property) {
    return Object.prototype.hasOwnProperty.call(object, property);
}
exports.hasOwnProperty = hasOwnProperty;
function generateHash(prefix) {
    return (prefix || '') + Math.random().toString(16).split('.')[1].toUpperCase();
}
exports.generateHash = generateHash;
function generateUuid() {
    return (0, uuid_1.v4)();
}
exports.generateUuid = generateUuid;
function sortByKeys(object) {
    var sorted = {};
    if (object) {
        // eslint-disable-next-line no-nested-ternary
        var keys = Object.keys(object).sort(function (a, b) { return (a > b ? 1 : a < b ? -1 : 0); });
        keys.forEach(function (key) {
            sorted[key] = object[key];
        });
    }
    return sorted;
}
exports.sortByKeys = sortByKeys;
function debounce(callback, duration) {
    if (duration === void 0) { duration = 1000; }
    var timeout;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        clearTimeout(timeout);
        timeout = setTimeout(function () { return callback(args); }, duration);
    };
}
exports.debounce = debounce;
function snakeToCamel(str) {
    return str.replace(/([-_][a-z]|^.)/gi, function ($1) { return $1.toUpperCase().replace('-', '').replace('_', ''); });
}
exports.snakeToCamel = snakeToCamel;
function validateEmail(value) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
}
exports.validateEmail = validateEmail;
function validatePassword(value) {
    return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(value.toString());
}
exports.validatePassword = validatePassword;
