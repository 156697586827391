import { Link } from 'react-router-dom';
import { Button, Icon } from '@roc-digital/ui-web';
import { mxmIcon } from '@roc-digital/ui-lib';
import Footer from './Footer';
import GetAppButtonGroup from './GetAppButtonGroup';
import { useAuthState } from '@/logic';

const AppFooter = () => {
  const auth = useAuthState();

  return (
    <>
      <div className="visible-sm w-full bg-font dark:bg-black">
        <div className="text-center mt-8">
          <Link to="/">
            <Icon src={mxmIcon} size="large" className="mb-9 large-logo" />
          </Link>
        </div>
        <div className="text-center">
          {!auth.authenticated ? (
            <>
              <Link to="/login">
                <Button customClassName="!w-28 medium-height bg-transparent border border-white mx-2">Login</Button>
              </Link>
              <Link to="/signup">
                <Button customClassName="!w-28 medium-height mx-2">Sign Up</Button>
              </Link>
            </>
          ) : null}
        </div>
        <div>
          <GetAppButtonGroup customClassName={'w-full justify-center mt-8 mb-8'} />
        </div>
        <Footer dark />
      </div>
      <div className="flex justify-center w-full bg-font hidden-sm dark:bg-black dark:text-white">
        <div className="flex flex-col max-w-5xl w-full">
          <div className="flex justify-between py-24 sm:px-4 sm:flex-wrap md:px-2">
            <div>
              <Link to="/">
                <Icon src={mxmIcon} size="large" className="mb-9 large-logo" />
              </Link>
              <GetAppButtonGroup customClassName={'ml-1'} />
            </div>
            <div className="flex items-center gap-4 sm:pt-8 sm:mx-auto">
              {!auth.authenticated ? (
                <>
                  <Link to="/login">
                    <Button customClassName="!w-28 medium-height bg-transparent border border-white">Login</Button>
                  </Link>
                  <Link to="/signup">
                    <Button customClassName="!w-28 medium-height">Sign Up</Button>
                  </Link>
                </>
              ) : null}
            </div>
          </div>
          <Footer dark />
        </div>
      </div>
    </>
  );
};

AppFooter.displayName = 'AppFooter';

export default AppFooter;
