import { useState, useEffect } from 'react';
import { Loading, Toggle } from '@roc-digital/ui-web';
import { useClickEvent } from '@roc-digital/ui-lib';
import { SessionState } from '@roc-digital/mxm-base/state';
import { updateUser, readUser } from '@roc-digital/mxm-base/logic';
import { toast } from 'react-toastify';

const AccountTheme = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [deviceSetting, setDeviceSetting] = useState(false);
  const [loading, setLoading] = useState(true);

  const initialize = async () => {
    try {
      setLoading(true);
      const userData = await readUser();

      await SessionState.setUser(userData);

      if (userData) {
        if (!userData.theme) {
          setDarkMode(false);
          localStorage.theme = 'dark';
          document.documentElement.classList.add('dark');
          setDeviceSetting(true);
        } else if (userData.theme !== 'dark') {
          setDarkMode(false);
          document.documentElement.classList.remove('dark');
          localStorage.theme = 'light';
          setDeviceSetting(true);
        } else {
          setDarkMode(true);
          setDeviceSetting(false);
          document.documentElement.classList.add('dark');
          localStorage.theme = 'dark';
        }
      }
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch user: ');
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const clickEvent = useClickEvent(
    () => {
      console.log('Button clicked');
    },
    { namespace: 'mxm.toggle' }
  );

  const onChangeDeviceSetting = async (toggled: boolean) => {
    try {
      setLoading(true);
      setDeviceSetting(toggled);
      localStorage.theme = 'dark';
      document.documentElement.classList.add('dark');
      if (toggled) {
        setDarkMode(false);
        document.documentElement.classList.remove('dark');
        localStorage.theme = 'light';
      }
      const userData = await updateUser(SessionState, {
        theme: toggled ? null : (darkMode as boolean) ? 'dark' : 'light',
      });

      await SessionState.setUser(userData);
      setLoading(false);
      toast.success('Theme settings updated.');
    } catch (error) {
      console.error('Failed to update theme setting: ');
      console.error(error);
      setLoading(false);
    }
  };

  const onChangeDarkMode = async (toggled: boolean) => {
    try {
      setLoading(true);
      setDarkMode(toggled);
      localStorage.theme = 'light';
      document.documentElement.classList.remove('dark');
      if (toggled) {
        setDeviceSetting(false);
        document.documentElement.classList.add('dark');
        localStorage.theme = 'dark';
      }
      const userData = await updateUser(SessionState, { theme: (toggled as boolean) ? 'dark' : 'light' });

      await SessionState.setUser(userData);
      setLoading(false);
      toast.success('Theme settings updated.');
    } catch (error) {
      console.error('Failed to update theme setting: ');
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col max-w-2xl w-full text-sm dark:text-white">
      <div className="text-2xl font-bold mb-6">Theme</div>
      {loading && <Loading />}
      {!loading && (
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <div>
              <div className="font-bold">Dark Mode</div>
              <div className="text-font-low">Use the darker UI style.</div>
            </div>
            <Toggle onToggle={onChangeDarkMode} initialState={darkMode} clickEvent={clickEvent} />
          </div>

          <div className="flex items-center justify-between">
            <div>
              <div className="font-bold">Use Device Setting</div>
              <div className="text-font-low">Use your system’s UI setting.</div>
            </div>
            <Toggle onToggle={onChangeDeviceSetting} initialState={deviceSetting} clickEvent={clickEvent} />
          </div>
        </div>
      )}
    </div>
  );
};

AccountTheme.displayName = 'AccountTheme';

export default AccountTheme;
