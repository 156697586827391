import { useLocation } from 'react-router';
import { ContentLayout } from './ContentLayout';
import { LoginForm, LoginFormProps } from '@/components/elements';
import { Card } from './Card';
import { useNav } from './utils';
import { toast } from 'react-toastify';
import { useMethod } from '@roc-digital/ui-lib';
import { LoginAndCheckAccount } from '@/logic';
import { getProfile } from '@roc-digital/ui-lib';

export function Login() {
  const location = useLocation();
  const nav = useNav();
  const mode: LoginFormProps['mode'] = location.pathname.includes('login') ? 'login' : 'sign-up';

  const loginQuery = useMethod(LoginAndCheckAccount, {
    onError: (err) => {
      nav('/subscribe/payment', {new: 'yes'});
      toast.error(err.toString() || 'Incorrect username or password.');
    },
    onSuccess: (r) => {
      const profile = getProfile();
      if(profile?.subscribed) {
        nav('/subscribe/welcome-back');
      } else {
        nav('/subscribe/payment', {new: r.isNewAccount ? 'yes' : 'no'});
      }
    }
  });

  return <ContentLayout title='Subscribe NOW for one month FREE'
    description='Woke news organizations have had too much influence for too long. Join MxM and take back control of information in America.'
    subtext='Save 30% with a yearly subscription.'
  >
    <Card title={mode === 'login' ? 'Login' : 'Sign Up'}>
      <LoginForm
        mode={mode}
        loading={loginQuery.loading}
        onLogin={(options) => {
          loginQuery.run(options)
        }}
        hideHeader
        onSwitchToLogin={() => nav('/subscribe/login')}
        onSwitchToSignUp={() => nav('/subscribe/create-account')}
        disableGuestAccess
      />
    </Card>
  </ContentLayout>;
}