"use strict";
/* eslint-disable import/no-cycle */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Article = void 0;
// @copyright 2022 Navigo, Inc
var Entity_1 = require("./Entity");
var Tag_1 = require("./Tag");
var Tags_1 = require("./Tags");
var Article = /** @class */ (function (_super) {
    __extends(Article, _super);
    function Article() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Article.prototype.getTags = function () {
        return new Tags_1.Tags(this.tags || [], Tag_1.Tag);
    };
    Article.prototype.getPublicationImage = function () {
        var _a;
        return (((_a = this.getTags()
            .filter(function (tag) { return tag.isPublication(); })
            .first()) === null || _a === void 0 ? void 0 : _a.image_url) || '');
    };
    Article.prototype.isMxmExclusive = function () {
        return this.content_type === 'mxm_exclusive';
    };
    Article.prototype.getCategoryTags = function () {
        return this.getTags().filter(function (tag) { return tag.isCategory(); });
    };
    Article.prototype.getCuratorTags = function () {
        return this.getTags().filter(function (tag) { return tag.isCurator(); });
    };
    Article.prototype.getDate = function () {
        return new Date((this.date || 0) * 1000);
    };
    Article.prototype.getDay = function () {
        return this.getDate().getDate();
    };
    Article.prototype.getMonth = function () {
        return this.getDate().getMonth();
    };
    Article.prototype.isUpVoted = function () {
        return this.voted === 1;
    };
    Article.prototype.isDownVoted = function () {
        return this.voted === -1;
    };
    return Article;
}(Entity_1.Entity));
exports.Article = Article;
