import React from 'react';
import { useLocation } from 'react-router';

export function useKeepTop() {
  const state = React.useRef('');
  const location = useLocation();

  React.useLayoutEffect(() => {
    if(state.current && state.current !== location.pathname) {
      document.body.scrollTo(0,0);
    }

    state.current = location.pathname;
  }, [location]);
}