"use strict";
/* eslint-disable import/no-cycle */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deactivateAdminUser = exports.activateAdminUser = exports.createAdminUser = exports.deleteAdminUser = exports.updateAdminUser = exports.readAdminUser = exports.listAdminUsers = void 0;
// @copyright 2023 MXM, Inc
var config_1 = require("../config");
var data_1 = require("../data");
var http_1 = require("./http");
var json_fetch_1 = require("./json-fetch");
var SERVICE_NAME = 'adminUsers';
var JSON_PATH = '_';
function url() {
    return "".concat((0, config_1.getConfig)().mxmApiAdminUrl, "/users");
}
function resolveUser() {
    return (0, http_1.createEntity)(JSON_PATH, data_1.User);
}
function listAdminUsers(params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'listAdminUsers', (0, http_1.createHTTPRequest)('GET', "".concat(url()), params), (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Users, data_1.User), true);
}
exports.listAdminUsers = listAdminUsers;
function readAdminUser(id) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'readAdminUser', (0, http_1.createHTTPRequest)('GET', "".concat(url(), "/").concat(id)), resolveUser());
}
exports.readAdminUser = readAdminUser;
function updateAdminUser(id, params) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'updateAdminUser', (0, http_1.createHTTPRequest)('PATCH', "".concat(url(), "/").concat(id), params, undefined, { 'Content-Type': 'application/json' }), resolveUser())];
        });
    });
}
exports.updateAdminUser = updateAdminUser;
function deleteAdminUser(id, params) {
    return (0, json_fetch_1.JsonFetch)({ method: 'DELETE', url: "".concat(url(), "/").concat(id) });
}
exports.deleteAdminUser = deleteAdminUser;
function createAdminUser(params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'createAdminUser', (0, http_1.createHTTPRequest)('POST', url(), params, undefined, { 'Content-Type': 'application/json' }), resolveUser());
}
exports.createAdminUser = createAdminUser;
function activateAdminUser(id) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'activateAdminUser', (0, http_1.createHTTPRequest)('GET', "".concat(url(), "/").concat(id, "/activate")), resolveUser());
}
exports.activateAdminUser = activateAdminUser;
function deactivateAdminUser(id) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'deactivateAdminUser', (0, http_1.createHTTPRequest)('GET', "".concat(url(), "/").concat(id, "/deactivate")), resolveUser());
}
exports.deactivateAdminUser = deactivateAdminUser;
