import { LoginAndCheckAccount, LoginOptions } from '@/logic';
import { useMethod } from '@roc-digital/ui-lib';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

export interface HomeSignupProps {
  mode?: 'login';
  onLogin: (value: LoginOptions) => void;
  loading?: boolean;
}

const HomeSignup = (props: HomeSignupProps) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [zip, setZip] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [check, setCheck] = useState(false);
  const [name, setName] = useState('');

  const loginQuery = useMethod(LoginAndCheckAccount, {
    onError: (err) => {
      toast.error(err.toString() || 'Incorrect username or password.');
    }
  });

  const signup = () => {
    if (validateForm()) { 
      loginQuery.run({
        method: 'password-signup',
        email: email,
        password: password
      }).then(() => {
        navigate(`/signup?phone=${phone}`);
      });
    }
  };

  const validateForm = () => {
    let validated: boolean = true;
    if (!validateName(name)) { validated = false; toast.error('Please enter your full name.'); }
    if (!email || email === '' || !validateEmail(email)) { validated = false; toast.error('Please enter a valid email.'); }
    if (!validatePhone(phone)) { validated = false; toast.error('Please enter valid phone number. US numbers only.'); }
    if (!zip || zip === '') { validated = false; toast.error('Please enter a zip code.'); }
    if (!password || password.length < 6 ) { validated = false; toast.error('Please enter a password more than 6 characters long.'); }
    if (!confirmPassword) { validated = false; toast.error('Please enter a password confirmation.'); }
    if (confirmPassword !== password) { validated = false; toast.error('Your passwords do not match.'); }
    if (!check) { validated = false; toast.error('Please check the box to receive text messages.'); }
    return validated;
  }

  const validatePhone = (phone: string) => {
    if (phone && phone !== '' && (phone.length === 10 || phone.length === 11)) {
      return true;
    } else {
      return false;
    }
  }

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateName = (name: string) => {
    if (!name || name === '') { return false; }
    const nameParts = name.trim().split(/\s+/); // Split by one or more spaces
    if (nameParts.length >= 2) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className='w-full flex flex-col max-w-5xl mx-auto px-2 pt-4 pb-8'>
      <div className='text-3xl my-3'>Connect with MXM News on SMS!</div>
      <form className='p-4 bg-[#ccc] rounded-lg'>
        <div className='flex flex-nowrap sm:flex-wrap gap-2'>
          <input
            className='w-full px-3 py-2 rounded-md'
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Full Name"
          />
          <input
            className='w-full px-3 py-2 rounded-md'
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email Address"
          />
          <input
            className='w-full px-3 py-2 rounded-md'
            type="number"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            placeholder="Phone (US numbers only)"
          />
        </div>
        <div className='flex flex-nowrap sm:flex-wrap gap-2 mt-2'>
          <input
            className='w-full px-3 py-2 rounded-md'
            type="text"
            value={zip}
            onChange={e => setZip(e.target.value)}
            placeholder="Zip Code"
          />
          <input
            className='w-full px-3 py-2 rounded-md'
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
          />
          <input
            className='w-full px-3 py-2 rounded-md'
            type="password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            placeholder="Confirm Password"
          />
        </div>
        <div>
          <label className='flex mt-2'>
            <input checked={check} onChange={e => setCheck(e.target.checked)} type="checkbox" className='mr-2'/>
            <span className='text-black'>I agree to receive text messages.*</span>
          </label>
        </div>
        <div>
        <div className='mt-4 text-black'>By entering your phone number and selecting to opt in, you consent to join a recurring SMS/MMS text messaging program that will provide alerts, updates, and other important information. By participating, you agree to the terms &amp; privacy policy for auto dialed messages to the phone number you provide. Msg &amp; data rates may apply. SMS information is not rented, sold, or shared. apply. Text JOIN to 21939 to opt in. Reply HELP for help
        or STOP to opt-out at any time. SMS information is not rented, sold, or shared. View <a href="/privacy" target="_blank" className='underline'>Privacy Policy</a> and <a href="/terms" target="_blank" className='underline'>Terms &amp; Conditions</a>.</div>
        </div>
        <div className='text-center my-8'>
          <a onClick={signup} className="cursor-pointer text-white bg-surface-primary h-14 w-full max-w-80 rounded-full leading-5 relative items-center justify-center text-base mt-16 w-auto px-12 h-auto py-4">
            Sign Up! 
          </a>
        </div>
        <div className='text-center pt-4'>Already have an account?<br/><a className='font-semibold' href="/login">Login</a></div>
      </form>
    </div>
  );
};

export default HomeSignup;
