import { AUX_API } from "@/env";
import { JsonFetch } from "@roc-digital/mxm-base/logic";
import { EmailArticleOptions } from "@roc-digital/types";

export async function emailArticle(options: EmailArticleOptions) {
  JsonFetch({
    method: 'POST',
    url: AUX_API + '/articles/share-in-email',
    data: options
  })
}