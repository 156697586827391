import React, { memo } from 'react';
import { format } from 'date-fns';
import { getTrendingView } from '@roc-digital/mxm-base/logic';
import { Heading1 } from '@roc-digital/ui-web';
import { Loading } from '@/components/elements/Loading';
import { useMethod, useProfile } from '@roc-digital/ui-lib';
import ZonePreview from '@/components/views/Trending/ZonePreview';
import AppFooter from '@/components/elements/AppFooter';
import LoginSignup from '@/components/elements/LoginSignup';
import SubscribeBanner from '@/components/elements/SubscribeBanner';
import MobileSnackbar from '@/components/elements/MobileSnackbar';
import { useAuthState } from '@/logic';
import { ArticleListByTag } from '@/components/elements/ArticlesListByTag';

const Trending = memo(() => {
    const trendingQuery = useMethod(getTrendingView, {autoRun: [], ssrPreloadKey: 'trending_view'});
    const profile = useProfile();
    const auth = useAuthState();

    const showLoginBanner = !auth.authenticated;
    const showSubBanner = auth.authenticated && profile?.subscribed !== true;
    const activeView = trendingQuery?.result;

    const showMiddleBar1 = (index: number) => {
      return !!(index && index % 3 === 0);
    };

    const showMiddleBar2 = (index: number) => {
      return !!(index && index % 5 === 0);
    };

    const excludedArticles = React.useMemo(() => {
      const list: string[] = [];
      if(!trendingQuery.result?.zones?.length) return list;

      trendingQuery.result.zones.forEach(zone => {
        if(zone.articles) {
          zone.articles?.forEach(a => list.push(a.id))
        }
      })

      return list;
    }, [trendingQuery.result])

    return (
      <>
        <div className={'h-full w-full flex flex-col justify-center dark:bg-surface-dark flex-grow'}>
          <div className={'flex w-full flex-col justify-between items-start max-w-5xl mx-auto px-2 mt-4 mb-2'}>
            <Heading1 className={'text-[#1E222A] !font-extrabold !text-[32px] !leading-9  !tracking-tight !font-chivo dark:text-white'}>
              {format(new Date(), 'MMMM d')}
            </Heading1>
          </div>
          <div className={'flex justify-center w-full'}>
            <div className="flex-grow bg-white dark:bg-surface-dark overflow-hidden">
              {trendingQuery.loading && <Loading customClassName={'mb-8 ml-auto mr-auto'} />}
              {activeView?.zones
                ?.sort((a, b) => (a.order || 0) - (b.order || 0))
                ?.map((zone, index) => (
                  <div key={zone.id} className={`${zone.web_layout === 0 ? 'bg-[#24AAE1] pb-6' : 'zone-gradient pb-10'} dark:zone-gradient-dark pb-10`}>
                    {showMiddleBar1(index) && showLoginBanner && (
                      <LoginSignup />
                    )}
                    {showMiddleBar2(index) && showLoginBanner && (
                      <LoginSignup size="large" />
                    )}
                    {showMiddleBar1(index) && showSubBanner && (
                      <SubscribeBanner />
                    )}
                    {showMiddleBar2(index) && showSubBanner && (
                      <SubscribeBanner size="large" />
                    )}
                    {zone.hide_title !== true ? <>
                      <Heading1 className="w-full flex flex-col mt-6 mb-4 max-w-5xl mx-auto px-2 text-action !font-extrabold !text-[32px] !leading-9 !tracking-tight !font-chivo">{zone.title}</Heading1>
                    </> : null}
                    <ZonePreview
                      preview={true}
                      key={index}
                      view={activeView}
                      zone={zone}
                      hideTags={true}
                      hideMeta={true}
                    />
                  </div>
                ))}
                {trendingQuery.result ? <>
                  <IcumiList excludedArticles={excludedArticles}/>
                </> : <></>}
            </div>
          </div>
        </div>
        <AppFooter />
        <MobileSnackbar />
      </>
    );
  }
);

Trending.displayName = 'Trending';

export default Trending;

function IcumiList(props: {excludedArticles: string[]}) {
  const [render, setRender] = React.useState(false);
  const ref = React.useMemo(() => {
    return (element: HTMLDivElement) => {
      if(!element) return;
      const io = new IntersectionObserver(([entry]) => {
        if(entry.isIntersecting) {
          setRender(true);
        }
      });

      io.observe(element);
    };
  }, []);

  return <>
    <div
      ref={ref}
      className="w-full flex flex-col max-w-5xl mx-auto px-2 pt-4 pb-8">
      {render ? <>
        <Heading1 className="w-full flex flex-col mt-6 mb-4 max-w-5xl mx-auto px-2 text-action !font-extrabold !text-[32px] !leading-9 !tracking-tight !font-chivo">
          In Case You Missed It
        </Heading1>
        <ArticleListByTag
          tags={['publication:mxm-news']}
          count={16}
          excludedArticles={props.excludedArticles}
        />
      </> : <></>}
    </div>
  </>
}
