import { decode } from 'blurhash';
import React from 'react';

export interface BlurhashProps {
  hash: string;
  height: number;
}

export function Blurhash(props: BlurhashProps) {
  const ref = React.useRef<any>();
  const canvas = React.useMemo(() => {
    if(!props.hash) return null;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d', {willReadFrequently: true});
    canvas.width = 32;
    canvas.height = 32;
    canvas.style.height = props.height + 'px';
    canvas.style.width = '100%';
    if(!ctx) return;
    const pixels = decode(props.hash, 32, 32);
    const imageData = new ImageData(pixels, 32, 32);
    ctx.putImageData(imageData, 0, 0);

    return canvas;
  }, [props.hash]);

  React.useLayoutEffect(() => {
    if(!ref.current || !canvas) return;

    ref.current.appendChild(canvas);
  }, [ref.current, canvas])

  return <div ref={ref}></div>
}