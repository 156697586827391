import { ArticleViewProps } from '@roc-digital/ui-lib';
import { JumbotronHeadline } from './JumbotronHeadline';
import { Landscape } from './Landscape';
import { Portrait } from './Portrait';
import { Mini } from './Mini';

export function ArticleView(props: ArticleViewProps) {

  const display = props.orientation || 'portrait';

  if(!props.article) {
    return <></>;
  }

  if(display === 'title-block' || display === 'jumbo'){
    return <JumbotronHeadline {...props}/>
  } else if(display === 'landscape') {
    return <Landscape {...props}/>
  } else if(display === 'portrait') {
    return <Portrait {...props}/>
  } else if(display === 'mini') {
    return <Mini {...props}/>
  } else {
    return <Mini {...props}/>
  }
}
