import { useState, useEffect, useCallback } from 'react';
import { Curator, Curators } from '@roc-digital/mxm-base/data';
import { Event } from '@roc-digital/mxm-base/types';
import { Avatar, Badge, Button, ButtonVariants, FollowItem, Icon, InputSearch } from '@roc-digital/ui-web';
import {
  useCustomEvent,
  PublisherData,
  useClickEvent,
  useStateEvent,
  useChangeEvent,
  InputData,
  closeIcon,
  followIcon,
} from '@roc-digital/ui-lib';
import {
  followCurator,
  listCuratorsWithQuery,
  myCurators,
  unfollowCurator,
  debounce,
} from '@roc-digital/mxm-base/logic';
import { CacheState } from '@roc-digital/mxm-base/state';
import { Loading } from '@/components/elements/Loading';

interface FollowingProps {
  categorized?: boolean;
}

const Following = ({ categorized }: FollowingProps) => {
  const [curators, setCurators] = useState<Curators | undefined>(undefined);
  const [clearSearch] = useStateEvent('mxm.search', 'clear', '');
  const [userCurators, setUserCurators] = useState<Curators | undefined>(undefined);
  const [followed, setFollowed] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [cancelled, setCancelled] = useState<boolean>(false);
  const [curatorLoading, setCuratorLoading] = useState<{ [id: string]: boolean }>({});
  const [selectPeople, setSelectedPeople] = useState<Curator | null>(null);

  const clickCloseFollow = useClickEvent(() => {
    setSelectedPeople(null);
  });

  useEffect(() => {
    setLoading(true);
    // myCurators();

    if (query) {
      setLoading(true);
    }
  }, []);

  useCustomEvent(
    'listCuratorsWithQuery.success',
    ({ data }: Event<Curators>) => {
      setLoading(false);
      if (!cancelled) {
        setCurators(data);
      }
    },
    { namespace: 'mxm.http.curators' },
    [cancelled]
  );

  useCustomEvent(
    'listCuratorsWithQuery.failed',
    () => {
      setLoading(false);
      setCurators(undefined);
    },
    { namespace: 'mxm.http.curators' }
  );

  useCustomEvent(
    'myCurators.success',
    ({ data }: Event<Curators>) => {
      setLoading(false);
      setUserCurators(data);
      setFollowed(data.map((curator: Curator) => curator.id || ''));
    },
    { namespace: 'mxm.http.curators' }
  );

  useCustomEvent(
    'myCurators.failed',
    () => {
      setLoading(false);
    },
    { namespace: 'mxm.http.curators' }
  );

  useCustomEvent(
    'followCurator.success',
    ({ data }: Event<Curator>) => {
      setCuratorLoading({ ...curatorLoading, [data.id as string]: false });
      setFollowed([...followed, data.id || '']);
    },
    { namespace: 'mxm.http.curators' },
    {},
    [followed, curatorLoading]
  );

  useCustomEvent(
    'followCurator.failed',
    () => {
      setCuratorLoading({});
    },
    { namespace: 'mxm.http.curators' }
  );

  useCustomEvent(
    'unfollowCurator.success',
    ({ data }: Event<Curator>) => {
      setCuratorLoading({ ...curatorLoading, [data.id as string]: false });
      setFollowed(followed.filter((item) => item !== data.id));
    },
    { namespace: 'mxm.http.curators' },
    {},
    [followed, curatorLoading]
  );

  useCustomEvent(
    'unfollowCurator.failed',
    () => {
      setCuratorLoading({});
    },
    { namespace: 'mxm.http.curators' }
  );

  const followEvent = useClickEvent(
    ({ data }: Event<PublisherData>) => {
      const { id } = data;

      setCuratorLoading({ ...curatorLoading, [id as string]: true });
      if (followed.includes(id as string)) {
        unfollowCurator(CacheState, id as string);
      } else {
        followCurator(CacheState, id as string);
      }
    },
    {},
    {},
    [followed]
  );

  const debounceSearch = useCallback(
    debounce(([value]) => {
      listCuratorsWithQuery(value as string);
    }),
    []
  );

  useEffect(() => {
    myCurators();
    setQuery('');
    setLoading(false);
    setCancelled(true);
  }, [clearSearch]);

  const searchChangeEvent = useChangeEvent(({ data }: Event<InputData>) => {
    setLoading(true);
    setCancelled(false);
    setQuery(data.value as string);
    debounceSearch(data.value);
  });

  const hasCurator = (curatorId: string, curators: Curators) => curators?.some((curator) => curator.id === curatorId);

  const combinedCurators =
    userCurators && curators
      ? [
          ...userCurators.toArray(),
          ...curators.toArray().filter((curator) => !hasCurator(curator.id || '', userCurators)),
        ]
      : [];

  const followedCurators = combinedCurators.filter((cc) => followed.includes(cc.id as string));
  const unfollowedCurators = combinedCurators.filter((cc) => !followed.includes(cc.id as string));

  return (
    <div className='flex flex-col gap-2'>
      <div className="text-xl font-bold mb-4 font-chivo dark:text-white">Follow your favorite people!</div>
      <div className="border border-surface-high dark:border-none">
        <InputSearch hideResults value={query} changeValueEvent={searchChangeEvent} placeholder="Search..." />
      </div>
      {loading && <Loading customClassName={'mr-auto ml-auto'} />}
      {categorized && (
        <>
          <div className="text-xl font-bold">Following</div>
          {loading && <Loading customClassName={'mr-auto ml-auto'} />}
          {followedCurators?.length === 0 && !loading && (
            <div className="mb-6 mt-6">You have not followed any curators</div>
          )}
          {followedCurators?.map((user) => (
            <div key={user.id} onClick={() => setSelectedPeople(user)}>
              <FollowItem
                id={user.id || ''}
                name={user?.name || user?.username || 'Unknown'}
                avatar={user.avatar_url}
                followed={followed.includes(user.id as string)}
                followEvent={followEvent}
                loading={curatorLoading[user.id as string] || false}
                className="font-bold dark:bg-transparent"
              />
            </div>
          ))}
        </>
      )}
      {categorized && (
        <>
          <div className="text-xl font-bold mb-2 mt-4">Find New</div>
          {loading && <Loading customClassName={'mr-auto ml-auto'} />}
          {unfollowedCurators?.length === 0 && !loading && (
            <div className="mb-6 mt-6">You are following everyone already!</div>
          )}
          {unfollowedCurators?.map((user) => (
            <div key={user.id} onClick={() => setSelectedPeople(user)}>
              <FollowItem
                id={user.id || ''}
                name={user?.name || user?.username || 'Unknown'}
                avatar={user.avatar_url}
                followed={followed.includes(user.id as string)}
                followEvent={followEvent}
                loading={curatorLoading[user.id as string] || false}
                className="font-bold dark:bg-transparent"
              />
            </div>
          ))}
        </>
      )}
      {!categorized && (
        <>
          {combinedCurators?.map((user) => (
            <div key={user.id} onClick={() => setSelectedPeople(user)}>
              <FollowItem
                id={user.id || ''}
                name={user?.name || user?.username || 'Unknown'}
                avatar={user.avatar_url}
                followed={followed.includes(user.id as string)}
                followEvent={followEvent}
                loading={curatorLoading[user.id as string] || false}
                className="font-bold"
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

Following.displayName = 'Following';

export default Following;
