import { memo, useEffect } from 'react';

const Thanks = memo(() => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="h-full flex flex-col justify-center bg-gray-100 font-chivo overflow-x-hidden text-white">
        <div
          className="relative flex flex-col items-center justify-center pt-0 pb-10 bg-cover bg-center h-screen"
          style={{ backgroundImage: 'url(\'/landingbackground.jpg\')' }}
        > 
          <div className="flex justify-center py-6">
            <img
              alt={'logo'}
              src={'/icons/logo.svg'}
              className={'cursor-pointer h-10 w-10 mx-0 sm:mx-2 inline'}
            />
            <span className="font-chivo pl-2 text-4xl font-bold">MxM News</span>
          </div>
          <div className="hidden-xs text-8xl font-bold text-center text-font-high text-white">
              Thank you!
          </div>
          <div className="visible-xs text-6xl font-bold text-center text-font-high text-white">
              Thank you!
          </div>
          <div className='text-mx mt-6 pb-28 text-center'>By signing up early, you saved on MXM news and have joined our exclusive early access list. <br/>You will receive an email in the next couple of weeks as soon as your account is activated.</div>
        </div>
      </div>


      <div className="w-full bg-font dark:bg-black">
        <div className="max-w-[860px] mx-auto pt-12 pb-12">
          <div className='inline-grid grid-cols-2 w-full'>
            <div className='w-full'>
              <img
                  alt={'logo'}
                  src={'/icons/logo.svg'}
                  className={'cursor-pointer h-10 w-10 mx-0 sm:mx-2 inline'}
                />
            </div>
            <div className='w-full text-right'>
            </div>
          </div>
        </div>
        
        <hr className='text-white'/>
        <div className='py-8 text-center font-md text-white'>
          <div className='inline px-4'><a href="https://mxmnews.com/privacy" target='_BLANK'>Privacy Policy</a></div>
          <div className='inline px-4'><a href="https://mxmnews.com/terms" target='_BLANK'>Terms and Conditions</a></div>
          <div className='inline px-4'>&copy; 2023 MxM News, All Rights Reserved.</div>
        </div>
      </div>
    </>
    );
});

Thanks.displayName = 'Landing';

export default Thanks;
