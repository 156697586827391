import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMethod } from '@roc-digital/ui-lib';
import { LoginAndCheckAccount } from '@/logic';
import { LandingPageView } from '@/components/elements';
import { useAuthState } from '@/logic';
import { ForgotPasswordForm } from '@/components/elements/ForgotPasswordForm';

export function ForgotPassword() {
  const auth = useAuthState();

  const resetQuery = useMethod(LoginAndCheckAccount, {
    onError: (err) => {
      toast.error(err.toString() || 'Incorrect username or password.');
    }
  });

  let content = <></>;

  if(auth.loading || auth.authenticating) {
    content = <>
      <div style={{width: '320px', height: '500px'}}></div>
    </>;
  } else if(!auth.authenticated || auth.isAnonymous) {
      content = <ForgotPasswordForm
        loading={resetQuery.loading}      
        onChangePassword={resetQuery.run}
      />
    }

  return <LandingPageView>
    {content}
  </LandingPageView>;
}
