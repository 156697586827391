import React, { useState } from 'react';
import { publish } from '@roc-digital/mxm-base/events';
import { ClickEvent, CustomEvent, PublisherData } from '@roc-digital/ui-lib';
import { BodyDark } from './Typefaces';

export interface ToggleProps {
  className?: string;
  onToggle: (toggled: boolean) => void;
  label?: string;
  initialState?: boolean;
  clickEvent?: ClickEvent | CustomEvent;
  disabled?: boolean;
  id?: string;
  isLabelLeft?: boolean;
  trackOff?: string;
  trackOn?: string;
  thumbOff?: string;
  thumbOn?: string;
}

export const Toggle: React.FC<ToggleProps> = ({
  trackOff = 'bg-badge',
  trackOn = 'bg-surface-primary',
  thumbOff = 'bg-surface-secondary',
  thumbOn = 'bg-white',
  className,
  isLabelLeft,
  onToggle,
  label,
  initialState = false,
  clickEvent,
  disabled,
  id,
}) => {
  const [isToggled, setIsToggled] = useState(initialState);

  const handleToggle = () => {
    if (!clickEvent || disabled) {
      return;
    }
    setIsToggled(!isToggled);
    onToggle(!isToggled);

    publish<PublisherData>(clickEvent.namespace, 'click', {
      id,
      eventData: clickEvent.data || {},
    });
  };

  return (
    <div className={`flex items-center ${disabled && 'opacity-60'} ${className}`}>
      {!isLabelLeft ? <BodyDark className="mr-2 text-sm text-black">{label}</BodyDark> : null}
      <button
        className={`w-12 h-7 flex items-center rounded-full p-1 transition-colors duration-300 ${
          isToggled ? trackOn : trackOff
        }`}
        onClick={handleToggle}
      >
        <span
          className={`w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 ${
            isToggled ? `translate-x-5 ${thumbOn}` : `translate-x-0 ${thumbOff}`
          }`}
        ></span>
      </button>
      {isLabelLeft ? <BodyDark className="ml-2 text-sm text-black">{label}</BodyDark> : null}
    </div>
  );
};

export default Toggle;
