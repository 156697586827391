import { getMessaging, getToken, onMessage } from "@firebase/messaging";
import { getFirebaseAuth, firebaseConfig, events, getState } from './firebase';
import { updateUserNotificationToken } from "@roc-digital/mxm-base/logic";

const messaging = getMessaging();
const key = firebaseConfig.vapidKey;

export function initNotifications() {
  const update = () => {
    const state = getState();
    if(state.authenticated) {
      checkNotificationsStatus().catch(e => console.error(e))
    }
  }

  update();
  events.addEventListener('next', update);
}

onMessage(messaging, (message) => {
  const notification = new Notification(message.notification?.title || '', {
    body: message.notification?.body || '',
    image: message.data?.image_url,
    icon: message.data?.image_url,
  });

  notification.onclick = () => {
    if(message.data?._type === 'article') {
      window.location = `/article/${message.data.slug || message.data.id}` as any;
    }
  }
});

export async function getPushToken() {
  const permissions =  Notification.permission;
  if(permissions !== 'denied') {
    return await getToken(messaging, {vapidKey: key});
  }

  return '';
}

export async function sendToken(token: string) {
  await updateUserNotificationToken(token);
}

let tryCount = 0;
export async function checkNotificationsStatus() {
  try {
    const token = await getPushToken();
    if(!token) {
      const permission = await Notification.requestPermission();

      if(permission !== "denied" && tryCount < 3) {
        tryCount++
        await checkNotificationsStatus();
      }

      return;
    }

    await sendToken(token);

  } catch(err) {
    console.error(err);
  }
}

// function safeJsonParse<T>(json: string, defaultValue: T): T {
//   try {
//     if(!json) return defaultValue;
//     const value = JSON.parse(json);
//     if(value === null || value == undefined) {
//       return defaultValue;
//     }

//     return value;

//   } catch(err) {
//     console.error(err);
//     return defaultValue;
//   }
// }