import { MyTagsSelector } from './MyTagsSelector';
import React from 'react';
import {Input, Select } from '@roc-digital/ui-web';
import { Event } from '@roc-digital/mxm-base/dist/types';
import usStates from '../../resources/states.json';
import { TagSchema } from '@roc-digital/mxm-base/src/data';

const states = [
  { "label": "Any State", "value": "" },
  ...usStates
]

export function UserLocationForm(props: {title?: string}) {
  const [search, setSearch] = React.useState('');
  const [state, setState] = React.useState('');

  const filter = React.useCallback((tag: TagSchema) => {

    if(state && !tag.tag?.toLowerCase()?.endsWith(`-${state.toLowerCase()}`)) {
      return false;
    }

    const s = (search?.trim?.() || ''.toLocaleLowerCase());

    if(s && !tag.name?.toLocaleLowerCase()?.includes(s)) {
      return false;
    }

    return true;

  }, [search, state]);

  return <div className='flex flex-col flex-1 max-h-[100%]'>
    <div className="text-xl font-bold mb-2 font-chivo dark:text-white">{props.title ? props.title : 'Get Localized News Coverage'}</div>
    <div className="text-base text-font-low font-worksans">
      Add location information to get content specific to your area.
    </div>
    <div className="flex items-center gap-2 mt-8 mb-3">
      <Select
        size="x-small"
        options={states}
        value={state}
        changeEvent={setState}
        className="flex items-center text-xl dark:bg-gray-100"
      />
      <Input
        value={search}
        changeEvent={setSearch}
        size='x-small'
        type="text"
        placeholder="Search"
        inputContainerClassName='flex-grow'
        className="w-28 px-3 py-4 border flex-1 flex-grow border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm pr-12 font-worksans !text-base"
      />
    </div>
    <MyTagsSelector type='location' filter={filter}/>
  </div>
}