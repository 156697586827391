import { useState } from 'react';
import { publish } from '@roc-digital/mxm-base/events';
import { Pagination as Paginator } from '@roc-digital/mxm-base/data';
import { t } from '@roc-digital/mxm-base/logic';

import {
  PaginateEvent,
  CustomEvent,
  PaginationData,
  getClassNames,
  paginationChevronDown,
  paginationChevronRight,
  paginationChevronLeft,
} from '@roc-digital/ui-lib';
type Props = {
  id?: string;
  className?: string;
  paginator: Paginator;
  paginateEvent: PaginateEvent | CustomEvent;
};

const paginationClasses = 'w-full';
const buttonsClasses = 'flex items-stretch h-9';
const pageButtonClasses = `border-edge flex items-center border-1 border-solid rounded cursor-default hover:border-edge-high`;

const prevButtonClasses = {
  base: `${pageButtonClasses} w-9 h-9 justify-center`,
  enabled: `border-edge bg-none cursor-pointer hover:border-edge-high`,
};
const prevImgButtonClasses = `w-auto`;

const nextButtonClasses = {
  base: `${pageButtonClasses} w-9 h-9 justify-center`,
  enabled: `border-edge bg-none cursor-pointer hover:border-edge-high`,
};
const nextImgButtonClasses = `w-auto`;

const currentButtonClasses = {
  base: `${pageButtonClasses} mr-2 ml-3 border-solid border-2 border-inherit rounded p-4`,
};
const currentTextButtonClasses = `text-sm pr-1 text-font-low `;
const currentImgButtonClasses = `w-auto mr-2 ml-3`;
const pagesClasses = `bg-surface border-edge z-50 absolute w-20 left-11 bottom-full mb-4 overflow-y-auto border-1 border-solid rounded focus:outline-none`;
const pageItemClasses = `border-b-edge block w-full px-0 py-[6px] border-b-1 border-solid text-center text-sm cursor-pointer transition-all duration-100 ease-linear h-9 hover:bg-edge-high text-font`;
const statsClasses = `text-font-low float-right pt-2 text-sm`;

export const Pagination = ({ id, className, paginator, paginateEvent }: Props) => {
  const [pageListVisible, setPageListVisible] = useState(false);

  const togglePageListVisibility = () => {
    if (paginator.pages < 2) {
      return;
    }

    setPageListVisible(!pageListVisible);
  };

  const publishPaginatePrev = () => {
    if (paginator.page === 1) {
      return;
    }

    publish<PaginationData>(paginateEvent.namespace, 'paginate', {
      id,
      eventData: paginateEvent.data || {},
      page: paginator.prev(),
      count: paginator.count,
    });
  };

  const publishPaginate = (page: number) => {
    setPageListVisible(false);

    publish<PaginationData>(paginateEvent.namespace, 'paginate', {
      id,
      eventData: paginateEvent.data || {},
      page: page,
      count: paginator.count,
    });
  };

  const publishPaginateNext = () => {
    if (paginator.page === paginator.pages) {
      return;
    }

    publish<PaginationData>(paginateEvent.namespace, 'paginate', {
      id,
      eventData: paginateEvent.data || {},
      page: paginator.next(),
      count: paginator.count,
    });
  };

  const { page, pages } = paginator;

  const prevClassName = getClassNames(prevButtonClasses, { enabled: page > 1 });
  const currClassName = getClassNames(currentButtonClasses, { enabled: pages > 1 });
  const nextClassName = getClassNames(nextButtonClasses, { enabled: pages > page });

  const pageListClassName = pageListVisible ? pagesClasses : `${pagesClasses} hidden`;

  className = className ? `${paginationClasses} ${className}` : paginationClasses;

  return (
    <div className={className}>
      <div className="flex justify-between">
        <span className={buttonsClasses}>
          <span className={currClassName} onClick={togglePageListVisibility}>
            <span className={currentTextButtonClasses}>{t('page', { page: paginator.page })}</span>
            <img className={currentImgButtonClasses} src={paginationChevronDown} />
          </span>
        </span>
        <span className={'flex flex-row'}>
          <span className={prevClassName} onClick={publishPaginatePrev}>
            <img className={prevImgButtonClasses} src={paginationChevronLeft} title="Previous page" />
          </span>
          <div className={statsClasses}>
            {t('viewingPage', {
              current: paginator.page,
              start: (paginator.page - 1) * paginator.count + 1,
              end: Math.min(paginator.page * paginator.count, paginator.total),
              total: paginator.total,
            })}
          </div>
          <span className={nextClassName} onClick={publishPaginateNext}>
            <img className={nextImgButtonClasses} src={paginationChevronRight} title="Next page" />
          </span>
        </span>

        <ul className={pageListClassName}>
          {paginator.range().map((page: number) => (
            <li className={pageItemClasses} key={page} onClick={() => publishPaginate(page)}>
              {page}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
