import React from 'react';
import { publish } from '@roc-digital/mxm-base/events';

import { ChangeEvent, CustomEvent, InputData, getClassNames } from '@roc-digital/ui-lib';
const defaultProps = {
  width: 'full',
};

type Props = {
  id?: string;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  width?: 'full' | 'half' | 'third' | 'quarter';
  size?: 'x-small' | 'small' | 'medium' | 'large';
  value?: string | number;
  changeEvent?: ChangeEvent | CustomEvent | ((value: string) => void);
  options: Option[];
} & typeof defaultProps;

export interface Option {
  value: string;
  label: string;
}

const selectClasses = {
  base: `outline-none text-font focus:border-font-info-high focus:outline-none  border-1 border-stone-200 font-workSans text-admin-dark font-medium`,
  width: {
    full: `w-full`,
    half: `w-1/2`,
    third: `w-1/3`,
    quarter: `w-1/4`,
  },
  size: {
    ['x-small']: `px-1 text-sm`,
    small: `pr-3 text-sm`,
    medium: `pr-4`,
    large: `pr-6`,
  },
};

const containerSelectClasses = {
  base: `border-edge flex-none grow-0 inline-block self-stretch box-border mx-0  border border-stone-200 border-1 border-solid rounded outline-none text-font focus:border-font-info-high focus:outline-none`,

  size: {
    ['x-small']: `px-1 py-[3px]`,
    small: `px-3 py-[6px]`,
    medium: `px-4 py-3`,
    large: `px-6 py-3`,
  },
};

export const Select = ({ options, id, className, value, width, size = 'medium', changeEvent }: Props) => {
  const publishChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    if (!changeEvent) {
      return;
    }

    if(typeof changeEvent === 'function') {
      return changeEvent(evt.target.value)
    }

    if (changeEvent.allowDefault === false) {
      evt.preventDefault();
    }

    if (changeEvent.propagate === false) {
      evt.stopPropagation();
    }

    publish<InputData>(changeEvent.namespace, 'change', {
      id,
      eventData: changeEvent.data || {},
      value: evt.target.value,
    });
  };

  const containerSelectClassName = getClassNames(containerSelectClasses, { size });
  const selectClassName = getClassNames(selectClasses, { width, size });

  className = `${containerSelectClassName} ${className}`;

  return (
    <div className={className}>
      <select value={value} className={selectClassName} onChange={publishChange} style={{backgroundColor: 'inherit'}}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

Select.defaultProps = defaultProps;
