import { useEffect, useState } from 'react';
import { Loading, Toggle } from '@roc-digital/ui-web';
import { useClickEvent } from '@roc-digital/ui-lib';
import { SessionState } from '@roc-digital/mxm-base/state';
import { User } from '@roc-digital/mxm-base/data';
import { readUser, updateUser } from '@roc-digital/mxm-base/logic';
import { toast } from 'react-toastify';

interface ExtUser extends User {
  notification_breaking?: boolean;
  notification_am_pm?: boolean;
}

const Notifications = () => {
  const [breakingNews, setBreakingNews] = useState(false);
  const [amPm, setAmPm] = useState(false);
  const [loading, setLoading] = useState(true);

  const initialize = async () => {
    try {
      setLoading(true);
      const userData = await readUser();

      await SessionState.setUser(userData);
      setLoading(false);
    } catch (error) {
      console.error('Failed to read user: ');
      console.error(error);
      setLoading(false);
    }
    const userData: ExtUser = (await SessionState.getUser()) as ExtUser;

    if (userData) {
      setBreakingNews(userData.notification_breaking || false);
      setAmPm(userData.notification_am_pm || false);
    }

    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  const clickEvent = useClickEvent(
    () => {
      console.log('Button clicked');
    },
    { namespace: 'mxm.toggle' }
  );

  const onChangeBreakingNews = async (toggle: boolean) => {
    try {
      setLoading(true);
      setBreakingNews(toggle);
      const userData = await updateUser(SessionState, { notification_breaking: toggle });

      SessionState.setUser(userData);
      setLoading(false);
      toast.success('Notification settings updated.');
    } catch (error) {
      console.error('Failed to update user: ');
      console.error(error);
      setLoading(true);
    }
  };

  const onChangeAmPm = async (toggle: boolean) => {
    try {
      setLoading(true);
      setAmPm(toggle);
      const userData = await updateUser(SessionState, { notification_am_pm: toggle });

      SessionState.setUser(userData);
      setLoading(false);
      toast.success('Notification settings updated.');
    } catch (error) {
      console.error('Failed to update user: ');
      console.error(error);
      setLoading(true);
    }
  };

  return (
    <div className="flex flex-col max-w-2xl w-full text-sm dark:text-white">
      <div className="text-2xl font-bold mb-6">Manage Notifications</div>
      <div className="max-w-md text-font-low mb-3">
        Never miss a big story. Get updates from our curators about the news that matters to you.
      </div>
      {loading && <Loading />}
      {!loading && (
        <div className="flex flex-col gap-4 pr-6">
          <div className="flex items-center justify-between">
            <div>
              <div className="font-bold">Breaking News</div>
              <div className="text-font-low">Urgent and important stories</div>
            </div>
            <Toggle onToggle={onChangeBreakingNews} initialState={breakingNews} clickEvent={clickEvent} />
          </div>

          <div className="flex items-center justify-between">
            <div>
              <div className="font-bold">AM/PM Update</div>
              <div className="text-font-low">Get a morning and evening news update</div>
            </div>
            <Toggle onToggle={onChangeAmPm} initialState={amPm} clickEvent={clickEvent} />
          </div>
        </div>
      )}
    </div>
  );
};

Notifications.displayName = 'Notifications';

export default Notifications;
