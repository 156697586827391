import { publish } from '@roc-digital/mxm-base/events';
import { Person } from '@roc-digital/mxm-base/data';

import {
  ClickEvent,
  CustomEvent,
  PublisherData,
  getClassNames,
  useToken,
  accountFillBrandIcon,
} from '@roc-digital/ui-lib';

import { AuthImage } from './AuthImage';
import styles from './Avatar.module.css';
import { Icon } from './Icon';

// Default property values must be defined first to avoid issues with typescript
const defaultProps = {
  image: '',
  size: 'medium',
  status: 'offline',
  notifications: 0,
};

type Props = {
  id?: string;
  className?: string;
  clickEvent?: ClickEvent | CustomEvent;
  image?: string;
  size?: 'x-small' | 'small' | 'medium' | 'big' | 'large';
  status?: 'online' | 'offline';
  notifications?: number;
  person?: Person;
} & typeof defaultProps;

const avatarClasses = {
  base: `inline-block relative rounded`,
  status: {
    offline: `bg-domain-status-offline`,
    online: `bg-domain-status-online`,
  },
  size: {
    ['x-small']: `w-5 h-5`,
    small: `w-5 h-5`,
    medium: `w-7 h-7`,
    big: `w-9 h-9`,
    large: `w-20 h-20`,
  },
};
const notificationsClasses = `${styles.notifications} border-edge-low bg-action-critical flex flex-row px-0 py-0.5 absolute -right-3 -top-1 border-2 border-solid rounded-lg text-xs box-content justify-center`;

function getVersionFromAvatarSize(
  size: 'x-small' | 'small' | 'medium' | 'big' | 'large'
): 'large' | 'medium' | 'small' {
  switch (size) {
    case 'x-small':
    case 'small':
      return 'small';
    case 'medium':
    case 'big':
      return 'medium';
    case 'large':
      return 'large';
  }
}

export const Avatar = ({ id, clickEvent, size, image, notifications, person, className, status }: Props) => {
  const token = useToken();
  const presence = (person?.getPresence() as 'offline' | 'online') || status;
  const publishClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    if (!clickEvent) {
      return;
    }
    if (clickEvent.allowDefault === false) {
      evt.preventDefault();
    }
    if (clickEvent.propagate === false) {
      evt.stopPropagation();
    }
    publish<PublisherData>(clickEvent.namespace, 'click', {
      id,
      eventData: clickEvent.data || {},
      value: '',
    });
  };

  const imageSrc = person?.getImage() || image;

  const avatarClassName = getClassNames(avatarClasses, { size, status: presence });
  className = `${avatarClassName} ${className || ''}`;

  return (
    <div className={className} onClick={publishClick}>
      {!token ? (
        <Icon
          className={`rounded-full min-w-full h-full drop-shadow-sm bg-gray-100`}
          src={accountFillBrandIcon}
          size={'medium'}
        />
      ) : imageSrc ? (
        <AuthImage
          className={`rounded-full min-w-full h-full drop-shadow-sm bg-gray-100`}
          src={imageSrc}
          version={getVersionFromAvatarSize(size)}
        />
      ) : (
        <Icon
          className={`rounded-full min-w-full h-full drop-shadow-sm bg-gray-100`}
          src={accountFillBrandIcon}
          size={'medium'}
        />
      )}
      {notifications ? <div className={notificationsClasses}>{notifications}</div> : null}
    </div>
  );
};

Avatar.defaultProps = defaultProps;
