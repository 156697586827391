import React from 'react';

// TODO: Refactor the useDebouncedState. The value state callback is too big and complex.

/**
 * Creates state getter/setter that is debounced when set.
 */
export function useDebouncedState<T>(initial: T, delay: number, options?: any) {
  const [state] = React.useState(() => {
    return {
      next: initial,
    };
  });
  const [current, setCurrent] = React.useState(initial);
  const [setValue] = React.useState(() => {
    let timer = null;
    const id = Math.random();
    const defaultOptions = {
      group: null,
      reschedule: true,
    };

    if (typeof options === 'object') {
      Object.assign(defaultOptions, options);
      options = defaultOptions;
    } else {
      defaultOptions.group = options;
      options = defaultOptions;
    }

    const group = options.group;

    if (!group) {
      return (value) => {
        state.next = value;

        if (!options.reschedule && timer) {
          return;
        }

        if (timer) {
          clearTimeout(timer);
        }

        // @ts-ignore: ignore next line
        timer = setTimeout(() => {
          timer = null;
          setCurrent(state.next);
        }, delay);
      };
    }

    return (value) => {
      state.next = value;

      if (useDebouncedState.groupTimers[group]) {
        clearTimeout(useDebouncedState.groupTimers[group]);
      }

      useDebouncedState.groupTimers[group] = setTimeout(() => {
        for (const cbId in useDebouncedState.groupCallbacks[group]) {
          useDebouncedState.groupCallbacks[group][cbId]();
        }
        useDebouncedState.groupCallbacks[group] = {};
      }, delay);

      if (!useDebouncedState.groupCallbacks[group]) {
        useDebouncedState.groupCallbacks[group] = {};
      }

      useDebouncedState.groupCallbacks[group][id] = () => {
        setCurrent(state.next);
      };
    };
  });

  return [current, setValue, state.next, setCurrent] as [T, (value: T) => void, T, (value: T) => void];
}

useDebouncedState.groupTimers = {};
useDebouncedState.groupCallbacks = {};
