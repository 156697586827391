import Subscription from '@/components/elements/Subscription';
import { Login } from './Login';
import { useAuthState } from '@/logic';
import { useNavigate } from 'react-router';
import { Button, ButtonSizes, Modal, Heading1, Icon } from '@roc-digital/ui-web';
import { useProfile } from '@roc-digital/ui-lib';
import { mxmIcon } from '@roc-digital/ui-lib';

export interface SignupModalProps {
  defaultPlan: 'annual' | 'monthly'
}

export function SignupModal(props: SignupModalProps) {
  const auth = useAuthState();
  const nav = useNavigate();
  const profile = useProfile();

  const authenticated = auth.authenticated && !auth.isAnonymous;
  let content = <></>;
  if(profile?.subscribed) {
    content = <div className='p-8 flex flex-col gap-8'>
      <div className="font-bold text-4xl text-center mb-3">You have already registered!</div>
      <div className='max-w-xl text-center'>You can manage your subscriptions by clicking the link below.</div>
      <a target='_blank' href='https://billing.stripe.com/p/login/3cseYxenWdztgeY9AA'>
        <Button size={ButtonSizes.small} customClassName="w-full px-12 h-auto py-4" clickEvent={() => {}}>
          MANAGE SUBSCRIPTIONS
        </Button>
      </a>
    </div>
  } else if(!authenticated) {
    content = <div>
      <div className='max-w-xl text-center'>Login or create your account.</div>
      <Login loginMode='sign-up'/>
    </div>;
  } else {
    content = <>
      <div className='max-w-xl text-center'>Confirm your plan and pay.</div>
      <Subscription
        coupon={'QmyErYmh'}
        defaultPlan={props.defaultPlan}
        onComplete={() => {
          Modal.close('mxm.modal');
          nav('/thanks')
        }}
      />
    </>
  }

  return <div className='flex flex-col gap-4 p-4'>
    <div className="flex items-center">
      <div className="mx-auto flex items-center">
        <Icon src={mxmIcon} size="x-large" />
        <Heading1 className="text-3xl text-admin-dark font-chivo font-bold dark:text-white pl-4">MxM News</Heading1>
      </div>
    </div>
    <div>{content}</div>
  </div>

}