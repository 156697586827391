export interface CardProps {
  children: any;
  orientation? : 'portrait' | 'landscape',
  header?: JSX.Element;
  className?: string;
  noFrame?: boolean;
}

export function Card(props: CardProps) {
  const flexDirection = props.orientation === 'landscape' ? 'flex-row' : 'flex-col';
  let cardFrame = `shadow shadow-gray-300 bg-white rounded-sm`;
  let contentFrame = `p-2`;

  if(props.noFrame) {
    cardFrame = '';
    contentFrame = '';
  }

  if(props.header) {
    return <div className={`w-full flex ${flexDirection} ${cardFrame} overflow-hidden dark:bg-surface-dark-low ${props.className || ''}`}>
      <div className='article-card-header flex flex-1 flex-col bg-gray-200 justify-center bg-red-400'>
        {props.header}
      </div>
      <div className={`article-card-content flex flex-1 flex-col gap-2 ${contentFrame}`} style={{minHeight: '0px'}}>
        {props.children}
      </div>
    </div>
  } else {
    return <div className={`article-card-content flex flex-col gap-2 ${contentFrame} ${cardFrame} overflow-hidden dark:bg-surface-dark-low ${props.className || ''}`} style={{minHeight: '0px'}}>
      {props.children}
    </div>;
  }
}