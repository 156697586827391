"use strict";
// @copyright 2022 Navigo, Inc
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.readActiveView = exports.deleteView = exports.activateView = exports.listViews = exports.duplicateView = exports.putView = exports.readViewV2 = exports.readView = void 0;
var data_1 = require("../data");
var http_1 = require("./http");
var config_1 = require("../config");
var json_fetch_1 = require("./json-fetch");
var VIEWS_CACHE_TTL_CACHE_TTL = 0; // 1 second
var SERVICE_NAME = 'views';
var JSON_PATH = '_';
function url(isAdmin) {
    if (isAdmin === void 0) { isAdmin = false; }
    return isAdmin ? "".concat((0, config_1.getConfig)().mxmApiAdminUrl, "/views") : "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/views");
}
function resolveView() {
    return (0, http_1.createEntity)(JSON_PATH, data_1.View);
}
function resolvePaginatedViewCollection() {
    return (0, http_1.createPaginatedCollection)('_', data_1.Views, data_1.View);
}
function readView(id, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'readView', (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "/").concat(id), params, undefined, {
        'Content-Type': 'application/json',
    }), resolveView(), true, VIEWS_CACHE_TTL_CACHE_TTL);
}
exports.readView = readView;
function readViewV2(id) {
    return __awaiter(this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                        url: "".concat(url(true), "/").concat(id)
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result.data];
            }
        });
    });
}
exports.readViewV2 = readViewV2;
function putView(view) {
    var _a, _b;
    if ((_a = view.zones) === null || _a === void 0 ? void 0 : _a.length) {
        (_b = view.zones) === null || _b === void 0 ? void 0 : _b.forEach(function (zone) {
            var _a;
            if ((_a = zone.articles) === null || _a === void 0 ? void 0 : _a.length) {
                zone.articles = zone.articles.filter(function (a) {
                    if (a.id.startsWith('temp_')) {
                        return false;
                    }
                    return true;
                });
            }
        });
    }
    if (view.id) {
        return updateViewV2(view);
    }
    else {
        return createViewV2(view);
    }
}
exports.putView = putView;
function createViewV2(view) {
    return __awaiter(this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                        method: 'POST',
                        url: "".concat(url(true)),
                        data: view
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result.data];
            }
        });
    });
}
function updateViewV2(view) {
    return __awaiter(this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, json_fetch_1.ApiFetch)({
                        method: 'PATCH',
                        url: "".concat(url(true), "/").concat(view.id),
                        data: view
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result.data];
            }
        });
    });
}
function duplicateView(id) {
    return __awaiter(this, void 0, void 0, function () {
        var view, newView;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, readViewV2(id)];
                case 1:
                    view = _a.sent();
                    if (!view) {
                        throw new Error('Failed to read view');
                    }
                    newView = __assign({}, view);
                    newView.title = "Copy of ".concat(newView.title || 'untitled view');
                    delete newView.id;
                    return [4 /*yield*/, createViewV2(newView)];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
exports.duplicateView = duplicateView;
function listViews(params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'listViews', (0, http_1.createHTTPRequest)('GET', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin)), params), resolvePaginatedViewCollection(), true, VIEWS_CACHE_TTL_CACHE_TTL);
}
exports.listViews = listViews;
function activateView(id, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'activateView', (0, http_1.createHTTPRequest)('GET', "".concat(url(true), "/").concat(id, "/activate"), params), resolveView());
}
exports.activateView = activateView;
function deleteView(id, params, cacheState) {
    return (0, http_1.resolveTextHTTPResponse)(SERVICE_NAME, 'deleteViews', (0, http_1.createHTTPRequest)('DELETE', "".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "/").concat(id), params), function () { return ({ id: id }); }).then(function (tag) {
        if (cacheState) {
            cacheState.remove("".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin), "/").concat(id));
            cacheState.remove("".concat(url(params === null || params === void 0 ? void 0 : params.isAdmin)));
        }
        return tag;
    });
}
exports.deleteView = deleteView;
function readActiveView() {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var views, view;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, listViews()];
                case 1:
                    views = _c.sent();
                    if (!views.first())
                        return [2 /*return*/, null];
                    view = (_a = views.first()) === null || _a === void 0 ? void 0 : _a.toParams();
                    if ((_b = view === null || view === void 0 ? void 0 : view.zones) === null || _b === void 0 ? void 0 : _b.length) {
                        view.zones = view === null || view === void 0 ? void 0 : view.zones.sort(function (a, b) { return (a.order || 0) - (b.order || 0); });
                    }
                    return [2 /*return*/, view || null];
            }
        });
    });
}
exports.readActiveView = readActiveView;
