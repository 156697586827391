"use strict";
/* eslint-disable import/no-cycle */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MocksState = void 0;
// @copyright 2023 MXM, Inc
var miragejs_1 = require("miragejs");
var config_1 = require("../config");
var logging_1 = require("../logic/logging");
var http_1 = require("../logic/http");
var mockServer;
var MOCKS = {};
function resolveMockResponse(request, endpoint) {
    var hash = (0, http_1.generateRequestHash)(request.method || 'GET', request.url, request.queryParams, request.params);
    if (endpoint.mocks[hash]) {
        var response = endpoint.mocks[hash].response;
        return response;
    }
    return undefined;
}
function resolve(request, endpoint) {
    var rePath = request.url.replace(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/gi, '{id}');
    var method = endpoint.method, url = endpoint.url;
    var queryParams = request.queryParams, params = request.params, headers = request.headers;
    var response = resolveMockResponse({ method: method, url: url, queryParams: queryParams, params: params, headers: headers }, endpoint) ||
        resolveMockResponse({ method: method, url: url, queryParams: queryParams, params: params }, endpoint) ||
        resolveMockResponse({ method: method, url: url, queryParams: queryParams }, endpoint) ||
        resolveMockResponse({ method: method, url: url }, endpoint) ||
        resolveMockResponse({ method: method, url: rePath, queryParams: queryParams, params: params, headers: headers }, endpoint) ||
        resolveMockResponse({ method: method, url: rePath, queryParams: queryParams, params: params }, endpoint) ||
        resolveMockResponse({ method: method, url: rePath, queryParams: queryParams }, endpoint) ||
        resolveMockResponse({ method: method, url: rePath }, endpoint);
    if (!response) {
        throw new Error("Unknown mock for ".concat(JSON.stringify(request)));
    }
    return response({ method: method, url: url, queryParams: queryParams, params: params, headers: headers });
}
function resolveWithMirage(request, endpoint) {
    var method = endpoint.method, url = endpoint.url;
    var queryParams = request.queryParams, params = request.params;
    var headers = request.requestHeaders;
    var mockResponse = resolve({ method: method, url: url, queryParams: queryParams, params: params, headers: headers }, endpoint);
    return new miragejs_1.Response(mockResponse.status, mockResponse.headers, mockResponse.body);
}
function createMockServer() {
    return (0, miragejs_1.createServer)({
        routes: function () {
            var _this = this;
            var endpointKeys = Object.keys(MOCKS);
            endpointKeys.forEach(function (endpointKey) {
                var endpoint = MOCKS[endpointKey];
                if (endpoint.method === undefined || endpoint.method === 'GET') {
                    (0, logging_1.log)('[MockState.add]', [endpoint]);
                    _this.get(endpoint.url, function (schema, request) { return resolveWithMirage(request, endpoint); });
                }
                if (endpoint.method === 'POST') {
                    (0, logging_1.log)('[MockState.add]', [endpoint]);
                    _this.post(endpoint.url, function (schema, request) { return resolveWithMirage(request, endpoint); });
                }
                if (endpoint.method === 'PUT') {
                    (0, logging_1.log)('[MockState.add]', [endpoint]);
                    _this.put(endpoint.url, function (schema, request) { return resolveWithMirage(request, endpoint); });
                }
                if (endpoint.method === 'PATCH') {
                    (0, logging_1.log)('[MockState.add]', [endpoint]);
                    _this.patch(endpoint.url, function (schema, request) { return resolveWithMirage(request, endpoint); });
                }
                if (endpoint.method === 'DELETE') {
                    (0, logging_1.log)('[MockState.add]', [endpoint]);
                    _this.delete(endpoint.url, function (schema, request) { return resolveWithMirage(request, endpoint); });
                }
                if (endpoint.method === 'OPTIONS') {
                    (0, logging_1.log)('[MockState.add]', [endpoint]);
                    _this.options(endpoint.url, function (schema, request) { return resolveWithMirage(request, endpoint); });
                }
            });
        },
    });
}
var MocksState = /** @class */ (function () {
    function MocksState() {
    }
    MocksState.start = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require('../mocks')); })];
                    case 1:
                        _a.sent();
                        mockServer = createMockServer();
                        mockServer.logging = (0, config_1.getConfig)().logging;
                        if ((0, config_1.getConfig)().appDevice === 'mobile') {
                            if (window.server) {
                                window.server.shutdown();
                            }
                            window.server = mockServer;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MocksState.stop = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                mockServer === null || mockServer === void 0 ? void 0 : mockServer.shutdown();
                mockServer = undefined;
                MOCKS = {};
                return [2 /*return*/];
            });
        });
    };
    MocksState.create = function (request, response) {
        var mockRequest = request;
        var mockResponse = response;
        if (typeof request === 'string') {
            mockRequest = { method: 'GET', url: request };
        }
        if (typeof response === 'function') {
            mockResponse = response;
        }
        else {
            mockResponse = function () { return ({ status: 200, body: response, headers: { 'Content-Type': 'application/json' } }); };
        }
        this.add(mockRequest, mockResponse);
    };
    MocksState.add = function (request, response) {
        var finalRequest = __assign({}, request);
        var url = new URL(request.url);
        finalRequest.method = finalRequest.method || 'GET';
        finalRequest.url = "".concat(url.origin).concat(url.pathname);
        if (!finalRequest.params || Object.keys(finalRequest.params).length === 0) {
            finalRequest.queryParams = {};
        }
        url.searchParams.forEach(function (value, key) {
            finalRequest.queryParams[key] = value;
        });
        var mockHash = (0, http_1.generateRequestHash)(finalRequest.method, finalRequest.url, finalRequest.queryParams, finalRequest.params);
        if (!MOCKS["".concat(finalRequest.method, " ").concat(finalRequest.url)]) {
            MOCKS["".concat(finalRequest.method, " ").concat(finalRequest.url)] = {
                method: finalRequest.method,
                url: finalRequest.url,
                mocks: {},
            };
        }
        MOCKS["".concat(finalRequest.method, " ").concat(finalRequest.url)].mocks[mockHash] = { request: finalRequest, response: response };
    };
    MocksState.resolve = function (request) {
        var finalRequest = __assign({}, request);
        var url = new URL(request.url);
        finalRequest.method = finalRequest.method || 'GET';
        finalRequest.url = "".concat(url.origin).concat(url.pathname);
        if (!finalRequest.params || Object.keys(finalRequest.params).length === 0) {
            finalRequest.queryParams = {};
        }
        url.searchParams.forEach(function (value, key) {
            finalRequest.queryParams[key] = value;
        });
        var endpoint = MOCKS["".concat(finalRequest.method, " ").concat(finalRequest.url)];
        return resolve(finalRequest, endpoint);
    };
    return MocksState;
}());
exports.MocksState = MocksState;
