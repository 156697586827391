import { getClassNames } from '@roc-digital/ui-lib';

type Margin = '0' | '1' | '2' | '3' | '4';

type Props = {
  className?: string;
  margins?: Margin;
  marginTop?: Margin;
  marginBottom?: Margin;
};

const styleClasses = {
  base: `border-b-edge border-b-1 border-solid`,
  marginTop: {
    0: `mt-0`,
    1: `mt-1`,
    2: `mt-1`,
    3: `mt-2`,
    4: `mt-3`,
  },
  marginBottom: {
    0: `mb-0`,
    1: `mb-1`,
    2: `mb-1`,
    3: `mb-2`,
    4: `mb-4`,
  },
};

export const Spacer = ({ className, marginTop, marginBottom, margins }: Props) => {
  // When the vertical margins is present use it to initialize top and bottom margins
  if (margins !== undefined) {
    marginTop = margins;
    marginBottom = margins;
  }

  const spacerClassName = getClassNames(styleClasses, { marginTop, marginBottom });

  className = `${spacerClassName} ${className}`;

  return (
    <div className={`relative flex items-center ${className}`}>
      <div className="flex-grow border-t border-stone-200"></div>
      <div className="flex-grow border-t border-stone-200"></div>
    </div>
  );
};
