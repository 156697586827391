import { Icon } from '@roc-digital/ui-web';
import { mxmIcon } from '@roc-digital/ui-lib';
import { useRef } from 'react';
import { useNavigate } from 'react-router';

interface ArticleCard {
  size?: string;
  easeload?: string;
  position?: string;
  image?: string;
  title?: string;
  tag?: string;
  date?: string;
  logo?: string;
  type?: string;
  url?: string;
  id?: string;
}

const ArticleCard = ({ size, easeload, position, image, title, date, logo, tag, type, url, id }: ArticleCard) => {
  const navigate = useNavigate();
  const divRef = useRef<HTMLDivElement>(null);

  const handleDivLoad = () => {
    if (divRef.current) {
      divRef.current.classList.add('easeLoadedImage');
    }
  };

  const trimTitle = (title: string) => {
    if (title.length > 50) {
      title = title.substring(0, 50) + "...";
    } 
    return title;
  };

  /*
  function extractLogo(url: string) {
    if (url) {
      const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^\/?#]+)/i;
      const matches = url.match(domainRegex);
      if (matches && matches.length > 1) {
        const favicon = 'https://s2.googleusercontent.com/s2/favicons?domain=' + matches[1];
        return favicon;
      }
    }
    return '';
  }
  */

  return (
    <div onClick={() => navigate(`/article/${id}`)} ref={divRef} className={`${easeload} drop-shadow-xl cursor-pointer bg-white rounded-xl home-postion ${position} ${size}`} onLoad={handleDivLoad}>
      <div className="overflow-hidden rounded-tr-xl rounded-tl-xl image-wrapper bg-gray-100">
        <img src={image ? image : '/photos/no-image1.svg'} alt={`Image for ${title}`}/>
      </div>
      <div className="mt-4 text-lg pl-4 pr-4 pb-1">
        <div className="flex items-center w-full ">
          <div className="w-full flex items-center justify-between">
            {type === 'mxm_exclusive' ? 
              <>
                <Icon src={mxmIcon} size="medium" />
                <div className="text-xs ml-2 capitalize border py-0 px-1 rounded-full bg-gray-100">MXM Exclusive</div>
              </>
              : 
              <>
                <Icon src={mxmIcon} size="medium" />
                <div className="text-xs ml-2 capitalize border py-0 px-1 rounded-full bg-gray-100">{tag ? tag?.replaceAll('-', ' ') : 'Trending News'}</div>
              </>
            }
          </div>
        </div>
        <div className="font-bold article-card-title pt-2 pb-2">{trimTitle(title || '')}</div>
        <div></div>
      </div>
    </div>
  );
};

ArticleCard.displayName = 'Article Card';

export default ArticleCard;
