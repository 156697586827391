import { useRef } from 'react';
import GetAppButtonGroup from '@/components/elements/GetAppButtonGroup';
import Footer from '@/components/elements/Footer';
import loginPageImage from '../../assets/device_layouts.png';

export interface LandingPageViewProps {
  children: any;
}

export function LandingPageView(props: LandingPageViewProps) {
  const imageRef = useRef<HTMLImageElement>(null);

  const handleImageLoad = () => {
    if (imageRef.current) {
      imageRef.current.classList.add('easeLoadedImage');
    }
  };

  return (
    <>
      <div className="flex flex-col items-center pt-16 sm:pt-2 sm:px-2 bg-gray-100 dark:bg-surface-dark relative">
        <div className="bg-white dark:bg-surface-dark-low rounded-2xl shadow-md sm:w-full py-12 px-12 visible-xs z-50 mb-8" style={{minWidth: '320px', minHeight: '500px'}}>
          {props.children}
          <div className="flex flex-col items-center">
            <div className="mb-4 mt-10 font-medium dark:text-white">Get the App</div>
            <GetAppButtonGroup />
          </div>
        </div>

        <div className="flex justify-between max-w-5xl w-full mb-24 hidden-xs">
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-col items-center bg-white rounded-2xl p-12 sm:px-2 sm:py-8 shadow-md dark:bg-surface-dark-low ml-2" style={{minWidth: '320px', minHeight: '500px'}}>
              {props.children}
            </div>
            <div className="flex flex-col items-center">
              <div className="mb-4 mt-10 font-medium dark:text-white">Get the App</div>
              <GetAppButtonGroup />
            </div>
          </div>
          <div className="relative">
            <div className="login_signup_image">
              <img
                src={loginPageImage}
                ref={imageRef}
                alt={'Image of MXM news loaded on a phone and a computer'}
                onLoad={handleImageLoad}
                className="easeload"
              />
            </div>
          </div>        
        </div>
      </div>
      <div className="bg-gray-100 dark:bg-surface-dark">
        <Footer />
      </div>
    </> 
  );
};