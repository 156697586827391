import { reportMxMAnalytics } from '@roc-digital/mxm-base/logic';

export function appLaunch() {
  const srcInterest = new URL(window.location.toString()).searchParams.get('src');

  if(srcInterest) {
    localStorage.setItem('src-tag', srcInterest);
  }
 
}

export function postAuth() {
  const srcInterest = localStorage.getItem('src-tag');
  if(srcInterest) {
    localStorage.removeItem('src-tag');
    reportMxMAnalytics({srcInterest});
  }
}