import React, { memo } from 'react';
import { useNavigate } from 'react-router';
import AppFooter from '@/components/elements/AppFooter';
import { ArticleContent, ArticleAction, useProfile, useMethod } from '@roc-digital/ui-lib';
import { Heading1, ZoneLayout } from '@roc-digital/ui-web';
import { useArticlesQuery } from '@/logic/useArticlesQuery';
import { Button, ButtonSizes, Loading } from '@roc-digital/ui-web';
import { ArticlesPage, InfiniteScrollMarker } from '@/components/elements';
import { TagSelectorHeader } from '@/components/elements/TagSelectorHeader';
import { listMyTagsV2 } from '@roc-digital/mxm-base/logic';
import { PaywallOverlay } from '@/components/elements/PaywallOverlay';

const Local = memo(() => {
  const navigate = useNavigate();
  const articlesQuery = useArticlesQuery(['feed:near-me'], 18);
  const tagQuery = useMethod(listMyTagsV2, { autoRun: ['location'] });
  const profile = useProfile();

  const render = React.useCallback((articles: ArticleContent[], onAction: (action: ArticleAction) => void) => {
    const map = {
      top: articles.slice(0, 3),
      mid: articles.slice(3, 6),
      bottom: articles.slice(6, 12),
      end: articles.slice(12)
    };

    return <div className="flex-grow bg-white gap-y-4 dark:bg-surface-dark mx-2">
      <ZoneLayout articles={map.top} layoutId={3} onAction={onAction} />
      <span className={'mb-4 block'} />
      <ZoneLayout articles={map.mid} layoutId={3} onAction={onAction} />
      <span className={'mb-4 block'} />
      <ZoneLayout articles={map.bottom} layoutId={8} onAction={onAction} />
      <span className={'mb-4 block'} />
      <ZoneLayout articles={map.end} layoutId={8} onAction={onAction} />
    </div>
  }, []);


  const isEmpty = !articlesQuery.loading && !articlesQuery.result?.[0]?.length;
  const pages = articlesQuery.result || [];

  return (
    <>
      <div className="h-full w-full flex flex-col justify-center mx-auto pt-0 dark:bg-surface-dark flex-grow relative pw-blur">
        <div className="w-full border-b border-t pt-4 border-font-edge dark:border-[#444444] dark:bg-black">
          <TagSelectorHeader
            allScope={'feed:near-me'}
            tags={tagQuery.result || []}
            activeFilters={articlesQuery.activeFilters}
            setActiveFilters={articlesQuery.setActiveFilters}
          />
        </div>
        <div className={'flex w-full flex-col justify-between items-start max-w-5xl mx-auto mb-4 px-2 mt-4'}>
          <Heading1 className={`ml-2 mb-4 text-action !font-extrabold !text-[32px] !leading-9 !tracking-tight !font-chivo`}>
            Local News
          </Heading1>
          <div className="flex flex-col flex-grow bg-white gap-y-4 dark:bg-surface-dark gap-4">

            {isEmpty ? <>
              <div className="dark:text-white">
                No local articles available for your selected locations.
                <span className={'mb-4 block'} />
                <Button size={ButtonSizes.medium} clickEvent={() => navigate('/account/locations')}>Select More Locations</Button>
              </div>
            </> : null}


            {pages.map((page, index) => {
              return <ArticlesPage
                articles={page}
                renderPage={render}
                key={index}
              />
            })}

            <InfiniteScrollMarker
              hasMore={articlesQuery.hasMore && profile?.subscribed ? true : false}
              onLoad={async () => {
                await articlesQuery.loadNextPage()
              }}
              className='flex justify-center mt-4'
            >
              {articlesQuery.loading ? <>
                <Loading className='mx-auto' />
              </> : null}
            </InfiniteScrollMarker>
          </div>
        </div>
      </div>
      <PaywallOverlay
        message='Subscribe to MxM News+ to access local news.'
      />
      <AppFooter />
    </>
  );
});

Local.displayName = 'Local';

export default Local;

