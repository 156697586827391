type Props = {
  size?: 'x-small' | 'small' | 'medium' | 'large';
  color?: 'gray' | 'white';
  customClassName?: string;
};
export const Loading = ({ size = 'medium', color = 'gray', customClassName = '' }: Props) => {
  return (
    <img
      width={size === 'large' ? 48 : size === 'medium' ? 36 : 24}
      src={color === 'gray' ? '/spinner2.gif' : '/spinner4.gif'}
      className={customClassName}
    />
  );
};
