
type Props = {
  id?: string;
  className?: string;
};

export const Rumble = ({ id = '', className = '' }: Props) => {
  className = className || '';
  id = id || 'v6uz6v';

  return (
    <div className={`rumble-container ${className}`}>
      <iframe className="responsive-rumble-iframe" src={`https://rumble.com/embed/${id}/?pub=7a20`} />
    </div>
  );
};
