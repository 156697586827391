import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { getEnvKey } from '@/logic/env';

const stripeKey = getEnvKey('stripePublishableKey');

export interface SubscriptionProps {
  onComplete?: () => void;
  coupon?: string;
  defaultPlan?: 'annual' | 'monthly',
  noFrame?: boolean;
  preCheck?: () => boolean;
}

const Subscription = (props: SubscriptionProps) => {
  const [stripePromise, setStripePromise] = useState<any>(null);
  
  useEffect(() => {
    import('@stripe/stripe-js')
      .then(({ loadStripe }) => {
        const stripePromise = loadStripe(stripeKey);
        setStripePromise(stripePromise);
      })
      .catch(err => {
        console.log('Failed to load Stripe', err);
      });
  }, []);
  
  const options: any = {
    // mode: 'payment',
    // amount: 996,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <div className={props.noFrame !== true ? 'p-8 border' : ''}>
      <div>Get MxM News+ and access Paid Exclusives and unlimited local news from over 200 US cities!</div>
      {stripePromise ? 
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            coupon={props.coupon}
            onComplete={props.onComplete}
            defaultPlan={props.defaultPlan}
          />
        </Elements>
      : 'Loading Stripe...'}
    </div>
  );
};

export default Subscription;