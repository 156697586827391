import { scaleDiverging } from 'd3-scale';
import { useEffect, useState, RefObject } from 'react';
import { useContainerWidth } from './useContainerWidth';

const fontScale = scaleDiverging().domain([0, 800, 4000]).range([36, 10, 6]);

export const useContainerScaledFont = (containerRef: RefObject<HTMLDivElement | undefined>) => {
  const [fontSize, setFontSize] = useState(10);
  const currentWidth = useContainerWidth(containerRef);
  useEffect(() => {
    if (currentWidth) {
      setFontSize(fontScale(currentWidth));
    } else {
      setFontSize(10);
    }
  }, [currentWidth]);
  return fontSize;
};
