import { useState, useRef, useEffect } from 'react';
import { Event } from '@roc-digital/mxm-base/types';
import { Notification, useCustomEvent } from '@roc-digital/ui-lib';
import { NotificationsItem } from './NotificationsItem';

const REMOVE_DELAY = 8000; // milliseconds

type Props = {
  className?: string;
  removeDelay?: number;
};

const notificationsClasses =
  'absolute flex flex-col justify-end top-0 right-0 bottom-0 w-[346px] h-full pr-1 bg-transparent pointer-events-none z-50';

export const Notifications = ({ removeDelay }: Props) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const notificationsRef = useRef<Notification[]>([]);
  const options = { namespace: 'mxm.notifications' };

  useEffect(() => {
    notificationsRef.current = notifications;
  }, [notifications]);

  const notificationsHandler = (evt: Event<Notification>) => {
    setNotifications([...notificationsRef.current, evt.data]);
  };

  useCustomEvent('*', notificationsHandler, options);

  const removeNotification = (notification: Notification) => {
    const values = notificationsRef.current.filter(({ key }) => key !== notification.key);

    setNotifications(values);
  };

  return (
    <ul className={notificationsClasses}>
      {notifications.map((notification) => (
        <NotificationsItem
          key={notification.key}
          notification={notification}
          removeDelay={removeDelay || REMOVE_DELAY}
          onRemove={removeNotification}
        />
      ))}
    </ul>
  );
};

Notifications.defaultProps = {
  removeDelay: REMOVE_DELAY,
};
