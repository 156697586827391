import { memo } from 'react';
import { Button, ButtonSizes, Modal } from '@roc-digital/ui-web';
import deviceLayoutsImage from '../../../assets/device_layoutsOLD.png';
import landingPageImage from '../../../assets/LandingPage.png';
import landingPageImage2 from '../../../assets/LandingPage2.png';
import React from 'react';
import { SignupModal } from './SignupModal'

const Landing = memo(() => {
  const ref: React.Ref<HTMLDivElement> = React.createRef();
  
  const goToSignup = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const openSignupModal = (plan: 'annual' | 'monthly') => {
    Modal.open('mxm.modal', <SignupModal defaultPlan={plan}/>, 'small');
  };

  return (
    <>
      <div className="h-full flex flex-col justify-center bg-gray-100 font-chivo overflow-x-hidden text-white">
        <div
          className="relative flex flex-col items-center justify-center pt-0 pb-10 bg-cover bg-center"
          style={{ backgroundImage: 'url(\'/landingbackground.jpg\')' }}
        > 
          <div className="flex justify-center pt-6">
            <img
              alt={'logo'}
              src={'/icons/logo.svg'}
              className={'cursor-pointer h-10 w-10 mx-0 sm:mx-2 inline'}
            />
            <span className="font-chivo pl-2 text-4xl font-bold">MxM News</span>
          </div>
          <div className='text-4xl font-bold pt-20'>Announcing...</div>
          <div className="hidden-xs text-8xl font-bold text-center text-font-high text-white">
              MxM News+
          </div>
          <div className="visible-xs text-6xl font-bold text-center text-font-high text-white">
              MxM News+
          </div>
          <div className='pb-8'>
            <Button customClassName="!w-auto mt-16 px-12 h-auto py-4" clickEvent={() => goToSignup()}>
              <div>
                <b className='font-chivo'>ONE MONTH FREE</b><br/>
                Pre-Order MxM News+ Now
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div className="relative flex flex-col items-center justify-center pt-16 bg-font text-white">
        <div className="text-6xl font-bold max-w-[600px] text-center">Local news for over 200 U.S. cities</div>
        <div className="text-base mt-8 max-w-md text-center text-lg">
        From Miami, FL to Seattle, WA and Scottsdale, AZ to Portland, ME. MxM News+ delivers the news that matters to YOU, all in one place.
        </div>
        <div className='text-center mt-8'>
          <Button customClassName="!w-auto px-12 h-auto py-4" clickEvent={() => goToSignup()}>
            <div>
              Pre-Order MxM News+ Now
            </div>
          </Button>
        </div>
        <div className="flex justify-center mt-12">
          <img src={landingPageImage} />
        </div>
      </div>
      <div className="relative flex flex-wrap items-center justify-center mt-20 mb-24 bg-white text-black max-w-[1000px] mx-auto">
        <div className="flex justify-center mt-12 w-1/2 min-w-[400px]">
          <img src={landingPageImage2} />
        </div>
        <div className='w-1/2 min-w-[400px]'>
          <div className="text-6xl font-bold text-center mt-6">Ad-free news</div>
          <div className="text-base mt-8 max-w-md text-center text-lg">
          Want the news without the nasty and annoying ads? MxM News+ cuts out the ads and delivers the news you need to see, without the clutter.
          </div>
          <div className='text-center mt-8'>
            <Button customClassName="!w-auto px-12 h-auto py-4" clickEvent={() => goToSignup()}>
              <div>
                Pre-Order MxM News+ Now
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div className='mb-12' style={{ background: 'linear-gradient(0deg, #FFFFFF 34.89%, #DBF5FA 100%)' }}>
        <div className="text-5xl mt-20 max-w-3xl text-center mx-auto px-2">
            “MxM News delivers the stories you need to see each day without the bias or censorship”
        </div>
        <div className="mt-4 rounded-full w-20 h-20 mx-auto">
          <img src={'/photos/donjr.png'} alt="Don Trump Jr" />
        </div>
        <div className="mt-4 text-center">
          <span className="font-bold text-lg">Donald Trump Jr.</span>
          <span className="text-lg">, Co-Founder</span>
        </div>
        <div className='text-center mt-8'>
          <Button customClassName="!w-auto px-12 h-auto py-4" clickEvent={() => goToSignup()}>
            <div>
              Pre-Order MxM News+ Now
            </div>
          </Button>
        </div>
      </div>
      <hr/>
      <div className='max-w-[860px] mx-auto font-chivo mb-12 mt-12'>{/* signup form */} 
        <div className="font-bold text-4xl text-center mb-3 text-[#33ace1]">Subscribe NOW for one month FREE</div>
        <div className='text-center'>And read top uncensored stories, exclusives, and breaking news from trusted sources!</div>
        {/* product cards */} 
        <div ref={ref} className='flex flex-wrap'>
          <div className='flex-1 rounded-lg bg-surface-primary shadow-xl mx-1 p-6 mt-4 text-white min-w-[350px]'>
            <div className='text-center'>
              <div className='text-md font-bold'>Monthly</div>
              <div className='font-bold text-4xl'>$2.49/week</div>
              <div className='font-bold text-lg'>$9.96/month</div>
            </div>
            <ul className='pt-4 text-lg'>
              <li className='flex'><div className='pr-1'>+</div><div>Ad-free mainstream news without mainstream bias</div></li>
              <li className='flex'><div className='pr-1'>+</div><div>Local news from YOUR community</div></li>
              <li className='flex'><div className='pr-1'>+</div><div>Top podcasts, interviews, and viral must-see content, all in one place.</div></li>
            </ul>
            <div className='mt-6'>
              <Button size={ButtonSizes.small} customClassName="mt-auto bg-white w-full px-12 h-auto py-4" clickEvent={() => openSignupModal('monthly')}>
              <span className='text-black font-bold text-lg text-[#33ace1]'>GET Monthly</span>
              </Button>
            </div>
          </div>
          <div className='flex-1 rounded-lg bg-surface-primary shadow-xl mx-1 p-6 mt-4 text-white relative min-w-[350px] min-h-[350px]'>
            <div className='text-center'>
              <div className='text-md font-bold'>Annual</div>
              <div className='font-bold text-4xl'>$1.86/week</div>
              <div className='font-bold text-lg'><span className='text-lg pr-2' style={{textDecoration: "line-through"}}>$119.52</span>$89.64/year</div>
            </div>
            <ul className='pt-4 text-lg'>
              <li className='flex'><div className='pr-1'>+</div><div>Everything in monthly</div></li>
              <li className='flex'><div className='pr-1'>+</div><div>Plus 30% savings!</div></li>
              <li className='flex'><div className='pr-1'>+</div><div>Annual access</div></li>
            </ul>
            <div className='mt-6 absolute bottom-[24px] w-full pr-12'>
              <Button size={ButtonSizes.small} customClassName="mt-auto bg-white w-full px-12 h-auto py-4" clickEvent={() => openSignupModal('annual')}>
                <span className='text-black font-bold text-lg text-[#33ace1]'>GET Annual</span>
              </Button>
            </div>
          </div>
        </div>
        
      </div>
      <div className="w-full bg-font dark:bg-black">
        <div className="max-w-[860px] mx-auto pt-12 pb-12">
          <div className='inline-grid grid-cols-2 w-full'>
            <div className='w-full'>
              <img
                  alt={'logo'}
                  src={'/icons/logo.svg'}
                  className={'cursor-pointer h-10 w-10 mx-0 sm:mx-2 inline'}
                />
            </div>
            <div className='w-full text-right'>
              
            </div>
          </div>
        </div>
        
        <hr className='text-white'/>
        <div className='py-8 text-center font-md text-white'>
          <div className='inline px-4'><a href="https://mxmnews.com/privacy" target='_BLANK'>Privacy Policy</a></div>
          <div className='inline px-4'><a href="https://mxmnews.com/terms" target='_BLANK'>Terms and Conditions</a></div>
          <div className='inline px-4'>&copy; 2023 MxM News, All Rights Reserved.</div>
        </div>
      </div>
    </>
    );
});

Landing.displayName = 'Landing';

export default Landing;