import React from 'react';
import { toast } from 'react-toastify';
import { useMethod } from '@roc-digital/ui-lib';
import { LoginAndCheckAccount } from '@/logic';
import { LoginForm } from '@/components/elements';

export interface LoginProps {
  loginMode: 'login' | 'sign-up';
}

export function Login(props: LoginProps) {
  const [mode, setMode] = React.useState<'login' | 'sign-up'>('sign-up');

  const loginQuery = useMethod(LoginAndCheckAccount, {
    onError: (err) => {
      toast.error(err.toString() || 'Incorrect username or password.');
    }
  });

  return <LoginForm
    mode={mode}
    loading={loginQuery.loading}
    onLogin={loginQuery.run}
    disableGuestAccess
    onSwitchToLogin={() => setMode('login')}
    onSwitchToSignUp={() => setMode('sign-up')}
    hideHeader
  />
}
