import React, { useEffect, useRef } from 'react';

const YouTubePlayer: React.FC<{ videoId: string }> = ({ videoId }) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const player = useRef<any>(null);

  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    if (firstScriptTag) {
      firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
    }

    (window as any).onYouTubeIframeAPIReady = () => {
      player.current = new (window as any).YT.Player(playerRef.current, {
        videoId: videoId,
        events: {
          'onReady': onPlayerReady,
        },
        playerVars: {
          autoplay: 1,
          mute: 1
        }
      });
    };

    return () => {
      if (player.current) {
        player.current.destroy();
      }
    };
  }, [videoId]);

  const onPlayerReady = (event: any) => {
    event.target.playVideo();
    setTimeout(() => {
      event.target.unMute();
    }, 100);
  };

  return (
    <div className="relative pb-[56.25%] h-0 overflow-hidden max-w-full">
      <div ref={playerRef} className="absolute top-0 left-0 w-full h-full"></div>
    </div>
  );
};

export default YouTubePlayer;
