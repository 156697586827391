import { ModuleStyles } from '..';

type Descriptors = { [name: string]: string | number | boolean | undefined };

// Joins CSS class names into a single string that can be used in the "className" attribute
export function joinClasses(className: string | undefined, styles: ModuleStyles, desc: Descriptors): string {
  const prefixClasses = Object.entries(desc)
    .map(([attr, value]) => {
      let name = '';

      // When the attr is a boolean use its name for the CSS class
      // otherwise join the name and the value to the name.
      if (typeof value === 'boolean') {
        name = value ? styles[attr] : '';
      } else {
        name = styles[`${attr}-${value}`] || '';
      }

      return name || '';
    })
    .filter((value: string) => value !== '')
    .join(' ')
    .trim();

  return className ? `${prefixClasses} ${className}` : prefixClasses;
}

export const getClassNames = (classes, props): string => {
  const classNames = [classes.base || ''];

  Object.keys(props).forEach((key) => {
    if (typeof props[key] === 'boolean' && props[key]) {
      classNames.unshift(classes[key] || '');
    } else {
      classNames.unshift(classes[key][props[key]] || '');
    }
  });

  return classNames
    .filter((value: string) => value !== '')
    .join(' ')
    .trim();
};
