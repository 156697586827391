import React from "react";
import { Chip } from "./Chip";

export interface ChipItem {
  id?: string,
  label: string,
}

export interface ChipListProps {
  value?: ChipItem[];
  onChange?: (value: ChipItem[]) => void
}

export function ChipList(props: ChipListProps) {
  const [value, setValue] = React.useState(props.value || []);
  const propValueRef = React.useRef(props.value);
  
  const removeItem = (item: ChipItem) => {
    changeValue(value.filter(v => v !== item));
  }

  const changeValue = (value: ChipItem[]) => {
    propValueRef.current = value;
    setValue(value);
    if (props.onChange) props.onChange(value);
  }

  React.useEffect(() => {
    if(props.value !== propValueRef.current) {
      setValue(props.value || []);
      propValueRef.current = props.value;
    }
  }, [props.value]);

  return <div
    className='flex flex-row gap-[4px] flex-wrap'
  >
    {value.map(item => {
      return <Chip
        key={item.id || item.label}
        onRemove={() => removeItem(item)}
      >{item.label}</Chip>
    })}
  </div>
}

