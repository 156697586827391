// @ts-ignore

import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, useEffect, useState } from 'react';
import { publish } from '@roc-digital/mxm-base/events';
import {
  ChangeEvent,
  CustomEvent,
  InputData,
  InputType,
  eyeClosedIcon,
  eyeOpenIcon,
  getClassNames,
  useClickEvent,
} from '@roc-digital/ui-lib';
import { Icon } from './Icon';

export type InputProps = {
  id?: string;
  type?: InputType;
  width?: 'auto' | 'full' | 'half' | 'third' | 'quarter';
  size?: 'x-small' | 'small' | 'medium' | 'large';
  name?: string;
  className?: string;
  inputContainerClassName?: string;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  changeEvent?: ChangeEvent | CustomEvent | ((value: string) => void);
  value?: string | number | undefined;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  ref?: any;
  autoFocus?: boolean;
  isSecureEntry?: boolean;
  refreshable?: boolean;
};

const styleClasses = {
  base: `disabled:opacity-75 disabled:text-opacity-50 disabled:placeholder:text-opacity-50 placeholder:text-admin-light text-black pr-10 font-workSans font-normal text-font inline-block rounded-lg border border-solid border-[#DEDEDE] dark:border-[#888888] text-font-on-secondary box-border focus:outline-none focus:border-font-secondary`,
  width: {
    auto: `w-auto`,
    full: `w-full`,
    half: `w-1/2`,
    third: `w-1/3`,
    quarter: `w-1/4`,
  },
  size: {
    ['x-small']: `px-1 py-[3px] text-sm`,
    small: `px-3 py-[6px] text-sm`,
    medium: `px-4 py-2`,
    large: `px-6 py-3`,
  },
};

export const Input = ({
  id,
  width,
  size = 'medium',
  className,
  inputContainerClassName,
  type,
  placeholder,
  value,
  changeEvent,
  onFocus,
  onBlur,
  onKeyUp,
  ref,
  autoFocus,
  isSecureEntry,
  disabled,
  refreshable = false,
}: InputProps) => {
  const [inputType, setInputType] = useState<string | undefined>(type);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (value === null || value === undefined || !value) {
      setInputValue('');
    }
    if (value && refreshable) {
      setInputValue(value);
    }
  }, [value, refreshable]);

  const publishChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(evt.target.value);
    if (!changeEvent) {
      return;
    }

    if(typeof changeEvent === 'function') {
      return changeEvent(evt.target.value)
    }

    if (changeEvent.allowDefault === false) {
      evt.preventDefault();
    }

    if (changeEvent.propagate === false) {
      evt.stopPropagation();
    }

    publish<InputData>(changeEvent.namespace, 'change', {
      id,
      eventData: changeEvent.data || {},
      value: evt.target.value,
      htmlEvent: evt,
    });
  };

  const eyeIconEvent = useClickEvent(
    () => {
      setInputType(inputType === 'password' ? 'text' : 'password');
    },
    {},
    {},
    [inputType]
  );

  const inputClassName = getClassNames(styleClasses, { width, size });

  className = `${inputClassName} ${className} dark:text-font-muted dark:bg-surface-dark-muted`;

  return (
    <div className={`flex flex-row items-center justify-center ${inputContainerClassName}`}>
      <input
        autoFocus={autoFocus}
        ref={ref}
        id={id}
        type={inputType}
        placeholder={placeholder}
        className={className}
        value={inputValue}
        onChange={publishChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        disabled={disabled}
      />
      {isSecureEntry && (
        <Icon
          className="right-0 mr-3 -ml-8 max-w-none"
          src={inputType === 'password' ? eyeClosedIcon : eyeOpenIcon}
          clickEvent={eyeIconEvent}
        />
      )}
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  size: 'medium',
};
