import { useArticlesQuery } from "@/logic/useArticlesQuery";
import { ArticleAction, ArticleContent } from "@roc-digital/ui-lib";
import { ZoneLayout } from "@roc-digital/ui-web";
import { ArticlesPage } from "./ArticlesPage";
import { Loading } from './Loading';
import React from "react";

export interface ArticleListByTagProps {
  tags: string[];
  count?: number;
  excludedArticles?: string[]
}

export function ArticleListByTag(props: ArticleListByTagProps) {
  const limit = (props.count || 20);
  const count = limit + (props.excludedArticles?.length || 0);
  const articlesQuery = useArticlesQuery(props.tags, count);

  const articles = React.useMemo(() => {
    const list = articlesQuery.result?.[0] || [];
    if(!list.length) return [];
    if(!props.excludedArticles?.length) return list;

    return list.filter(a => props.excludedArticles?.includes(a.id) === false);
  }, [articlesQuery.result, props.excludedArticles])

  return <>
    {articlesQuery.loading ? <Loading customClassName={'mb-8 ml-auto mr-auto'} /> : <></>}
    <ArticlesPage
      articles={articles}
      renderPage={renderPage}
    />
  </>
}

function renderPage(articles: ArticleContent[], onAction: (action: ArticleAction) => void) {
  return <ZoneLayout
    articles={articles}
    onAction={onAction}
    layoutId={16 as any}
    hideMeta
  />
}