import React, { useState } from 'react';
import { listArticlesForMeV2 } from '@roc-digital/mxm-base/logic';
import { useMethod } from '@roc-digital/ui-lib';

export function useArticlesQuery(filters: string[], count: number = 17) {
  const [activeFilters, setActiveFilters] = useState<string[]>(filters);
  const getArticles = React.useCallback(async (filters: string[], page: number, count: number) => {
    const result = await listArticlesForMeV2(filters, page, count);
    return [result];
  }, []);

  const articlesQuery = useMethod(getArticles, {
    map: (next, current) => {
      if (!current?.result?.length) {
        return next
      }

      if (next.args[0].join(',') !== current.args[0].join(',')) {
        return next;
      }

      return {
        args: next.args,
        result: [
          ...current.result,
          ...next.result
        ]
      };
    },
    defaultValue: []
  });

  React.useEffect(() => {
    void articlesQuery.debounce([activeFilters, 1, count], 100);
  }, [activeFilters]);

  const hasMore = articlesQuery.result?.length ?(articlesQuery.result[articlesQuery.result.length -1].length >= count ?  true : false) : true;

  const nextPage = () => {
    const args = articlesQuery.lastArgs || [[''], 1, count];
    args[1] = (args[1] || 1) + 1;
    return articlesQuery.run(...args);
  }

  return {
    loading: articlesQuery.loading,
    result: articlesQuery.result,
    call: articlesQuery.debounce,
    activeFilters,
    setActiveFilters,
    hasMore,
    loadNextPage: nextPage
  };
}