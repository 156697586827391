import React from 'react';
import { Button, ButtonSizes } from '@roc-digital/ui-web';

export interface PromoBoxesProps {
  signup: (plan: 'monthly' | 'annual') => void;
}

export function PromoBoxes(props: PromoBoxesProps) {
  return <div className='mx-auto font-chivo'>{/* signup form */}
    <div className='flex flex-wrap gap-4'>
      <div className='flex-1 rounded-lg bg-surface-primary shadow-xl p-6 text-white min-w-[300px]' style={{flexBasis: 'min-content'}}>
        <div className='text-center relative'>
          <div className='absolute w-full -top-[70px]'>'
            <div className='mx-auto w-[150px] p-2 bg-black rounded-full'>TOP CHOICE!</div>
          </div>
          <div className='text-md font-bold'>Monthly</div>
          <div className='font-bold text-4xl'>$2.49/week</div>
          <div className='font-bold text-lg'>$9.96/month</div>
        </div>
        <ul className='pt-4 text-lg'>
          <li className='flex'><div className='pr-1'>+</div><div className="text-left">Ad-free mainstream news without mainstream bias</div></li>
          <li className='flex'><div className='pr-1'>+</div><div className="text-left">Local news from YOUR community</div></li>
          <li className='flex'><div className='pr-1'>+</div><div className="text-left">Top podcasts, interviews, and viral must-see content, all in one place.</div></li>
        </ul>
        <div className='mt-6'>
          <Button size={ButtonSizes.small} customClassName="mt-auto bg-white w-full px-12 h-auto py-4" clickEvent={() => props.signup('monthly')}>
            <span className='text-black font-bold text-lg text-[#33ace1]'>GET Monthly</span>
          </Button>
        </div>
      </div>
      <div className='flex-1 rounded-lg bg-surface-primary shadow-xl p-6 text-white relative min-w-[300px] min-h-[350px]' style={{flexBasis: 'min-content'}}>
        <div className='text-center'>
          <div className='text-md font-bold'>Annual</div>
          <div className='font-bold text-4xl'>$1.86/week</div>
          <div className='font-bold text-lg'><span className='text-lg pr-2' style={{ textDecoration: "line-through" }}>$119.52</span>$89.64/year</div>
        </div>
        <ul className='pt-4 text-lg'>
          <li className='flex'><div className='pr-1'>+</div><div>Everything in monthly</div></li>
          <li className='flex'><div className='pr-1'>+</div><div>Plus 30% savings!</div></li>
          <li className='flex'><div className='pr-1'>+</div><div>Annual access</div></li>
        </ul>
        <div className='mt-6 absolute bottom-[24px] w-full pr-12'>
          <Button size={ButtonSizes.small} customClassName="mt-auto bg-white w-full px-12 h-auto py-4" clickEvent={() => props.signup('annual')}>
            <span className='text-black font-bold text-lg text-[#33ace1]'>GET Annual</span>
          </Button>
        </div>
      </div>
    </div>

  </div>
}