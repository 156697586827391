import { useEffect, useState } from 'react';
import { Tags, Tag } from '@roc-digital/mxm-base/data';
import { Event } from '@roc-digital/mxm-base/types';
import { deleteTags, followTag, listTags, myTags } from '@roc-digital/mxm-base/logic';
import { useStateEvent, useClickEvent, InputData, useCustomEvent } from '@roc-digital/ui-lib';
import { Badge } from '@roc-digital/ui-web';
import { CacheState } from '@roc-digital/mxm-base/state';
import { Loading } from '@/components/elements/Loading';

const Interests = () => {
  const [selectedTags, setSelectedTags] = useState<Tags>();
  const [loading, setLoading] = useState(false);

  const [tags] = useStateEvent<Tags>('mxm.http.tags', 'listTags.success', undefined, ({ data }: Event<Tags>) =>
    data.filter((tag) => tag.type === 'category')
  );

  const [myFavoriteTags] = useStateEvent<Tags>(
    'mxm.http.tags',
    'myTags.success',
    undefined,
    ({ data }: Event<Tags>) => {
      setLoading(false);
      setSelectedTags(data);
    }
  );

  useCustomEvent(
    'myTags.failed',
    () => {
      setLoading(false);
    },
    { namespace: 'mxm.http.tags' }
  );

  useEffect(() => {
    setLoading(true);
    listTags();
    myTags();
  }, []);

  const tagsList = tags?.toArray() || [];

  const onClickTag = useClickEvent(
    ({ data }: Event<InputData>) => {
      if (myFavoriteTags?.map((tag) => tag.id).includes(data.id as string)) {
        deleteTags(data.id as string);
        return;
      }

      followTag(CacheState, data.id as string);
    },
    undefined,
    undefined,
    [myFavoriteTags]
  );

  useCustomEvent(
    'followTag.success',
    ({ data }: Event<{ id: string }>) => {
      const id = data?.id || '';

      const selectedTag = tags?.find((t) => t.id === id);

      setSelectedTags((prev) => {
        if (prev && selectedTag) {
          return new Tags([...prev.toArray(), selectedTag], Tag);
        }

        if (selectedTag) {
          return new Tags([selectedTag], Tag);
        }

        return prev;
      });
    },
    { namespace: 'mxm.http.tags' },
    {},
    [selectedTags, tags]
  );

  useCustomEvent(
    'deleteTags.success',
    ({ data }: Event<{ id: string }>) => {
      const id = data?.id || '';

      const selectedTag = tags?.find((t) => t.id === id);

      setSelectedTags((prev) => {
        if (prev && selectedTag) {
          return new Tags(
            prev.toArray().filter((t) => t.id !== id),
            Tag
          );
        }

        return prev;
      });
    },
    { namespace: 'mxm.http.tags' },
    [selectedTags, tags]
  );

  return (<>
    <div className="text-xl font-bold mb-4 font-chivo dark:text-white">Get content that interests you!</div>
    <div style={{maxHeight: '100%', overflowY: 'auto', paddingRight: 4}}>
      {loading && <Loading customClassName={'mr-auto ml-auto'} />}
      {tagsList.map((tag) => (
        <Badge
          id={tag.id}
          customClassName="mb-2"
          key={tag.id}
          image={tag.image_url}
          title={tag.name}
          clickEvent={onClickTag}
          selected={selectedTags?.map((tag) => tag.id).includes(tag.id as string)}
        />
      ))}
    </div>
  </>
  );
};

Interests.displayName = 'Interests';

export default Interests;
