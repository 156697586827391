import { useEffect, useMemo } from 'react';
import { ArticleSchema } from '@roc-digital/mxm-base/data';
import { useMethod, mapToArticleContent, getArticleActionHandler } from '@roc-digital/ui-lib';
import { ZoneLayout } from '@roc-digital/ui-web';
import { handleVote, handleBookmark, listArticlesByScopesV2 } from '@roc-digital/mxm-base/logic';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { shareArticle } from '@/logic/shareArticle';

type Props = {
  tags?: any;
  articleId?: string;
};

const RelatedArticles = (props: Props) => {
    const navigate = useNavigate();
    const layoutId = 8;
    const articlesQuery = useMethod(listArticlesByScopesV2);
  
    const onAction = useMemo(() => {
      return getArticleActionHandler({
        get: articlesQuery.getLastResult,
        set: articlesQuery.setResult,
        navigate: async (article) => navigateAndScroll(article.id, createSlug(article.title)),
        bookmark: async (article) => {
          try {
            const result = await handleBookmark(article);
            if(result.bookmarked) {
              toast.success('Article bookmarked');
            } else {
              toast.success('Bookmark removed');
            }
            return result;
          } catch(err) {
            console.error(err);
            toast.error('Failed to bookmark article')
            return article;
          }
        },
        vote: (article, vote) =>  handleVote(article.id, vote),
        share: (article) => openShareModal(article)
      });
    }, []);

    const createSlug = (title: string) => {
      const lowercaseText = title.toLowerCase();
      const formattedText = lowercaseText.replace(/[^a-z0-9]+/g, '-');
      const slug = formattedText.replace(/^-+|-+$/g, '');
      return slug;
    };

    const navigateAndScroll = (id: string, slug?: string) => {
      navigate(`/article/${id}${slug ? '?' + slug : null}`);
      window.scrollTo(0, 0);
    }

    const openShareModal = (article: ArticleSchema) => {
      shareArticle(article);
    };

    const articles = useMemo(() => {
      if(!articlesQuery.result) return [];
      const articlesList = articlesQuery.result.map(a => mapToArticleContent(a, {}));
      return articlesList.filter((a) => (a.id !== props.articleId));
    }, [articlesQuery.result]);
  
    const categoriesAndPublications = (tags: any) => {
      const scopes: string[] = [];
      if (tags) {
        tags.forEach((tag: { type: string; scope: string; }) => {
          if (tag.type === 'publication' || tag.type === 'category') {
            scopes.push(tag.scope as string)
          }
        });
      } 
      console.log('Scopes', scopes);
      return scopes;
    }

    useEffect(() => {
      const scopes = categoriesAndPublications(props.tags); //props.tags?.map((z: { scope: any; }) => z.scope) as string[] || [];
      articlesQuery.run(scopes, 1, 9);
    }, []);
  
    return (
      <div className="w-full flex flex-col max-w-5xl mx-auto px-2">
        {articles && (
          <ZoneLayout
            layoutId={layoutId as any}
            articles={articles}
            onAction={onAction}
          />
        )}
      </div>
    );
};

export default RelatedArticles;
