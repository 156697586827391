import { usePayWall } from './usePaywall';

/**
 * Apples the 'pw-active' class to the body element when the paywall is active.
 */
export function usePaywallRootCssClass() {
  try {

    if (typeof document !== 'object') return;

    const paywall = usePayWall();
    if(paywall && document.body.classList.contains('pw-active') == false) {
      document.body.classList.add('pw-active');
    } else if(!paywall && document.body.classList.contains('pw-active') == true) {
      document.body.classList.remove('pw-active');
    }
  } catch(err) {}
}