import { Heading3 } from "@roc-digital/ui-web";
import GetAppButtonGroup from "./GetAppButtonGroup";
import { isMobile } from 'react-device-detect';
import { useEffect, useRef, useState } from "react";

interface MobileSnackbarProps {
  dark?: boolean;
}

const MobileSnackbar = ({ dark }: MobileSnackbarProps) => {
  const [snackbar, setSnackbar] = useState(false);
  const imageRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => { 
    if(isMobile) {
      showMobileSnackbar();
    }
  });

  const showMobileSnackbar = () => {
    setTimeout(function(){
      setSnackbar(true);
      handleFadeIn();
      console.log("Show snackbar after 3 seconds");
    }, 1000);
  };

  const hideSnackbar = () => {
    if (imageRef.current) {
      imageRef.current.classList.remove('easeLoadedImage');
    }
    // setSnackbar(false);
  };

  const handleFadeIn = () => {
    if (imageRef.current) {
      imageRef.current.classList.add('easeLoadedImage');
    }
  };

  return (
    snackbar ? 
    <div className="fixed bottom-0 h-32 w-full easeloadSlide" ref={imageRef}>
      <div className="bg-white h-40 w-[320px] mx-auto rounded-t-lg shadow-2xl px-6 py-4">
      <Heading3 className="relative text-[#1E222A] !font-extrabold !text-[28px] !leading-9  !tracking-tight !font-chivo dark:text-white">
        Get the MXM App!
        <img src="/icons/close.svg" className="absolute h-6 w-6 -right-9 top-1 cursor-pointer" title="Close dialogue" onClick={() => hideSnackbar()}/>
      </Heading3>
        <div className="mt-4">
          <GetAppButtonGroup />
        </div>
      </div>
    </div> : null
  );
};

MobileSnackbar.displayName = 'Mobile Snackbar';

export default MobileSnackbar;
