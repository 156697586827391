import { closeIcon } from "@roc-digital/ui-lib";
import { Icon } from "./Icon";

export interface RoundCloseButtonProps {
  topRightOffset?: string;
  onClick?: () => void;
}

export function RoundCloseButton(props: RoundCloseButtonProps) {
  const offset = props.topRightOffset || '24px';

  return <button
    className='icon'
    style={{position: 'absolute', top: offset, right: offset}}
    onClick={props.onClick}
  >
    <Icon src={closeIcon}/>
  </button>
}