import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { listArticles, listHomeArticles } from '@roc-digital/mxm-base/logic';
import { Articles } from '@roc-digital/mxm-base/data';
import { Button } from '@roc-digital/ui-web';
import { useStateEvent, useCustomEvent, useClickEvent } from '@roc-digital/ui-lib';
import { formatDistanceToNow, fromUnixTime } from 'date-fns';
import deviceLayoutsImage from '../../../assets/device_layoutsOLD.png';
import mapAreaImage from '../../../assets/map_area.png';
import GetAppButtonGroup from '@/components/elements/GetAppButtonGroup';
import AppFooter from '@/components/elements/AppFooter';
import ArticleCard from '@/components/elements/ArticleCard';
import NewsletterBanner from '@/components/elements/NewsletterBanner';
import HomeTitle from '@/components/elements/HomeTitle';
import HomeSignup from '@/components/elements/HomeSignup';
function timePassedSince(timestamp: number): string {
  const dateFromTimestamp = fromUnixTime(timestamp);

  return formatDistanceToNow(dateFromTimestamp) + ' ago';
}

const Home = memo(({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const [allArticles] = useStateEvent<Articles>('mxm.http.articles', 'listHomeArticles.success', undefined);

  const navigate = useNavigate();

  useEffect(() => {
    void listHomeArticles({}, 'publication:mxm-news');
  }, []);

  useCustomEvent(
    'signin.success',
    () => {
      listArticles(1, 12, { page: 5, count: 20 });
    },
    { namespace: 'mxm.auth' }
  );

  useCustomEvent(
    'signin.success.anonymous',
    () => {
      listArticles(1, 12, { page: 5, count: 20 });
    },
    { namespace: 'mxm.auth' }
  );

  const onClickStartReading = useClickEvent(() => {
    navigate('/trending');
  });

  const getLogoName = (newTags: any) => {
    let logo = '';
    if (newTags && newTags.length > 0) {
      logo =
      newTags.find((tag: any) => tag.image_url !== undefined && tag.image_url !== '' && tag.image_url !== null)
          ?.image_url || '';
    }
    return logo || '/icons/article.svg';
  };

  const getArticleSize = (position: number) => {
    switch(position) {
      case 0:
        return 'small-article-card'
      case 1:
        return 'medium-article-card'
      case 2:
        return 'large-article-card'
      case 3:
        return 'small-article-card'
      case 4:
        return 'medium-article-card'
      case 5:
        return 'large-article-card'
      case 6:
        return 'large-article-card'
      default:
        return 'small-article-card'
    }
  };

  return (
    <div className="h-full flex flex-col justify-center bg-gray-100 font-chivo overflow-x-hidden">
      <div
        className="relative flex flex-col items-center justify-center pt-32 pb-10"
        style={{ background: 'linear-gradient(180deg, #FFFFFF 34.89%, #DBF5FA 100%)' }}
      >
        <HomeTitle />
        <div className="w-80">
          <Button customClassName="home-start w-auto px-12 h-auto mb-12 py-4" clickEvent={onClickStartReading}>
            Start Reading
          </Button>
        </div>
        <GetAppButtonGroup />
        {allArticles?.map((article, i) => (
          i ? (i < 7 &&
          <ArticleCard
            key={article.id}
            id={article.id}
            easeload={`easeload${i}`}
            position={`position-${i-1}`}
            size={getArticleSize(i-1)}
            title={article.title}
            image={article.image_url}
            tag={article.getTags().first()?.tag}
            date={timePassedSince(article?.date || 0)}
            logo={getLogoName(article.getTags())}
            url={article.url}
          />) : ''
        ))}
      </div>
      <div className="relative flex flex-col items-center justify-center pt-36 bg-font text-white">
        <h2 className="text-7xl font-bold w-96 text-center">Relevant & Unbiased</h2>
        <div className="text-base mt-8 max-w-sm text-center">
          Read top uncensored stories and breaking news from trusted sources.
        </div>
        <div className="flex justify-center mt-12">
          <img src={deviceLayoutsImage} alt="Device layouts for MXM"/>
        </div>
      </div>
      <div className="flex flex-col items-center py-40">
        <div className="flex justify-center gap-24 max-w-3xl sm:flex-wrap">
          <div className="w-full relative">
            <span className="">
              <div className="absolute bg-white p-3 rounded-full -left-20 top-8 drop-shadow-xl">
                <img src="/icons/finance_money.svg" className="w-5 h-5 inline mr-2" alt="Finance icon"/>
                Finance & Money
              </div>
              <div className="absolute bg-white p-3 rounded-full left-24 -top-8 drop-shadow-xl">
                <img src="/icons/life_culture.svg" className="w-5 h-5 inline mr-2" alt="Life and culture icon"/>
                Life & Culture
              </div>
              <div className="absolute bg-white p-3 rounded-full -left-12 top-40 drop-shadow-xl">
                <img src="/icons/america_first.svg" className="w-5 h-5 inline mr-2" alt="America first icon"/>
                America First
              </div>
              <div className="absolute bg-white p-3 rounded-full -right-12 top-24 drop-shadow-xl">
                <img src="/icons/policy.svg" className="w-5 h-5 inline mr-2" alt="Policy icon"/>
                Policy
              </div>
              <div className="absolute bg-white p-3 rounded-full -right-12 top-60 drop-shadow-xl">
                <img src="/icons/politics.svg" className="w-5 h-5 inline mr-2" alt="Politics icon"/>
                Politics
              </div>
              <div className="absolute bg-white p-3 rounded-full left-20 bottom-12 drop-shadow-xl">
                <img src="/icons/international.svg" className="w-5 h-5 inline mr-2" alt="International icon"/>
                International
              </div>
              <img src={mapAreaImage} alt="Image of a map"/>
            </span>
          </div>
          <div className="w-full">
            <div className="font-bold text-7xl text-font-high sm:px-2 md:px-2">
              Stories that Matter to <i>You</i>
            </div>
            <div className="text-base mt-10 sm:pr-5 sm:pl-5">
              Tailor your feed to highlight local news and personal interests.
            </div>
          </div>
        </div>
        <NewsletterBanner />
        <div className="text-5xl mt-40 max-w-3xl text-center">
          “MxM News delivers the stories you need to see each day without the bias or censorship”
        </div>
        <div className="mt-4 rounded-full w-20 h-20">
          <img src={'/photos/donjr.png'} alt="Don Trump Jr" />
        </div>
        <div className="mt-4">
          <span className="font-bold text-lg">Donald Trump Jr.</span>
          <span className="text-lg">, Co-Founder</span>
        </div>
        <div className="w-80">
          <Button customClassName="mt-16 w-auto px-12 h-auto py-4" clickEvent={onClickStartReading}>
            Start Reading
          </Button>
        </div>
      </div>
      <HomeSignup onLogin={v => alert('New Login')} mode='login'/>
      <AppFooter />
    </div>
  );
});

Home.displayName = 'Home';

export default Home;
