import { EventCallback, ChangeEvent, EventOptions } from '../types';
import { Data } from '@roc-digital/mxm-base/types';
import { useCustomEvent } from './useCustomEvent';

// The useChangeEvent hook allows listening for component change events.
export function useChangeEvent<T>(
  fn: EventCallback<T>,
  opts: EventOptions = {},
  data?: Data,
  deps: any[] = []
): ChangeEvent {
  return useCustomEvent<T>('change', fn, opts, data, deps);
}
