"use strict";
/* eslint-disable import/no-cycle */
// @copyright 2023 MXM, Inc
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CacheState = void 0;
var config_1 = require("../config");
var logging_1 = require("../logic/logging");
var STORAGE = null;
function setItem(key, value) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/];
        });
    });
}
function getItem(key) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, null];
        });
    });
}
function removeItem(key) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/];
        });
    });
}
var CacheState = /** @class */ (function () {
    function CacheState() {
    }
    CacheState.init = function (newStorage) {
        if (STORAGE) {
            return;
        }
        STORAGE = newStorage;
    };
    CacheState.has = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            var exists, item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Cache.has]', [key]);
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][").concat(key, "]"))];
                    case 1:
                        exists = _a.sent();
                        if (exists) {
                            item = JSON.parse(exists);
                            if (item && item.timestamp + item.expires > Date.now()) {
                                (0, logging_1.log)('[Cache.has] Item exists', [item]);
                                return [2 /*return*/, item.data];
                            }
                        }
                        return [2 /*return*/, false];
                }
            });
        });
    };
    CacheState.get = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            var item, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        (0, logging_1.log)('[Cache.get]', [key]);
                        _b = (_a = JSON).parse;
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][").concat(key, "]"))];
                    case 1:
                        item = _b.apply(_a, [(_c.sent()) || 'null']);
                        if (item && item.timestamp + item.expires > Date.now()) {
                            (0, logging_1.log)('[Cache.get] Found item', [item]);
                            return [2 /*return*/, item.data];
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    };
    CacheState.set = function (key, value, expires) {
        return __awaiter(this, void 0, void 0, function () {
            var keys, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        (0, logging_1.log)('[Cache.set]', [key, value, expires]);
                        _b = (_a = JSON).parse;
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%KEYS%]"))];
                    case 1:
                        keys = _b.apply(_a, [(_c.sent()) || '[]']);
                        if (!!keys.includes(key)) return [3 /*break*/, 3];
                        (0, logging_1.log)('[Cache.set] Adding key', [key, keys]);
                        keys.push(key);
                        return [4 /*yield*/, setItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%KEYS%]"), JSON.stringify(keys))];
                    case 2:
                        _c.sent();
                        _c.label = 3;
                    case 3: return [4 /*yield*/, setItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][").concat(key, "]"), JSON.stringify({
                            timestamp: Date.now(),
                            expires: expires || 0,
                            data: value,
                        }))];
                    case 4:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CacheState.remove = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, logging_1.log)('[Cache.remove]', [key]);
                        return [4 /*yield*/, removeItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][").concat(key, "]"))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CacheState.clearExpired = function () {
        return __awaiter(this, void 0, void 0, function () {
            var keys, _a, _b, newKeys, removed, i, value, item;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        (0, logging_1.log)('[Cache.clearExpired]', []);
                        _b = (_a = JSON).parse;
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%KEYS%]"))];
                    case 1:
                        keys = _b.apply(_a, [(_c.sent()) || '[]']);
                        newKeys = [];
                        removed = 0;
                        if (!keys) return [3 /*break*/, 9];
                        i = 0;
                        _c.label = 2;
                    case 2:
                        if (!(i < keys.length)) return [3 /*break*/, 7];
                        return [4 /*yield*/, getItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][").concat(keys[i], "]"))];
                    case 3:
                        value = _c.sent();
                        if (!value) return [3 /*break*/, 6];
                        item = JSON.parse(value);
                        if (!(!item || item.timestamp + item.expires < Date.now())) return [3 /*break*/, 5];
                        // eslint-disable-next-line no-await-in-loop
                        return [4 /*yield*/, removeItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][").concat(keys[i], "]"))];
                    case 4:
                        // eslint-disable-next-line no-await-in-loop
                        _c.sent();
                        removed++;
                        return [3 /*break*/, 6];
                    case 5:
                        newKeys.push(keys[i]);
                        _c.label = 6;
                    case 6:
                        i++;
                        return [3 /*break*/, 2];
                    case 7: return [4 /*yield*/, setItem("mxm[".concat((0, config_1.getConfig)().appVersion, "][%KEYS%]"), JSON.stringify(newKeys))];
                    case 8:
                        _c.sent();
                        _c.label = 9;
                    case 9:
                        if (removed > 0) {
                            (0, logging_1.log)("[Cache.clearExpired] Cleared ".concat(removed, " items"));
                        }
                        return [2 /*return*/, removed];
                }
            });
        });
    };
    return CacheState;
}());
exports.CacheState = CacheState;
