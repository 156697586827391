import { memo } from 'react';
import AppFooter from '@/components/elements/AppFooter';

const Careers = memo(() => {
  return (
    <div className="h-full flex flex-col justify-center bg-gray-100 font-chivo overflow-x-hidden dark:bg-surface-dark">
      <div className="p-5 mt-10 mb-20 mx-auto max-w-5xl">
        <h1 className={'text-3xl  dark:text-white'}>Careers</h1>
        <div className={'pt-4 dark:text-white'}>
          MxM News is growing! We are looking for the best and brightest to join our team. Please explore our
          opportunities below.
        </div>
        <ul className={'pt-4 ml-4 dark:text-white'}>
          <li>• Content Manager</li>
        </ul>
      </div>
      <AppFooter />
    </div>
  );
});

Careers.displayName = 'Terms and Conditions';

export default Careers;
