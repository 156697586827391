import { t } from "@roc-digital/mxm-base/src/logic";

export function createArticleUnlockUrl(articleId: string, userId: string) {
  const pat = createArticleUnlockToken(articleId, userId);
  const url = location.origin + '/article/'+articleId+"?pat="+pat;
  return url;
}

export function checkUrlForArticleUnlockToken() {
  try {
    const url = new URL(window.location.toString());
    const pat = url.searchParams.get('pat');
    const articleId = url.pathname.split('/').pop();
    if(!articleId || !pat) return;
  
    if(verifyArticleUnlockToken(articleId, pat) !== true) return;

    localStorage.setItem('PAT:'+articleId, pat);
  } catch(err) {
    console.error('PAT Error');
    console.error(err);
  }
}

let calcCache = {} as any;
export function isArticleUnlocked(articleId: string) {
  if(calcCache[articleId] !== undefined) return calcCache[articleId];

  const pat = localStorage.getItem('PAT:'+articleId);
  if(!pat) return false;
  calcCache[articleId] = verifyArticleUnlockToken(articleId, pat);

  return calcCache[articleId];
}

export function createArticleUnlockToken(articleId: string, userId: string) {
  let key = articleId + '-' + articleId;
  let id = (Math.random() * 1000).toFixed(0)
  let data = id+':'+userId+':1';
  
  const paddedData = data + key.slice(key.length - data.length * -1);
  let token = btoa(xorStrings(key, paddedData));
  return token;
}

export function verifyArticleUnlockToken(articleId: string, token: string) {
  let key = articleId + '-' + articleId;
  const payload = xorStrings(key, atob(token));
  const [id, userId, n] = payload.split(':');
  return id && userId && n === '1' ? true : false;
}

function xorStrings(str1: string, str2: string) {
  let result = "";

  // Ensure strings are of equal length
  const length = Math.min(str1.length, str2.length);

  for (let i = 0; i < length; i++) {
    // Get the character codes for each character
    const charCode1 = str1.charCodeAt(i);
    const charCode2 = str2.charCodeAt(i);

    // Perform XOR operation on character codes
    const xorCharCode = charCode1 ^ charCode2;

    // Convert back to character and append to result
    result += String.fromCharCode(xorCharCode);
  }

  return result;
}

function hex(str: string) {
  return btoa(str);
}

function dehex(str: string) {
  return atob(str);
}
