import { useEffect, useRef } from "react";

const HomeTitle = () => {
  const divRef2 = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    if (divRef2.current) {
        divRef2.current.classList.add('easeLoadedImage');
    }
  }, []);
  
  return (
    <h1 ref={divRef2} className="easeloadSlide text-9xl font-bold max-w-lg text-center text-font-high home-title">
        News<br />Without<span className="block text-4xl max-h-[40px]">Mainstream</span>Bias
    </h1>
  );
};

export default HomeTitle;
