import { Text, TextProps } from '../Text';
import { ArticleImage } from './ArticleImage';
import './ArticleHeadline.scss';

export interface ArticleHeadlineProps {
  size?: 'lg' | 'md' | 'sm';
  headline: string;
  image?: string;
  blurhash?: string;
  onClick?: () => void;
  children?: any;
  href?: string;
}

export function ArticleHeadline(props: ArticleHeadlineProps) {
  const InnerContent = <><h3 className='article-headline flex-col flex-grow min-h-[0px] font-pragati !font-bold dark:text-white'
  style={{
    whiteSpace: 'normal'
  }}>
    {truncateHeadline(props.headline)}
    {props.children}
  </h3>
  {props.image ? <>
    <ArticleImage
      blurhash={props.blurhash}
      height={80}
      className={`bg-gray-20 w-[80px] h-[80px] min-w-[80px] min-h-[80px] max-w-[80px] max-h-[80px]`}
      src={props.image}
      mini
      onClick={props.onClick}
    />
  </> : null}</>; 
  

  return props.href ? <a href={props.href} className='flex flex-row gap-3 flex-grow cursor-pointer min-h-[0px]' 
      onClick={(event) => {
        event.preventDefault();
        props.onClick ? props.onClick() : null;
      }}>
      {InnerContent}
    </a> 
  :
    <div className='flex flex-row gap-3 flex-grow cursor-pointer min-h-[0px]' 
      onClick={(event) => {
        event.preventDefault();
        props.onClick ? props.onClick() : null;
      }}>
      {InnerContent}
    </div>
  ;
}

export const ArticleTitle = ({ children, className, ...props }: TextProps) => (
  <Text {...props} className={`font-pragati !font-bold ${className} dark:text-white`}>
    {children}
  </Text>
);

const MAX_CHARS = 110;

function truncateHeadline(content: string) {
  if(content.length < MAX_CHARS) return content;


  for(let i = MAX_CHARS; i >=0; i--) {
    if(content[i] === ' ') return content.slice(0, i) + '...';
  }

  return content.slice(0, MAX_CHARS) + '...';
}