import UserFollowing from '@/components/elements/Following';

const Following = () => {
  return (
    <div className="flex flex-col max-w-2xl w-full text-sm dark:text-white">
      <div className="text-2xl font-bold mb-6">Follow Public Figures</div>
      <div className="max-w-md text-font-low mb-3">Follow Public Figures and see what news matters to them.</div>
      <UserFollowing categorized />
    </div>
  );
};

Following.displayName = 'Following';

export default Following;
