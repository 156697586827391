import { useMemo } from 'react';
import { TagSchema } from '@roc-digital/mxm-base/data';
import { deleteTags, followTag, listMyTagsV2, listTagsV2 } from '@roc-digital/mxm-base/logic';
import { useMethod } from '@roc-digital/ui-lib';
import { Badge } from '@roc-digital/ui-web';
import { Loading } from '@/components/elements/Loading';

export interface MyTagsSelectorProps {
  type: string;
  filter?: (tag: TagSchema) => boolean;
}

export function MyTagsSelector(props: MyTagsSelectorProps) {
  const myTagsQuery = useMethod(listMyTagsV2, {autoRun: ['location'], defaultValue: []});
  const allTagsQuery = useMethod(listTagsV2 , {autoRun: [props.type], defaultValue: []});
  const loading = myTagsQuery.loading || allTagsQuery.loading;

  const selectedTags = useMemo(() => {
    let map: {[id: string]: boolean} = {};
    if(!myTagsQuery.result) {
      return map;
    }
    myTagsQuery.result.forEach(t => map[t.id as string] = true);

    return map;
  }, [myTagsQuery.result]);

  const tags = useMemo(() => {

    if(!allTagsQuery.result) return [];

    if(!props.filter) {
      return allTagsQuery.result;
    }

    return allTagsQuery.result?.filter(props.filter);
  }, [props.filter, allTagsQuery.result])


  const toggleTag = (tag: TagSchema) => {
    if(selectedTags[tag.id as string]) {
      deleteTags(tag.id as string).catch(e => console.error(e));
      myTagsQuery.setResult(myTagsQuery.getLastResult().filter(t => t.id !== tag.id))
    } else {
      followTag(null as any, tag.id as string).catch(e => console.error(e));
      const next = [...myTagsQuery.getLastResult(), tag];
      myTagsQuery.setResult(next.filter((a, i, l) => l.findIndex((tag) => tag.id === a.id) === i));
    }
  }

  return (
    <div style={{maxHeight: '100%', overflowY: 'auto', paddingRight: 4, flex: 1, flexGrow: 1,}}>
      {loading && <Loading customClassName={'mr-auto ml-auto'} />}
      {tags?.map((tag) => (
        <Badge
          id={tag.id}
          customClassName="mb-2"
          key={tag.id}
          image={tag.image_url}
          title={tag.name}
          clickEvent={() => toggleTag(tag)}
          selected={selectedTags[tag.id as string]}
        />
      ))}
    </div>
  );
}