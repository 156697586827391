import React, { useState } from 'react';
import { updateUser } from '@roc-digital/mxm-base/logic';
import { Input } from '@roc-digital/ui-web';
import { SessionState } from '@roc-digital/mxm-base/state';

interface CollectPhoneNumberProps {
  confirmSMS: (value: any) => void;
  startingSMSValue?: any;
}

const CollectPhoneNumber = ({confirmSMS, startingSMSValue}: CollectPhoneNumberProps) => {
  const [phone, setPhone] = React.useState('');
  const [confirmCheckbox, setConfirm] = useState(startingSMSValue || false);
  const phoneRef = React.useRef(phone);
  phoneRef.current = phone;

  console.log('Starting Value', startingSMSValue);

  const handleChange = (event: any) => {
    if (confirmCheckbox === false) {
      setConfirm(true);
      confirmSMS(true);
    } else {
      setConfirm(false);
      confirmSMS(false);
    }
  };

  React.useEffect(() => {
    return () => {
      // Will save the phone number when the component unmounts;
      // yes, it's a little unorthodox.
      const value = phoneRef.current;
      if (confirmCheckbox) {
        updateUser(SessionState, {phone: value || null})
        .catch(e => console.error(e));
      } else {
        updateUser(SessionState, {phone: null})
        .catch(e => console.error(e));
      }
    }
  }, [confirmCheckbox])

  return (<>
    <div className="text-xl font-bold mb-4 font-chivo dark:text-white">Enter your phone number if you would like to receive SMS alerts.</div>
    <div style={{maxHeight: '100%', overflowY: 'auto', paddingRight: 4}}>
        <Input
          type="text"
          value={phone}
          changeEvent={setPhone}
          placeholder="Phone Number"
          className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-worksans !text-base"
        />
        <label className='flex'>
          <input checked={confirmCheckbox} type="checkbox" onChange={handleChange} className='mr-2'/>
          <span>I agree to receive text messages.*</span>
        </label>
        <div className='mt-4'>By entering your phone number and selecting to opt in, you consent to join a recurring SMS/MMS text messaging program that will provide alerts, updates, and other important information. By participating, you agree to the terms &amp; privacy policy for auto dialed messages to the phone number you provide. Msg &amp; data rates may apply. SMS information is not rented, sold, or shared. apply. Text JOIN to 21939 to opt in. Reply HELP for help
        or STOP to opt-out at any time. SMS information is not rented, sold, or shared. View <a href="/privacy" target="_blank" className='underline'>Privacy Policy</a> and <a href="/terms" target="_blank" className='underline'>Terms &amp; Conditions</a></div>
    </div>
  </>
  );
};

CollectPhoneNumber.displayName = 'CollectPhoneNumber';

export default CollectPhoneNumber;
