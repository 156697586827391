import React from "react";

export interface InfiniteScrollMarkerProps {
  onLoad: () => Promise<void>;
  hasMore: boolean;
  className?: string;
  children?: any;
}

export function InfiniteScrollMarker(props: InfiniteScrollMarkerProps) {
  const ref = React.useRef<HTMLDivElement>();
  const propR = React.useRef(props);
  propR.current = props;

  React.useLayoutEffect(() => {
    if(!ref.current) return;

    let loading = false;
    let start = 0;

    let observer = new IntersectionObserver(([event]) => {
      if(!start) {
        start =  event.time;
      }
      const time = event.time - start;

      if( time < 2000
          || propR.current.hasMore !== true
          || loading
          || event.isIntersecting !== true
      ) {
        return;
      }

      loading = true;
      propR.current.onLoad()
      .catch(e => console.error(e))
      .finally(() => loading = false);
    }, {
      rootMargin: '0px',
      threshold: .1
    });


    observer.observe(ref.current);

    return () => {
      console.log('DESTROY');
      observer.disconnect();
    }
  }, [ref.current]);

  return <div
    ref={ref as any}
    className={props.className}
  >
    {props.children}
  </div>;
}

