import { Badge, BadgeSizes } from '../Badge';
import { Image } from '../Image';
import { BodyDark } from '../Typefaces';

export interface MetaBarProps {
  logo?: string;
  timeLapse?: string;
  tag?: string;
}

export function MetaBar(props: MetaBarProps) {
  return <div className='flex flex-row items-center gap-3 flex-wrap max-h-[20px] overflow-hidden' style={{flexShrink: 0}}>
    {props.logo && <Image className='!h-[30px] !w-auto' src={props.logo} style={{ maxWidth: '65px', maxHeight: '12px' }} />}
    {props.timeLapse ? <BodyDark className='text-gray-400 text-xs dark:text-white'>{props.timeLapse}</BodyDark> : null}
    <div className="grow"></div>
    {props.tag && (
      <Badge
        type="web"
        size={BadgeSizes.xsmall}
        customClassName={'bg-badge rounded-full capitalize dark:bg-surface-dark-muted'}
        title={props.tag}
      />
    )}
  </div>
}
