"use strict";
/* eslint-disable import/no-cycle */
Object.defineProperty(exports, "__esModule", { value: true });
exports.unsubscribe = exports.resubscribe = exports.subscribe = exports.publish = exports.Event = void 0;
// @copyright 2023 MXM, Inc
var logging_1 = require("./logic/logging");
var SUBSCRIBERS = {};
var Event = /** @class */ (function () {
    function Event(name, data, timestamp, key) {
        this.name = name;
        this.data = data;
        this.timestamp = timestamp;
        this.key = key;
    }
    return Event;
}());
exports.Event = Event;
function publish(namespace, event, data, delay) {
    (0, logging_1.log)('[publish]', [namespace, event, data, delay]);
    var resolveSubscribers = function () {
        var subscriber = null;
        if (typeof SUBSCRIBERS[namespace] !== 'undefined' && typeof SUBSCRIBERS[namespace][event] !== 'undefined') {
            (0, logging_1.log)("[publish] Found ".concat(SUBSCRIBERS[namespace][event].length, " subscriber").concat(SUBSCRIBERS[namespace][event].length > 1 ? 's' : ''), [namespace, event]);
            for (var i = 0; i < SUBSCRIBERS[namespace][event].length; i++) {
                subscriber = SUBSCRIBERS[namespace][event][i];
                (0, logging_1.log)("[publish] Triggering subscriber ".concat(i + 1), [subscriber, data]);
                subscriber.callback(new Event(event, data, subscriber.timestamp, subscriber.key));
            }
        }
        if (typeof SUBSCRIBERS[namespace] !== 'undefined' && typeof SUBSCRIBERS[namespace]['*'] !== 'undefined') {
            (0, logging_1.log)("[publish] Found ".concat(SUBSCRIBERS[namespace]['*'].length, " subscriber").concat(SUBSCRIBERS[namespace]['*'].length > 1 ? 's' : '', " (*)"), [namespace, event]);
            for (var i = 0; i < SUBSCRIBERS[namespace]['*'].length; i++) {
                subscriber = SUBSCRIBERS[namespace]['*'][i];
                (0, logging_1.log)("[publish] Triggering subscriber ".concat(i + 1, " (*)"), [subscriber, data]);
                subscriber.callback(new Event(event, data, subscriber.timestamp, subscriber.key));
            }
        }
    };
    if (delay) {
        (0, logging_1.log)("[publish] Event delayed ".concat(delay, "ms"));
        setTimeout(resolveSubscribers, delay);
    }
    else {
        resolveSubscribers();
    }
}
exports.publish = publish;
/**
 * Subscribes to an event and returns a unique subscriber key.
 *
 * The callback receives the event data as the first argument, and the event name as the second.
 */
function subscribe(namespace, event, callback) {
    (0, logging_1.log)('[subscribe]', [namespace, event, callback]);
    if (typeof SUBSCRIBERS[namespace] === 'undefined') {
        SUBSCRIBERS[namespace] = {};
    }
    if (typeof SUBSCRIBERS[namespace][event] === 'undefined') {
        SUBSCRIBERS[namespace][event] = [];
    }
    var key = Math.random().toString(36).substring(2, 9);
    SUBSCRIBERS[namespace][event].push({ callback: callback, timestamp: new Date(), key: key });
    return key;
}
exports.subscribe = subscribe;
/**
 * Resubscribes to an event and returns the subscriber key.
 *
 * The callback receives the event data as the first argument, and the event name as the second.
 */
function resubscribe(key, namespace, event, callback) {
    (0, logging_1.log)('[resubscribe]', [key, namespace, event, callback]);
    if (typeof SUBSCRIBERS[namespace] === 'undefined') {
        throw new Error("Invalid namespace for resubscription - ".concat(namespace));
    }
    if (typeof SUBSCRIBERS[namespace][event] === 'undefined') {
        throw new Error("Invalid event name for resubscription - ".concat(event));
    }
    var index = SUBSCRIBERS[namespace][event].findIndex(function (item) { return item.key === key; });
    if (index === -1) {
        throw new Error("Invalid key for resubscription - ".concat(key));
    }
    SUBSCRIBERS[namespace][event][index] = { callback: callback, timestamp: new Date(), key: key };
    return key;
}
exports.resubscribe = resubscribe;
/**
 * Unsubscribes from events, optionally using a subscriber key, and returning the number of events it unsubscribed from.
 */
function unsubscribe(namespace, event, key) {
    (0, logging_1.log)('[unsubscribe]', [namespace, event, key]);
    var removed = 0;
    if (typeof SUBSCRIBERS[namespace] === 'undefined')
        return 0;
    if (event === '*') {
        Object.keys(SUBSCRIBERS[namespace]).forEach(function (name) {
            removed += SUBSCRIBERS[namespace][name].length;
        });
        delete SUBSCRIBERS[namespace];
        return removed;
    }
    if (typeof SUBSCRIBERS[namespace] !== 'undefined' && typeof SUBSCRIBERS[namespace][event] !== 'undefined') {
        if (!key) {
            removed += SUBSCRIBERS[namespace][event].length;
            delete SUBSCRIBERS[namespace][event];
        }
        else {
            for (var i = 0; i < SUBSCRIBERS[namespace][event].length; i++) {
                if (SUBSCRIBERS[namespace][event][i].key === key) {
                    SUBSCRIBERS[namespace][event].splice(i, 1);
                    removed++;
                }
            }
        }
    }
    return removed;
}
exports.unsubscribe = unsubscribe;
