import { reportMxMAnalytics } from "@roc-digital/mxm-base/logic";

export function reportSignupFromUtmUrl() {
  const utmUrl = localStorage.getItem('utm_url') || '';
  const utmReferrer = localStorage.getItem('utm_referrer') || '';

  if(!utmUrl) return;

  reportMxMAnalytics({
    signupUtmUrl: utmUrl,
    utmReferrerUrl: utmReferrer
  });

  localStorage.removeItem('utm_url');
  localStorage.removeItem('utm_referrer');
}

export function logUtmParameter() {
  const search = window.location.search;
  if(search.includes("?utm") || search.includes("&utm")) {
    localStorage.setItem('utm_url', window.location.toString());
    localStorage.setItem('utm_referrer', document.referrer || '');
  }
}