import React from 'react';

import styles from './TableHeader.module.css';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

const thClasses = `${styles.th} px-2 py-3 text-left text-admin-light font-medium text-xs border-t border-stone-200`;

export const TableHeader = ({ children, className }: Props) => {
  className = className ? `${thClasses} ${className}` : thClasses;

  return <th className={className}>{children}</th>;
};
