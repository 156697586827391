import { memo, useEffect } from 'react';
import AppFooter from '@/components/elements/AppFooter';

const Help = memo(() => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="h-full flex flex-col justify-center bg-gray-100 font-chivo overflow-x-hidden">
      <div className={'helpForm'}>
        <iframe className={'w-full helpForm'} src="https://form.jotform.com/233627014075047"></iframe>
      </div>
      <AppFooter />
    </div>
  );
});

Help.displayName = 'Help and Support';

export default Help;
