import { useEffect, useMemo, useState } from 'react';
import { useStateEvent, useMethod, mapToArticleContent, getArticleActionHandler } from '@roc-digital/ui-lib';
import { ArticleSchema } from '@roc-digital/mxm-base/data';
import { listBookmarksV2, handleVote, handleBookmark } from '@roc-digital/mxm-base/logic';
import { InputSearch, ZoneLayout } from '@roc-digital/ui-web';
import { Loading } from '@/components/elements/Loading';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { shareArticle } from '@/logic/shareArticle';

const SavedArticles = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [clearSearch] = useStateEvent('mxm.search', 'clear', '');
  const articlesQuery = useMethod(listBookmarksV2);
  const [searchResults, setSearchResults] = useState<ArticleSchema[] | undefined>([]);

  const articles = useMemo(() => {
    if(!searchResults) return [];

    return searchResults.map(a => mapToArticleContent(a, {}));
  }, [searchResults]);

  useEffect(() => {
    setQuery('');
  }, [clearSearch]);

  useEffect(() => {
    articlesQuery.run(1, 100);
  }, []); 

  useEffect(() => {
    if (query === '' || query.trim() === '') {
      setSearchResults(articlesQuery.result);
      return;
    }
    const filterQuery = articlesQuery?.result?.filter((a) => a.title.toLowerCase().includes(query.toLowerCase()));
    setSearchResults(filterQuery);
  }, [query, articlesQuery.result]);

  const onAction = useMemo(() => {
    return getArticleActionHandler({
      get: articlesQuery.getLastResult,
      set: articlesQuery.setResult,
      navigate: async (article) => navigate(`/article/${article.id}`),
      bookmark: async (article) => {
        try {
          const result = await handleBookmark(article);
          if(result.bookmarked) {
            toast.success('Article bookmarked');
          } else {
            toast.success('Bookmark removed');
            articlesQuery.run(1, 100)
          }
          return result;
        } catch(err) {
          console.error(err);
          toast.error('Failed to bookmark article')
          return article;
        }
      },
      vote: (article, vote) =>  handleVote(article.id, vote),
      share: (article) => openShareModal(article)
    });
  }, []);

  const openShareModal = (article: ArticleSchema) => {
    shareArticle(article);
  };

  /**
   * TODO Add pagination via infinite scroll.
   */

  return (
    <div className="flex flex-col w-full max-w-4xl dark:text-white">
      <div className="text-2xl font-bold mb-6">Saved Stories</div>
      <div className="border border-surface-high mb-6 dark:border-0 mr-6">
        <InputSearch hideResults value={query} placeholder="Search..." changeValueEvent={(input) => setQuery(input)}/>
      </div>
      {articlesQuery.loading && <Loading customClassName={'ml-auto mr-auto'} />}
      {articles && !articlesQuery.loading && <ZoneLayout articles={articles} layoutId={99} onAction={onAction}/>}
    </div>
  );
};

SavedArticles.displayName = 'SavedArticles';

export default SavedArticles;
