import { publish } from '@roc-digital/mxm-base/events';

import { ClickEvent, CustomEvent, PublisherData } from '@roc-digital/ui-lib';

type Props = {
  id?: string;
  className?: string;
  src: string;
  alt?: string;
  title?: string;
  style?: React.CSSProperties;
  clickEvent?: ClickEvent | CustomEvent | Function;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
};

export const Image = ({ id, className, clickEvent, style, src, alt, onError }: Props) => {
  const publishClick = (evt: React.MouseEvent<HTMLImageElement>) => {
    if (!clickEvent) {
      return;
    }

    if(typeof clickEvent === 'function') {
      return clickEvent();
    }

    if (clickEvent.allowDefault === false) {
      evt.preventDefault();
    }

    if (clickEvent.propagate === false) {
      evt.stopPropagation();
    }

    publish<PublisherData>(clickEvent.namespace, 'click', {
      id,
      eventData: clickEvent.data || {},
    });
  };

  return <img className={className} style={style} onClick={publishClick} src={src} alt={alt} onError={onError} />;
};