"use strict";
/* eslint-disable import/no-cycle */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDashboardStats = exports.getStats = void 0;
// @copyright 2023 MXM, Inc
var config_1 = require("../config");
var data_1 = require("../data");
var http_1 = require("./http");
var json_fetch_1 = require("./json-fetch");
var SERVICE_NAME = 'adminStats';
var _24HOURS = 60 * 60 * 24;
var _1_WEEK = _24HOURS * 7;
function url() {
    return "".concat((0, config_1.getConfig)().mxmApiAdminUrl, "/stats");
}
function getStats(params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'getStats', (0, http_1.createHTTPRequest)('GET', "".concat(url(), "?start=").concat(((Date.now() / 1000) - _1_WEEK).toFixed(0)), params), function (data) { return new data_1.Stats(data); }, true);
}
exports.getStats = getStats;
function getDashboardStats() {
    return (0, json_fetch_1.JsonFetch)({
        url: "".concat((0, config_1.getConfig)().mxmAuxApiUrl, "/admin/exports/dashboard")
    });
}
exports.getDashboardStats = getDashboardStats;
