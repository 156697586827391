import { getEnv } from "@/logic/env";
import { JsonFetch } from "@roc-digital/mxm-base/logic";
import { Popup } from "@roc-digital/types";

// const date = Math.floor(Date.now() / 1000);

// let popups: Popup<GenericPopupConfig>[] = [
//   {
//     id: 1,
//     active: true,
//     type: 'generic',
//     name: 'Sample popup 1',
//     config: {
//       trigger: {
//         urlMatch: '/trending',
//         delay: 5000,
//         scrollDepth: 20,
//       }
//     },
//     created: date,
//     modified: date,
//   }
// ];

export async function getPopups() {
  return await JsonFetch<Popup[]>({url: `${getEnv().auxApi}/popups`})
}
