import { Link } from 'react-router-dom';
import googlePlayStoreButtonImage from '../../assets/google_play_store.svg';

const PlayStoreButton = () => {
  return (
    <Link to="https://play.google.com/store/apps/details?id=com.rocnews" target="_blank">
      <img src={googlePlayStoreButtonImage} />
    </Link>
  );
};

PlayStoreButton.displayName = 'PlayStoreButton';

export default PlayStoreButton;
