import {
  upvoteIcon,
  downvoteIcon,
  bookmarkFillIcon,
  bookmarkIcon,
  shareIcon,
  CustomEvent,
  upvoteFillIcon,
  downvoteFillIcon,
} from '@roc-digital/ui-lib';
import { Pressable } from './Pressable';
import { Icon } from './Icon';
import { BodyDark } from './Typefaces';

export enum LayoutTypes {
  desktop = 'desktop',
  mobile = 'mobile',
}

export enum UpvoteSizes {
  large = 'lg',
  medium = 'md',
  small = 'sm',
  xsmall = 'xml',
}

export type UpVoteProps = {
  id?: string;
  size?: UpvoteSizes;
  upvoted?: boolean;
  downvoted?: boolean;
  upvotes?: number;
  downvotes?: number;
  saved?: boolean;
  upVoteEvent?: CustomEvent;
  downVoteEvent?: CustomEvent;
  saveEvent?: CustomEvent;
  shareEvent?: CustomEvent;
  shareOptions?: { name: string; icon: string; action: string }[];
  className?: string;
  saveIconClassName?: any;
};

const sizeClasses = {
  lg: 'h-8',
  md: 'h-7',
  sm: 'h-6',
};

export const UpVote = ({
  id,
  size = UpvoteSizes.medium,
  upvoted,
  downvoted,
  upvotes = 0,
  downvotes = 0,
  saved,
  upVoteEvent,
  downVoteEvent,
  saveEvent,
  shareEvent,
  className,
  saveIconClassName,
}: UpVoteProps): JSX.Element => {
  return (
    <div className={`flex flex-row w-full justify-between ${sizeClasses[size]} ${className} sm:h-fit sm:gap-y-2`}>
      <div className="flex flex-row justify-between items-center rounded-full border border-gray-100 px-2 sm:h-7">
        {/* <div className="flex flex-row items-center py-1">
          <Pressable id={id} clickEvent={upVoteEvent} className="px-1 border-gray-100">
            <Icon
              className="flex"
              size={size === 'lg' ? 'medium' : size === 'md' ? 'small' : 'x-small'}
              src={upvoted ? upvoteFillIcon : upvoteIcon}
            />
          </Pressable>
          <BodyDark
            className={`text-[10px] text-admin-muted ${
              size === 'lg' ? 'text-xl' : size === 'md' ? 'text-sm' : 'text-xs'
            } pr-1`}
          >
            {upvotes}
          </BodyDark>
        </div> */}
        <div className="flex flex-row items-center py-2">
          <Pressable id={id} clickEvent={upVoteEvent} className={'px-1'}>
            <Icon
              className="flex"
              size={size === 'lg' ? 'medium' : size === 'md' ? 'small' : 'x-small'}
              src={upvoted ? upvoteFillIcon : upvoteIcon}
            />
          </Pressable>
          <BodyDark
            className={`sm:text-[10px] text-admin-muted ${
              size === 'lg' ? 'text-xl' : size === 'md' ? 'text-sm' : 'text-xs'
            } pr-1 dark:text-white`}
          >
            {upvotes}
          </BodyDark>
        </div>

        <div className="h-full sm:h-7 bg-gray-100 mx-1 border border-gray-100" />

        <div className="flex flex-row items-center py-2">
          <Pressable id={id} clickEvent={downVoteEvent} className={'px-1'}>
            <Icon
              className="flex"
              size={size === 'lg' ? 'medium' : size === 'md' ? 'small' : 'x-small'}
              src={downvoted ? downvoteFillIcon : downvoteIcon}
            />
          </Pressable>
          <BodyDark
            className={`sm:text-[10px] text-admin-muted ${
              size === 'lg' ? 'text-xl' : size === 'md' ? 'text-sm' : 'text-xs'
            } pr-1 dark:text-white`}
          >
            {downvotes}
          </BodyDark>
        </div>
      </div>

      <div className="flex flex-row items-center ml-1">
        <Pressable
          className={`flex items-center rounded-full border border-background p-1.5 ${saveIconClassName}`}
          id={id}
          clickEvent={saveEvent}
        >
          <Icon
            className="flex"
            size={size === 'lg' ? 'medium' : size === 'md' ? 'small' : 'x-small'}
            src={saved ? bookmarkFillIcon : bookmarkIcon}
          />
        </Pressable>
        <div className="h-full border-background mx-0.5" />
        <Pressable
          className={'flex items-center rounded-full border border-background p-1.5 sm:h-7 sm:w-7 sm:justify-center'}
          id={id}
          clickEvent={shareEvent}
        >
          <Icon
            className="flex"
            size={size === 'lg' ? 'medium' : size === 'md' ? 'small' : 'x-small'}
            src={shareIcon}
          />
        </Pressable>
      </div>
    </div>
  );
};

UpVote.displayName = 'UpVote';
