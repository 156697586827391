import { UserLocationForm } from '@/components/elements/UserLocationForm';

const Locations = () => {
  return (
    <div className="flex flex-col max-w-2xl w-full text-sm dark:text-white pr-4">
      <UserLocationForm title={'Update Localized News Coverage'}/>
    </div>
  );
};

Locations.displayName = 'Locations';

export default Locations;
