import { ArticleViewProps } from '@roc-digital/ui-lib';
import { Heading1 } from '../Typefaces';

export function JumbotronHeadline(props: ArticleViewProps) {
  const content = props.article.href ? <a
    href={props.article.href}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      props.onAction?.({ action: 'open', articleId: props.article.id });
    }}
  >{ props.article.headline}</a> : props.article.headline;
  return <Heading1
  onClick={() => props.onAction?.({ action: 'open', articleId: props.article.id })}
  className="w-full cursor-pointer flex flex-col bg-[#24AAE1] pb-6 pt-6 mt-0 mb-0 max-w-5xl mx-auto px-2 text-white underline uppercase !font-extrabold !text-[32px] !leading-9 !tracking-tight !font-chivo text-center"
>{content}</Heading1>
}